import axios from 'axios'

export default options => {
    return rest(options)
}

const rest = options => {
    const _axios = axios.create({
        baseURL: options.baseURL,
    })

    _axios.interceptors.response.use(
        response => {
            return response
        },
        error => {
            if (error?.response?.status === 401) {
                console.log('do something here...')
            }
            return error
        },
    )

    return _axios
}
