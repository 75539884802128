import React, { Component } from 'react'
import configureStore from './stores'
import { Provider } from 'react-redux'
import { ContainerProvider, Container } from './services/container'
import { Link, Route, Switch } from 'react-router-dom'
import Amplify, {Hub} from 'aws-amplify'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import config from './aws-exports'
import { PersistGate } from 'redux-persist/integration/react'
import Restaurant from './containers/Restaurant'
import Cart from './containers/Cart'
import Checkout from './containers/Checkout'
import NewAddress from './containers/NewAddress'
import Account from './containers/Account'
import Orders from './containers/Orders'
import DeliveryMode from './containers/DeliveryMode'
import Home from './containers/Home'
import ReadMenu from './containers/ReadMenu'
import ThankYouPage from './containers/ThankYouPage'
import CookieBanner from 'react-cookie-banner'
import { updateAmplifyConfig } from './config'
import ProtectedRoute from './utils/ProtectedRoute'
import OrderDetails from './containers/OrderDetails'
import Page from './containers/Page'
import DeliveryPoints from './containers/DeliveryPoints'
import { withRouter } from 'react-router-dom'

const container = Container()
const { store, persistor } = configureStore()



export const appStore = store

Amplify.configure(updateAmplifyConfig(config))

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC)

class App extends Component {

    componentDidMount () {
        Amplify.configure(updateAmplifyConfig(config))
        Hub.listen('auth', ({ payload: { event, data } }) => {
          switch (event) {
            case 'signIn':
              store.dispatch({ type: 'ACTION_USER_LOGIN', payload: data.signInUserSession.idToken })
              break
            case 'signOut':
              store.dispatch({ type: 'ACTION_USER_LOGOUT' })
              break
          }
        })

        const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
        if (path) {
            this.props.history.replace(path)
        }
      }

    render() {
        return (
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <ContainerProvider container={container}>
                        <Elements stripe={stripePromise}>
                            <CookieBanner
                                message="Questo sito fa uso di cookie per migliorare l’esperienza di navigazione degli utenti e per raccogliere informazioni sull’utilizzo del sito stesso. Proseguendo nella navigazione si accetta l’uso dei cookie e della nostra "
                                onAccept={() => {}}
                                link={
                                    <Link to={'privacy-policy'}> privacy policy.</Link>
                                }
                                buttonMessage="Accetto"
                                dismissOnScroll={false}
                                styles={{
                                    banner: { padding: '4vw 15vw', position: 'fixed', bottom: 0, backgroundColor: '#8b8b7f', height: 'unset', width: '70vw' },
                                    message: { lineHeight: '15px', fontSize: '13px' }
                                }}
                                cookie="user-has-accepted-cookies" />
                            <Switch>
                                <Route exact path="/" component={Home} />
                                <Route
                                    exact
                                    path="/privacy-policy"
                                    component={Page}
                                />
                                <Route
                                    exact
                                    path="/menu"
                                    component={ReadMenu}
                                />
                                <Route
                                    exact
                                    path="/menu-delivery"
                                    component={() => (
                                        <ReadMenu isDelivery={true} />
                                    )}
                                />
                                <Route
                                    exact
                                    path="/delivery-points"
                                    component={DeliveryPoints}
                                />
                                <Route
                                    exact
                                    path="/store"
                                    component={Restaurant}
                                />
                                <Route exact path="/cart" component={Cart} />
                                <Route
                                    exact
                                    path="/delivery-takeaway"
                                    component={DeliveryMode}
                                />
                                <ProtectedRoute
                                    exact
                                    path="/checkout"
                                    component={Checkout}
                                />
                                <ProtectedRoute
                                    exact
                                    path="/new-address"
                                    component={NewAddress}
                                />
                                <ProtectedRoute
                                    exact
                                    path="/thankyou-page"
                                    component={ThankYouPage}
                                />
                                <ProtectedRoute
                                    exact
                                    path="/profile"
                                    component={Account}
                                />
                                <ProtectedRoute
                                    exact
                                    path="/orders"
                                    component={Orders}
                                />
                                <Route
                                    exact
                                    path="/order-datails/:id"
                                    render={(props)=>  <OrderDetails id={props.match.params.id}/>}
                                />
                                <Route path="/" component={Home} />
                            </Switch>
                        </Elements>
                    </ContainerProvider>
                </PersistGate>
            </Provider>
        )
    }
}

export default withRouter(App)
