export const getUrlWithDeliveryPointParam = (url) => {
    let stored_deliveryPointParam = localStorage.getItem('deliveryPointParam') || ''
    if ('URLSearchParams' in window && stored_deliveryPointParam) {
        localStorage.setItem('deliveryPointParam', '');
    }

    let deliveryPointParam = (new URLSearchParams(window.location.search)).get("deliveryPoint") || stored_deliveryPointParam
    const newLocationSearch = new URLSearchParams()
    newLocationSearch.set("deliveryPoint", deliveryPointParam) 
    const searchParams = deliveryPointParam ? `?${newLocationSearch.toString()}` : ''
    return url + searchParams
}
