import React, { Component } from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import Modal from 'react-responsive-modal'
import ProductRule from '../components/product_rule'
import { truncate, pxToRem, withModal } from '../utils/common-style'
import ConfirmButton from '../components/confirm_button'

const deepClone = (oldObject) => JSON.parse(JSON.stringify(oldObject))

class ProductOptions extends Component {
    constructor(props) {
        super(props)
        this.state = { visible: false }
    }

    open() {
        this.setState({ visible: true })
    }

    close() {
        this.props.onCloseModal()
        this.setState({ visible: false, rules: [] })
    }

    componentDidUpdate() {
        if(this.state.visible && !this.props.item?.name) {
            this.close()
        }
    }

    componentWillReceiveProps(newProps) {
        const index = newProps.item?.rules?.findIndex(
            (x) => x.name === 'Aggiungi ingredienti',
        )
        if (index > -1) {
            const newOptions = newProps.baseOptions
            newProps.item.rules[index].options = [...newOptions]
        }
        const isChecked = (optionName) => {
            let checked = false
            if (
                newProps.item.options &&
                newProps.item.options
                    .split(',')
                    .find((optName) => optName === optionName)
            ) {
                checked = true
            }
            return checked
        }

        if (!this.state.visible && newProps.item.rules) {
            const rules = newProps.item.rules.reduce((acc, rule) => {
                const options = rule.options.reduce((acc2, opt) => {
                    acc2[opt.name] = {
                        name: opt.name,
                        checked: isChecked(opt.name),
                        price: opt.price,
                    }
                    return acc2
                }, {})
                const { min, max } = rule

                acc.push({
                    name: rule.name,
                    options,
                    min,
                    max,
                })
                return acc
            }, [])
            this.setState({ rules })
            this.open()
        }
    }

    selectedInRule(rule) {
        let count = 0
        Object.keys(rule.options).forEach((optName) => {
            if (rule.options[optName].checked) {
                count++
            }
        })
        return count
    }

    isRuleConfirmable(rule) {
        return !rule.min || this.selectedInRule(rule) >= rule.min
    }

    onSelection(changed) {
        const toggleProp = (obj, prop) => {
            obj[prop] = !obj[prop]
        }
        const newState = deepClone(this.state)

        // opt, propname nel caso checkbox
        if (changed.value) {
            const ruleIndex = newState.rules.findIndex(
                (x) => x.name === changed.propName,
            )
            Object.keys(newState.rules[ruleIndex].options).forEach((opt) => {
                newState.rules[ruleIndex].options[opt].checked = false
            })

            newState.rules.find((x) => x.name === changed.propName).options[
                changed.value
            ].checked = true
        } else {
            toggleProp(
                newState.rules.find((x) => x.name === changed.propName).options[
                    changed.opt.name
                ],
                'checked',
            )
        }
        this.setState(newState)
        this.forceUpdate()
    }

    getClasses() {
        return classNames({
            invalid:
                this.state.rules &&
                this.state.rules.filter((rule) => !this.isRuleConfirmable(rule))
                    .length > 0,
        })
    }

    render() {
        return (
            <Modal
                open={this.state.visible}
                center
                focusTrapped={false}
                animationDuration={150}
                onClose={() => this.close()}>
                <div className={`${this.props.className} ${this.getClasses()}`}>
                    <div className="line product">
                        <h2>{this.props.item.name}</h2>
                    </div>
                    <div className="modal-content">
                        {this.state.rules
                            ? this.state.rules.map((rule, i) => (
                                  <ProductRule
                                      {...rule}
                                      key={`rule_${i}`}
                                      onSelection={(ruleState) =>
                                          this.onSelection(ruleState)
                                      }
                                  />
                              ))
                            : null}
                        <ConfirmButton
                            className="confirm"
                            message={'Conferma Scelta'}
                            onClick={() => {
                                const prodOptions = {
                                    used: true,
                                    list: '',
                                    price: 0,
                                    quantity: this.props.item.count || 1,
                                }
                                this.state.rules.reduce((acc, rule) => {
                                    Object.keys(rule.options).forEach((x) => {
                                        if (rule.options[x].checked) {
                                            acc.list = acc.list.concat(
                                                acc.list ? ',' : '',
                                                rule.options[x].name,
                                            )
                                            acc.price += rule.options[x].price
                                        }
                                    })
                                    return acc
                                }, prodOptions)
                                this.props.onConfirmButtonClicked(
                                    this.props.item.categoryName,
                                    this.props.item,
                                    prodOptions,
                                )
                                setTimeout(() => {
                                    this.close()
                                }, 100)
                            }}
                        />
                    </div>
                </div>
            </Modal>
        )
    }
}

const StyledProductOptions = styled(ProductOptions)`
    background-color: white;
    color: ${(props) => props.theme.textLight3};
    .line {
        height: 56px;
        display: flex;
        align-items: center;
        padding: 0 17px;
        h2 {
            ${truncate};
        }
        &.product {
            font-size: ${pxToRem(16)};
            color: ${(props) => props.theme.pariabrown};
            border-bottom: 1px solid ${(props) => props.theme.separatorColor};
            font-weight: bold;
        }
    }
    ${withModal}
`

export default StyledProductOptions
