import React from 'react'
import styled from 'styled-components'
import { pxToRem, device } from '../../utils/common-style'

class HomePic extends React.Component {
    render() {
        return (
            <div className={`section ${this.props.className}`}>
                <div className="section-content">
                    <div className="info">

                        <div className="big"></div>
                        <button className="menu-k1s" onClick={() => this.props.goTo('/store')}>Ordina ora</button>
                        <div className="address">
                            Piazza della Repubblica 16, L'Aquila (AQ) Italy
                            <br />
                            info@pariapizza.com
                            <br />
                            <a href="tel:3347619116">+39 334 7619116</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const StyledHomePic = styled(HomePic)`
    background-image: url('https://paria-web-store.s3-eu-west-1.amazonaws.com/site-assets/gallery/paria_01.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .menu-k1s {
        padding: 5px 20px;
        cursor: pointer;
        margin: 4px 10px;
        background-color: #91d147;
        border: 2px solid #FFFFFF;
        font-weight: bold;
        text-transform: uppercase;
        color: #FFFFFF;
        &:hover {
            background-color: #FFFFFF;
            color: #91d147;
            border: 2px solid #91d147;
        }
    }
    .section-content {
        display: flex;
        flex-direction: row;
        height: 100%;
        justify-content: center;
        align-items: flex-end;
        margin-bottom: 8vh;
        .info {
            text-align: center;
            font-family: WorkSans;
            font-size: ${pxToRem(16)};
            width: 70%;
            color: white;
            font-size: ${pxToRem(19)};
            .big {
                font-family: WorkSans;
                font-size: ${pxToRem(34)};
            }
            .address {
                margin-top: 50px;
                font-family: RecoletaAlt;
                font-size: ${pxToRem(14)};
                font-weight: 600;
                letter-spacing: 2px;
                @media ${device.laptop} {
                    font-size: ${pxToRem(24)};
                }
            }
        }
        svg {
            width: 120px;
            margin-bottom: 30px;
        }
    }
`

export default StyledHomePic
