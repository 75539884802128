import { ACTION_CREATE_ORDER } from './types'
import { somethingWentWrong } from '.'

const createOrder = ({ rest, Auth }) => async (dispatch, getState) => {
    const items = []
    const selectedPayment = getState().checkout.selectedPayment
    // if card you need to take id
    // const selectedPaymentIndex = getState().checkout.selectedPayment
    Object.keys(getState().cart.categories).forEach((key) => {
        const cat = getState().cart.categories[key]
        Object.keys(cat).map((i) => {
            const p = cat[i]

            const id = p._id
            const name = p.name
            const options = p.options
            const price = p.price + p.optionsPrice
            const quantity = p.count
            const category = p.categoryName

            items.push({
                id,
                name,
                options: options === '' ? '-' : options,
                price,
                quantity,
                category,
            })
            return null
        })
    })
    const { selectedRestaurant, timeAddress, cart, user, checkout } = getState()

    const prettyAddress = ({ number, street, zipcode, city }) =>
        `${street}, ${number} - ${zipcode} ${city}`

    const payload = {
        deliveryAt: timeAddress.time,
        delivery: {
            coordinates: checkout.delivery.coordinates,
            fullAddress:
                checkout.delivery.address &&
                prettyAddress(checkout.delivery.address),
            intercome: checkout.delivery.intercome,
            note: checkout.delivery.note,
            email: user.email,
            name: user.firstName,
            phone: checkout.phone,
            surname: user.lastName,
        },
        products: items,
        totalPrice: cart.total,
        shipmentFee: Number(selectedRestaurant.evaluation.cost),
        tip: checkout.tip,
        isDelivery: cart.isDelivery,
        extra: cart.extra,
        payment: selectedPayment
    }

    // // add coupon eventually
    if (cart.evaluatedCoupon && cart.evaluatedCoupon.coupon) {
        payload.couponId = cart.couponCode
        payload.totalCoupon = cart.evaluatedCoupon.discount
        payload.totalPrice = (payload.totalPrice - payload.totalCoupon).toFixed(2)
    }

    // // add invoice eventually
    // if (checkout.billing) {
    //     payload.delivery.company = checkout.billing.companyName
    //     payload.delivery.vat = checkout.billing.companyVat
    //     payload.delivery.companyAddress = prettyAddress(checkout.billing)
    //     payload.delivery.companyZipcode = checkout.billing.zipcode
    //     payload.delivery.companyCity = checkout.billing.city
    // }
    const currentUser = await Auth.currentAuthenticatedUser()
    let config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: currentUser.signInUserSession.idToken.jwtToken,
        },
    }
    const request = rest.post(`order`, payload, config)


    return dispatch({
        payload: request,
        type: ACTION_CREATE_ORDER,
    }).catch(() => {
        dispatch(somethingWentWrong(true))
    })
}

export default createOrder
