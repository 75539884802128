import { ACTION_USER_PAYMENT_METHOD_ATTACH } from './types'
import { getCheckoutInfo } from '.'

const attachPaymentMethod = ( { rest, Auth }, { paymentMethodId }) => async (
    dispatch,
) => {
    const currentUser = await Auth.currentAuthenticatedUser()
    let config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: currentUser.signInUserSession.idToken.jwtToken,
        },
    }
    const request = rest.post(`user/payment-methods`,
        { paymentMethodId, attributes: currentUser.attributes },
        config,
    )
    return dispatch({
        payload: request,
        type: ACTION_USER_PAYMENT_METHOD_ATTACH,
    })
    .then(({value}) => {
        dispatch(getCheckoutInfo({ rest, Auth }))
        return value?.data
    })
}

export default attachPaymentMethod
