// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:c7e7d598-186a-4f41-8bac-b6733605860d",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_TR6ciMZfe",
    "aws_user_pools_web_client_id": "149p6ae4khil4kh11jijhc73th",
    "oauth": {
        "domain": "web-store-paria-test.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/cart/,https://pariapizza.com/cart/,https://d2rg8etzu6dcf9.cloudfront.net/cart/",
        "redirectSignOut": "http://localhost:3000/store/,https://pariapizza.com/store/,https://d2rg8etzu6dcf9.cloudfront.net/store/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
