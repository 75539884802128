import { ACTION_USER_LOGOUT } from './types'
import createConfig from '../utils/headers'

const logout = ({ rest }) => (dispatch, getState) => {
    const token = getState().user.token
    const config = createConfig(token)
    const request = rest.post(`users/logout`, null, config)
    dispatch({ type: ACTION_USER_LOGOUT, payload: request })
}

export default logout
