// @flow
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { withContainer, connect } from '../services/container'
import styled from 'styled-components'
import { getMenu } from '../stores/actions'
import MenuCategories from '../components/home/MenuCategories'
import MenuPages from '../components/home/MenuPages'
import BackNavbar from '../containers/BackNavbar'
import { device, pageContainer } from '../utils/common-style'

class ReadMenu extends Component {
    componentDidMount() {
        this.props.getMenu()
    }

    render() {
        return (
            <div className={this.props.className}>
                <BackNavbar hideUser={true} />
                <MenuCategories />
                <div className={'content'}>
                    <div>
                        <MenuPages isDelivery={this.props.isDelivery} />
                    </div>
                </div>
            </div>
        )
    }
}

const StyledReadMenu = styled(ReadMenu)`
    ${pageContainer};
    .content {
        margin: 0px 20vw 0px 20vw;
        & > div {
            height: calc(100vh - 148px);
        }
        @media ${device.laptopL} {
        }
    }
`

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getMenu,
        },
        dispatch,
    )
const mapStateToProps = ({ selectedRestaurant, cart }) => ({
    selectedRestaurant,
    cart,
})
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withContainer(StyledReadMenu))
