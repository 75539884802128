import { ACTION_MAILCHIMP_EVENT } from './types'

const mailchimpEvent = ({ rest, Auth }) => async (dispatch, getState) => {

    const currentUser = await Auth.currentAuthenticatedUser()
    let config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: currentUser.signInUserSession.idToken.jwtToken,
        },
    }
    const request = rest.post(`user/mailchimp-events`, {"name":"buy"}, config)


    return dispatch({
        payload: request,
        type: ACTION_MAILCHIMP_EVENT,
    })
}

export default mailchimpEvent
