import { ACTION_SET_STATE_ITEM_FOR_OPTION_MODE } from './types';
import { clearItemForOptionMode } from '.';

const setStateItemForOptionMode = () => (dispatch, getState) => {
  dispatch({
    type: ACTION_SET_STATE_ITEM_FOR_OPTION_MODE,
  });
  setTimeout(() => dispatch(clearItemForOptionMode()), 100);
};

export default setStateItemForOptionMode;
