// @flow
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { withContainer, connect } from '../services/container'
import styled from 'styled-components'
import {
    setStateItemForOptionMode,
    clearItemForOptionMode,
    addItemToCart,
    getMenu,
    getStore,
} from '../stores/actions'
import { urls } from '../config'
import BackNavbar from './BackNavbar'
import CartPreview from '../components/cart_preview'
import RestaurantCategories from './RestaurantCategories'
import RestaurantPages from './RestaurantPages'
import ProductOptions from '../components/product_options'
import { device, pageContainer } from '../utils/common-style'

class DeliveryPoints extends Component {
    state = {
        closed: false,
        deliveryPoints: []
    }

    async componentDidMount() {
        const res = await this.props.getStore()
        let weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][new Date().getDay()]
        const store = res?.value?.data
        if(store?.restDay.length > 0 && store.restDay.indexOf(weekday) >= 0){
            this.setState({
                closed: true,
                message: 'Spiacenti ma oggi siamo chiusi, torna a trovarci domani!'
            })
        }else if(store?.forceClose){
            this.setState({
                closed: true,
                message: 'Siamo temporaneamente offline, torna a trovarci piú tardi!'
            })
        }else if(store?.deliveryPointsOffline){
            this.setState({
                closed: true,
                message: 'Siamo spiacenti ma il servizio non é disponibile in questo momento.'
            })
        } else {
            this.setState({
                deliveryPoints: store?.deliveryPoints || [],
            })
        }

    }

    render() {
        if (this.state.closed){
            return <div className={this.props.className}>
                <BackNavbar />
                <div className={'closed-message'}>
                {this.state.message}
                </div>


            </div>
        }
        return (
            <div className={this.props.className}>
                <BackNavbar />
                <div className={'content'}>
                    <div>
                        <div className={'title-section'}>Delivery Points</div>
                        <div className={'title-subsection'}>I prodotti che ami, nei luoghi piu belli della cittá</div>
                        <div className={'dp-wrapper'}>
                            {this.state.deliveryPoints.map(x => <div key={x.name} className={'card'} onClick={() => {
                                this.props.history.push(`${urls.STORE}?deliveryPoint=${x.slug}&action=clear`)
                            }}>
                                <div className={'name'}>{x.name}
                                <i className='material-icons'>arrow_forward</i>
                                </div>
                                <img src={x.image} className={'image'}></img>
                            </div>)}
                        </div>
                    </div> 
                </div>

            </div>
        )
    }
}

const StyledDeliveryPoints = styled(DeliveryPoints)`
    ${pageContainer};
    .content {
        margin: 0px 20vw 0px 20vw;
        & > div {
            height: calc(100vh - 148px);
        }
        @media ${device.laptopL} {
        }
    }
    .title-section {
        font-size: 18px;
        text-align: center;
        padding-top: 20px;
    }
    .title-subsection {
        font-size: 15px;
        text-align: center;
        margin-top: 20px;
    }
    .dp-wrapper {
        padding-bottom: 40px;
        .card {
            width: 300px;
            height: 240px;
            margin: 20px auto;
            position: relative;
            .name {
                font-size: 20px;
                position: absolute;
                bottom: 5px;
                left: 5px;
                background-color: #0000008a;
                padding: 3px;
                border-radius: 7px;
                color: floralwhite;
                display: flex;
                i {
                  margin-left: 10px;
                }
            }
            .image {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }   
        }
    }
    .closed-message {
        margin: 10vh 20vw;
        font-size: 30px;
    }
`

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getStore
        },
        dispatch,
    )
const mapStateToProps = ({ selectedRestaurant, cart }) => ({
    selectedRestaurant,
    cart,
})
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withContainer(StyledDeliveryPoints))
