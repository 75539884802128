import React, { Component } from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import FloatingLabelInput from 'react-floating-label-input'
import ConfirmButton from '../components/confirm_button'
import Address from '../utils/address'
import { moovendaForm, pxToRem } from '../utils/common-style'
import MapWithMarker from '../components/mapWithMarker'
import NavbarPanels from '../components/navbar_panels'
import Navbar from '../components/navbar'

import SearchAddress from '../components/search_address'

class AddressPicker extends Component {
    constructor(props) {
        super(props)
        this.state = {
            address: props.initialAddress,
            renderMarker: !!props.initialAddress.formatted_address,
            intercome: props.initialIntercome,
            note: props.initialNotes,
            error: false,
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleChangeNotes = this.handleChangeNotes.bind(this)
    }

    getClasses() {
        return classNames({
            invisible: !this.state.address.number || !this.state.renderMarker,
            'hidden-on-keyboard': true,
        })
    }

    handleChange(event) {
        this.setState({ intercome: event.target.value })
    }

    handleChangeNotes(event) {
        this.setState({ note: event.target.value })
    }

    render() {
        const tabIndex = this.props.addressTabIndex
        return (
            <div key="address" className={this.props.className}>
                <Navbar
                    items={['Mappa', 'Dettagli']}
                    activeIndex={tabIndex}
                    simple
                    lightColor
                    onClick={(index) => {
                        this.props.selectTabAddress(index)
                    }}
                />
                <NavbarPanels tabIndex={tabIndex}>
                    <div>
                        <SearchAddress
                            address={
                                this.props.initialAddress.formatted_address
                            }
                            placeholder={'Inserisci indirizzo e civico'}
                            onAddressComplete={(address) =>
                                this.setState({
                                    address: new Address(
                                        address.address_components,
                                        address.geometry,
                                        address.formatted_address,
                                    ),
                                    renderMarker: true,
                                })
                            }
                            onSwitchToEditMode={() =>
                                this.setState({
                                    renderMarker: false,
                                })
                            }
                        />

                        <MapWithMarker
                            lat={this.state.address.lat}
                            lng={this.state.address.lng}
                            renderMarker={this.state.renderMarker}
                            googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyC7trbZejQZrN7NY1-6caGJa0pzueQI0F0&libraries=geometry,drawing,places"
                            loadingElement={<div style={{ height: '100%' }} />}
                            containerElement={
                                <div
                                    style={{ height: 'calc( 100vh - 103px )' }}
                                />
                            }
                            mapElement={<div style={{ height: '100%' }} />}
                        />
                    </div>
                    <div
                        className={`address-datails ${
                            this.state.error && 'error'
                        }`}>
                        <FloatingLabelInput
                            id="intercome-input"
                            label="Citofono"
                            onChange={this.handleChange}
                            shrink={70}
                            value={this.state.intercome}
                        />
                        <div className={'intercome-error'}>
                            Campo obbligatorio
                        </div>
                        <div className={'notes-area'}>
                            <label htmlFor="note-input">Note indirizzo</label>
                            <textarea
                                rows="4"
                                cols="50"
                                id="note-input"
                                label="Note"
                                placeholder="Utilizza questo campo per informazioni permanenti da associare all' indirizzo"
                                onChange={this.handleChangeNotes}
                                value={this.state.note}
                            />
                        </div>
                    </div>
                </NavbarPanels>

                <ConfirmButton
                    className={`confirm ${this.getClasses()}`}
                    message={'Conferma'}
                    onClick={() => {
                        if (
                            this.props.intercomeRequired &&
                            !this.state.intercome
                        ) {
                            this.props.selectTabAddress(1)
                            this.setState({
                                error: true,
                            })
                            setTimeout(() => {
                                this.setState({
                                    error: false,
                                })
                            }, 4000)
                            return
                        }
                        this.props.onPickAddress({
                            address: this.state.address,
                            intercome: this.state.intercome,
                            note: this.state.note,
                        })
                    }}
                />
            </div>
        )
    }
}

const StyledAddressPicker = styled(AddressPicker)`
    width: 100%;
    color: white;
    background-color: white;
    .confirm {
        position: absolute;
        bottom: 0;
        z-index: 10;
        width: 100%;
        .btn-conf {
            width: calc(100% - 34px);
        }
    }
    .react-floating-label-input {
        margin-bottom: 30px;
    }
    .address-datails {
        ${moovendaForm};
        .intercome-error {
            color: ${(props) => props.theme.red};
            position: absolute;
            top: 100px;
            font-size: ${pxToRem(12)};
            opacity: 0;
            transition: opacity 0.3s ease;
        }
        .notes-area {
            margin-top: 30px;
        }
        label {
            font-size: ${pxToRem(16)};
            color: ${(props) => props.theme.textLight3};
        }
        #note-input {
            background-color: ${(props) => props.theme.lightgray};
            width: 100%;
            margin-top: 15px;
            padding: 20px;
            box-sizing: border-box;
            border-radius: 5px;
            &::placeholder {
                color: ${(props) => props.theme.textLight3};
                font-size: ${pxToRem(12)};
            }
        }
        &.error {
            .intercome-error {
                opacity: 1;
            }
            .react-floating-label-input .container {
                border-bottom: 1px solid ${(props) => props.theme.red};
            }
        }
    }
`

export default StyledAddressPicker
