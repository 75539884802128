import React from 'react';
import styled from 'styled-components';
import { user, pxToRem } from '../utils/common-style';

const AccountTopInfo = props => {
    const {attributes} = props.user

    return (<div className={props.className}>
    <div className="user" />
    <div className="info">
      <div className="left">
        <div>{`${attributes.name} ${attributes.family_name}`}</div>
        <div>{attributes.email}</div>
      </div>
      <div className="right">
        <i className="material-icons">arrow_drop_down</i>
      </div>
    </div>
  </div>
)};

const StyledAccountTopInfo = styled(AccountTopInfo)`
  padding: 0 0px 0 41px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 75px;
  background-color: #e3dcd6;
  .user {
    ${user};
    display: none;
  }
  .info {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .left {
      div:first-child {
        font-size: ${pxToRem(14)};
      }
      div:last-child {
        font-size: ${pxToRem(12)};
      }
    }
    .right {
      width: 70px;
      text-align: right;
      display: none;
    }
  }
`;

export default StyledAccountTopInfo;
