import {
    ACTION_FETCH_RESTAURANT_CATEGORIES,
    ACTION_ADD_ITEM_TO_CART,
    ACTION_SET_ITEM_FOR_OPTION_MODE,
    ACTION_SET_STATE_ITEM_FOR_OPTION_MODE,
    ACTION_CLEAR_ITEM_FOR_OPTION_MODE,
    ACTION_CHANGE_QUANTITY_ITEM_CART,
    ACTION_REMOVE_ITEM_FROM_CART,
    ACTION_EVALUATE_COUPON,
    ACTION_REMOVE_COUPON,
    ACTION_RESET_AFTER_PAYMENT,
    ACTION_GET_MENU,
    ACTION_SELECT_DELIVERY,
    ACTION_SET_DELIVERYPOINTS_MODE
} from '../actions/types'

const deepClone = (oldObject) => JSON.parse(JSON.stringify(oldObject))

const upperLimitOfExtra = ({ minimum }) => Math.round(minimum * 0.25)

const MINIMUM_ORDER_DELIVERY = 20
const MINIMUM_ORDER_DELIVERY_VERMUTTINO = 16

const INITIAL_STATE = {
    categories: {},
    restaurantId: null,
    total: 0,
    items: 0,
    minimum: 0,
    extra: 0,
    optionItem: {},
    evaluatedCoupon: {},
    evaluatingCoupon: false,
    isDelivery: null,
    couponCode: '',
    isDeliveryPoints: false
}

export default (state = INITIAL_STATE, action) => {
    const { type, payload, meta } = action
    switch (type) {
        case `${ACTION_GET_MENU}_FULFILLED`: {
            return {
                ...INITIAL_STATE,
                categories: payload.data.categories.reduce((acc, curr) => {
                    acc[curr.SK] = {}
                    return acc
                }, {}),
            }
        }
        case `${ACTION_FETCH_RESTAURANT_CATEGORIES}_FULFILLED`: {
            const restaurantId = /partners\/([^;]+)\/categories/.exec(
                payload.config.url,
            )[1]
            const isNewRestaurant = restaurantId !== state.restaurantId
            const noCategoryYet = Object.keys(state.categories).length === 0
            return isNewRestaurant || noCategoryYet
                ? {
                      ...state,
                      evaluatedCoupon: {},
                      categories: payload.data.list.reduce((a, b) => {
                          a[b] = {}
                          return a
                      }, {}),
                  }
                : state
        }
        case ACTION_REMOVE_ITEM_FROM_CART: {
            const { catName, idCart } = payload
            const categories = deepClone(state.categories)
            const item = categories[catName][idCart]
            let items = state.items
            let total = state.total
            items -= item.count
            total -= (item.price + item.optionsPrice) * item.count
            delete categories[catName][idCart]
            const extraWithoutLimit = state.minimum - total
            const extra = parseFloat(
                Math.min(extraWithoutLimit, upperLimitOfExtra(state)).toFixed(
                    2,
                ),
            )
            return {
                ...state,
                categories,
                total,
                extra: extra > 0 ? extra : 0,
                items,
                evaluatedCoupon: {},
                lastUpdate: new Date().getTime()
            }
        }
        case ACTION_CHANGE_QUANTITY_ITEM_CART: {
            const { add = true, catName, idCart } = payload
            const categories = deepClone(state.categories)
            const item = categories[catName][idCart]
            let items = state.items
            let total = state.total
            if (add) {
                item.count++
                items++
                total += item.price + item.optionsPrice
            } else {
                items--
                total -= item.price + item.optionsPrice
                if (item.count >= 2) {
                    // there will be al teast one more
                    item.count--
                } else {
                    // remove the last one
                    delete categories[catName][idCart]
                }
            }
            const extraWithoutLimit = state.minimum - total
            const extra = parseFloat(
                Math.min(extraWithoutLimit, upperLimitOfExtra(state)).toFixed(
                    2,
                ),
            )
            return {
                ...state,
                categories,
                total,
                extra: extra > 0 ? extra : 0,
                items,
                evaluatedCoupon: {},
                lastUpdate: new Date().getTime()
            }
        }
        case ACTION_ADD_ITEM_TO_CART: {
            const categories = deepClone(state.categories)
            const itemIdInCart = `${payload.item.SK}_${payload.options}`
            const isPresent = !!categories[payload.category][itemIdInCart]
            if (!isPresent) {
                payload.item.count = payload.quantity
                payload.item.optionsPrice = payload.optionsPrice
                payload.item.options = payload.options
                categories[payload.category][itemIdInCart] = payload.item
            } else {
                categories[payload.category][itemIdInCart].count +=
                    payload.quantity
            }
            const addedItemTotalPrice =
                (categories[payload.category][itemIdInCart].price +
                    payload.optionsPrice) *
                payload.quantity
            let total = state.total + addedItemTotalPrice
            const items = state.items + payload.quantity
            const extraWithoutLimit = state.minimum - total
            const extra = parseFloat(
                Math.min(extraWithoutLimit, upperLimitOfExtra(state)).toFixed(
                    2,
                ),
            )
            return {
                ...state,
                categories,
                total,
                extra: extra > 0 ? extra : 0,
                items,
                evaluatedCoupon: {},
                lastUpdate: new Date().getTime()
            }
        }
        case ACTION_SET_ITEM_FOR_OPTION_MODE: {
            const optionItem = { ...payload, state: 'open' }
            return {
                ...state,
                optionItem,
                evaluatedCoupon: {},
            }
        }
        case ACTION_SET_STATE_ITEM_FOR_OPTION_MODE:
            return {
                ...state,
                optionItem: { ...state.optionItem, state: 'closed' },
                evaluatedCoupon: {},
            }
        case ACTION_CLEAR_ITEM_FOR_OPTION_MODE:
            return {
                ...state,
                optionItem: {},
                evaluatedCoupon: {},
            }
        case ACTION_REMOVE_COUPON:
            return {
                ...state,
                evaluatedCoupon: {},
                evaluatingCoupon: false,
            }
        case `${ACTION_EVALUATE_COUPON}_PENDING`:
            return {
                ...state,
                evaluatedCoupon: {},
                evaluatingCoupon: true,
            }
        case `${ACTION_EVALUATE_COUPON}_FULFILLED`:
            const {coupon, error} = payload.data
            let evaluatedCoupon = {}
            if (error) {
                evaluatedCoupon = { error }
            }else if ( coupon ){
                const {value, type} = coupon
                let discount = 0
                if(type === 'value'){
                    discount = value
                } else if (type === 'percent'){
                    discount = (state.total * value / 100).toFixed(2)
                }
                evaluatedCoupon = {
                    coupon,
                    discount,
                }
            }
            return {
                ...state,
                evaluatedCoupon,
                evaluatingCoupon: false,
            }
        case `${ACTION_EVALUATE_COUPON}_REJECTED`:
            return {
                ...state,
                evaluatedCoupon: payload.response.data,
                evaluatingCoupon: false,
            }
        case '@@redux-form/CHANGE': {
            return meta.form === 'Coupon'
                ? {
                      ...state,
                      couponCode: payload,
                  }
                : {
                      ...state,
                  }
        }
        case ACTION_RESET_AFTER_PAYMENT: {
            return {
                ...INITIAL_STATE,
                extra: state.extra,
                minimum: state.minimum,
            }
        }
        case ACTION_SELECT_DELIVERY: {
            const minimum = state.isDeliveryPoints ? MINIMUM_ORDER_DELIVERY_VERMUTTINO : MINIMUM_ORDER_DELIVERY
            const extraWithoutLimit = minimum - state.total
            const extra = parseFloat(
                Math.min(
                    extraWithoutLimit,
                    upperLimitOfExtra({ minimum: minimum }),
                ).toFixed(2),
            )

            return {
                ...state,
                minimum: payload.isDelivery ? minimum : 0,
                extra: payload.isDelivery ? extra : 0,
                isDelivery: payload.isDelivery,
            }
        }

        case ACTION_SET_DELIVERYPOINTS_MODE: {
            const minimum = payload.isDeliveryPoints ? MINIMUM_ORDER_DELIVERY_VERMUTTINO : MINIMUM_ORDER_DELIVERY
            return {
                ...state,
                isDeliveryPoints: payload.isDeliveryPoints,
                minimum
            }
        }

        case `persist/REHYDRATE`: {
            const now = new Date().getTime()
            const hasLastUpdate = Boolean(payload?.cart?.lastUpdate)
            const isOldCart = hasLastUpdate && (now - payload?.cart?.lastUpdate > 1 * 60 * 60 * 1000)// > 1h
            
            if(!hasLastUpdate || isOldCart){ 
                return {
                    ...INITIAL_STATE,
                }
            }else {
                return {
                    ...payload.cart,
                }
            }

        }
        default:
            return state
    }
}
