import React, { Component, useState, useEffect } from 'react'
import styled from 'styled-components'
import { withRouter, Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { withContainer, connect } from '../services/container'
import { urls } from '../config'
import { login } from '../stores/actions'
import { device } from '../utils/common-style'
import { ReactComponent as PariaLogo } from '../assets/logo.svg'
import {appStore} from '../App'

const BackNavbar = (props) => {
    const [user, setUser] = useState(null)

    const handleClick = () => {
        if (props.onBackClicked) {
            props.onBackClicked()
        } else {
            props.history.goBack()
        }
    }

    const checkUser = async () => {   
        try {
            const user = await props.container.Auth.currentAuthenticatedUser()
            appStore.dispatch({
                payload: user.attributes,
                type: 'ACTION_USER_DATA_FETCHED',
            })
            setUser(user)
        } catch (error) {
            appStore.dispatch({ type: 'ACTION_USER_LOGOUT' })
            setUser()
        }
    }

    useEffect(() => {
        checkUser()
    }, [])

    return (
        <header className={`mdc-toolbar ${props.className}`}>
            <div className="mdc-toolbar__row fullH">
                <section className="mdc-toolbar__section mdc-toolbar__section--align-start">
                    {/* <PariaLogo /> */}
                    <Link to={'/'} className="center-container">
                        <PariaLogo />
                    </Link>
                    {props.showBack && (
                        <i className="material-icons" onClick={handleClick}>
                            arrow_back
                        </i>
                    )}
                </section>
            </div>
            {!props.hideUser && (
                <div className="buttons">
                    {user && (
                        <Link to={urls.PROFILE}>
                            <i className="material-icons">account_circle</i>
                        </Link>
                    )}
                </div>
            )}
        </header>
    )
}

const StyledBackNavbar = styled(BackNavbar)`
    background-color: transparent;
    height: 56px;
    position: relative;
    .fullH {
        height: 100%;
    }
    .mdc-toolbar__section {
        align-items: center;
    }
    .mdc-toolbar__row {
        min-height: 56px;
    }
    i {
        position: absolute;
        padding: 16px 10px;
        color: ${(props) => props.theme.textDark};
    }
    .center-container {
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 100px;
        margin: 0 auto;
        svg {
            height: 76%;
        }
    }
    .buttons {
        position: absolute;
        right: 20px;
        top: 15px;
        z-index: 20;
        .login-btn {
            background-color: #c6e6be;
            padding: 7px;
            font-size: 15px;
            border-radius: 4px;
            cursor: pointer;
            &:hover {
                background-color: #9de08c;
            }
        }
        i {
            right: 10px;
            padding: 2px;
        }
        a {
            color: black
        }
    }
    @media ${device.laptopL} {
        margin: 0 20vw;
        width: 60vw;
        .buttons {
            top: 35px;
        }
    }
    @media ${device.laptopL} {
        height: 100px;
    }
`


const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            login
        },
        dispatch,
    )
const mapStateToProps = ({ }) => ({
    
})
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withContainer(withRouter(StyledBackNavbar)))
