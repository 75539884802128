import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import BackNavbar from './BackNavbar'
import { prettyDateAndTime } from '../utils/date'
import { withContainer, connect } from '../services/container'
import StepsHighlightOrder from '../components/steps_highlight_order'
import { parseToUnit } from '../utils/numbers'
import InfoCart from '../components/info_cart'
import { urls } from '../config'
import { pageSection, listCard, pxToRem, pageContainer, user } from '../utils/common-style'
import { fetchUserOrders } from '../stores/actions'


class OrderDetails extends Component {
    componentDidMount() {
        this.props.fetchUserOrders();
    }

  renderMooverInfo = order => (
    <div className='moover'>
      <div className='user' />

        <div className='info'>
          <div>
            Il nostro rider
          </div>
          <div>
            {order.status === 'completed'
              ? 'ha completato questa consegna'
              : 'si sta occupando della consegna'}
          </div>
        </div>
    </div>
  )

  orderStatus = order => (
    <div>
      <div className='top'>Stato del tuo ordine</div>
      <StepsHighlightOrder status={order.status} />
      {order.status !== 'taken' && this.renderMooverInfo(order)}
    </div>
  )

  renderContentIfOrder () {
    const { orders } = this?.props?.user
    const order = orders.find(x => x.SK === this.props.id)
    if (!order) {
        return <div>loading</div>
    }

    const data = JSON.parse(order.data)
    return (
        <div>
            {order.type === "takeaway" && <div className='section'>
                <div className='title first'>Ritiro al locale</div>
            </div>}
            {order.type === "delivery" && <div className='section'>
                {this.orderStatus(order)}
                <div className='title first'>Indirizzo di consegna</div>
                <div className='intercome'>{data.delivery.intercome}</div>
                <div className='address'>{data.delivery.fullAddress}</div>
            </div>}
            <div className='section second'>
            <div className='title'>data</div>
            <div className='time'>
                {prettyDateAndTime(order.deliveryAt, ', ')}
            </div>
            </div>
            <div className='section third'>
            {order.payment === 'card' && <div className='title'>Pagato con carta di credito</div>}
            {order.payment === 'card' && <div className='title'>Stato pagamento: {order.paymentStatus}</div>}
            <div className='title'>prodotti ordinati</div>
            {order.couponId && <div className='title'>{`Sconto di ${parseToUnit(order.totalCoupon, 2, '€')} con il coupon ${order.couponId}`}</div>}
            <div className='items'>
                {data.products.map((item, i) => (
                <div className='cart-item-review' key={i}>
                    <div className='left'>
                    <div className='item-title'>
                        {item.quantity > 1 ? `x${item.quantity} ` : ''}
                        {item.name && item.name}
                    </div>
                    <div className='options'>{item.options}</div>
                    <div className='price-wr'>
                        <div className='price'>
                        <span>{parseToUnit(item.price, 2, '€')}</span>
                        </div>
                    </div>
                    </div>
                </div>
                ))}
            </div>
    
            <InfoCart
                className='order-info-cart'
                total={data.totalPrice}
                hasDiscount={!!order.totalCoupon}
                shipmentCost={data.shipmentFee}
                discount={order.totalCoupon}
                extra={data.extra}
                showDeltaRow
            />
            <div className='order-id'>ORDINE ID {order.internalId}</div>
            </div>
        </div>
        )
  }

  render () {
    return (
      <div className={`${this.props.className}`}>
        <BackNavbar
          title='Dettaglio ordine'
          showBack
        />
        <div className="content">
            {this.renderContentIfOrder()}
        </div>
      </div>
    )
  }
}

const StyledOrderDetails = styled(OrderDetails)`
    ${pageContainer};
    .content {
        margin: 0px 20vw 0px 20vw;
        & > div {
            height: calc(100vh - 148px);
        }
    }
  .section {
    ${listCard};
    padding: 32px 39px;
    margin-bottom: 15px;
    .moover {
      display: flex;
      align-items: center;
      font-size: ${pxToRem(14)};
      .user {
        flex-shrink: 0;
        ${user};
      }
      .info {
        margin-left: 6vw;
      }
    }
    .top {
      text-align: center;
      font-size: ${pxToRem(18)};
      color: ${props => props.theme.textDark};
    }
    .pop-title {
      text-align: center;
      font-size: ${pxToRem(24)};
      color: ${props => props.theme.textDark};
      margin-bottom: 29px;
    }
    .pop-sub {
      text-align: center;
      font-size: ${pxToRem(16)};
      color: ${props => props.theme.textDark};
      font-family: Roboto;
      margin: 0 auto;
      max-width: 230px;
      span {
        color: ${props => props.theme.moovendapop};
      }
    }
    .call {
      font-family: Roboto;
      font-size: 16px;
      font-weight: bold;
      color: ${props => props.theme.green};
      display: flex;
      justify-content: center;
      margin-top: 20px;
      margin-bottom: 40px;
      i {
        margin-left: 10px;
      }
    }
    .title {
      ${pageSection};
      margin-bottom: 15px;
      padding: 0;
      &.first {
        margin-top: 20px;
      }
    }
    .intercome {
      color: ${props => props.theme.textDark};
      font-weight: bold;
    }
    .address {
      font-size: ${pxToRem(13)};
      margin-top: 4px;
      color: ${props => props.theme.textLight};
    }
    .time {
      font-size: ${pxToRem(20)};
      font-weight: 900;
      color: ${props => props.theme.moovendaorange};
    }
    &.third {
      .item-title {
        font-size: ${pxToRem(16)};
        text-align: left;
        color: ${props => props.theme.textDark};
      }
      .price-wr {
        display: flex;
        justify-content: flex-end;
        .price {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 70px;
          height: 21px;
          border-radius: 10.5px;
          background-image: linear-gradient(to left, #bbee61, #8bd96c);
          margin: 2px 0px 34px 0px;
          span {
            color: ${props => props.theme.white};
            font-size: ${pxToRem(12)};
            font-weight: 900;
            text-align: center;
          }
        }
      }
      .order-info-cart {
          width: 100%
      }
      .options {
        font-size: ${pxToRem(12)};
        text-align: left;
        color: ${props => props.theme.textLight};
        margin: 10px 0px;
      }
    }
    .order-id {
      font-size: ${pxToRem(10)};
      font-weight: 900;
      letter-spacing: 0.5px;
      text-align: center;
      margin-top: 40px;
      color: ${props => props.theme.textLight};
    }
  }
`

const mapDispatchToProps = dispatch =>
  bindActionCreators({ fetchUserOrders }, dispatch)
const mapStateToProps = ({ user }) => ({ user })
export default connect(mapStateToProps, mapDispatchToProps)(withContainer(StyledOrderDetails))
