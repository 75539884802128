// @flow
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { withContainer, connect } from '../services/container'
import styled from 'styled-components'
import { createLocation } from '../stores/actions'
import BackNavbar from './BackNavbar'
import Address from '../utils/address'
import AddressPicker from '../components/address_picker'
import { pageContainer, device } from '../utils/common-style'
import { urls } from '../config'
import AlertDialog from '../components/alert_dialog'
import FullPageSpinner from '../components/fullPageSpinner'
var polygon = [
    [42.39267749467776, 13.341264886896766],
    [42.351207053323044, 13.317232294123329],
    [42.32240260341531, 13.424692316095985],
    [42.373784573144235, 13.4447766971995],
]

class NewAddress extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tabSelected: 0,
            addressNotInside: false,
            loading: false,
        }
    }

    setLoadingState(newState) {
        this.setState({ loading: newState })
    }

    inside(point, vs) {
        var x = point[0],
            y = point[1]
        var inside = false
        for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
            var xi = vs[i][0],
                yi = vs[i][1]
            var xj = vs[j][0],
                yj = vs[j][1]
            var intersect =
                yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi
            if (intersect) inside = !inside
        }
        return inside
    }

    render() {
        return (
            <div className={this.props.className}>
                <BackNavbar
                    showBack
                    onBackClicked={() => {
                        this.props.history.push(urls.CHECKOUT)
                    }}
                />
                <div className="content">
                    <div>
                        <FullPageSpinner
                            active={this.state.loading}></FullPageSpinner>
                        <AlertDialog
                            visible={this.state.addressNotInside}
                            positive={() => {
                                this.setState({ addressNotInside: false })
                            }}
                            messages={{
                                title: 'Zona non servita',
                                firstSub: `Siamo spiacenti ma il tuo indirizzo é al di fuori della nostra zona di delivery`,
                                secondSub: ``,
                                positive: 'Ok',
                            }}
                        />
                        <AddressPicker
                            key="address"
                            initialIntercome={''}
                            initialNotes={''}
                            initialAddress={new Address()}
                            intercomeRequired
                            addressTabIndex={this.state.tabSelected}
                            selectTabAddress={(x) =>
                                this.setState({ tabSelected: parseInt(x, 10) })
                            }
                            onPickAddress={async (data) => {
                                const payload = {
                                    address: {
                                        city: data.address.city,
                                        number: data.address.number,
                                        street: data.address.street,
                                        zipcode:
                                            data.address.CAP ||
                                            data.address.zipcode,
                                    },
                                    coordinates: {
                                        lat: data.address.lat,
                                        lng: data.address.lng,
                                    },
                                    intercome: data.intercome,
                                    note: data.note,
                                    locType: 'delivery',
                                    preferred: false,
                                }
                                const createLocationSettings = {
                                    dispatchSelectDeliveryAddress: true,
                                }
                                const isInside = this.inside(
                                    [data.address.lat, data.address.lng],
                                    polygon,
                                )
                                if (isInside) {
                                    try {
                                        this.setLoadingState(true)
                                        await this.props.createLocation(
                                            payload,
                                            createLocationSettings,
                                        )
                                        setTimeout(() => {
                                            this.props.history.push(
                                                urls.CHECKOUT,
                                            )
                                        }, 200)
                                    } catch (error) {
                                        console.log(error)
                                    } finally {
                                        this.setLoadingState(false)
                                    }
                                } else {
                                    this.setState({ addressNotInside: true })
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const StyledNewAddress = styled(NewAddress)`
    ${pageContainer};
    .content {
        margin: 0px 20vw 0px 20vw;
        overflow-x: hidden;
        & > div {
            height: calc(100vh - 56px);
        }
    }
`

// height: calc(100vh - 148px);

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            createLocation,
        },
        dispatch,
    )
const mapStateToProps = ({ cart }) => ({
    cart,
})
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withContainer(StyledNewAddress))
