// @flow
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { withContainer, connect } from '../services/container'
import styled from 'styled-components'
import {
    setStateItemForOptionMode,
    clearItemForOptionMode,
    addItemToCart,
    getMenu,
    fetchBaseOptions,
    getStore,
    resetAfterPayment,
    selectCategory,
} from '../stores/actions'
import { urls } from '../config'
import BackNavbar from './BackNavbar'
import CartPreview from '../components/cart_preview'
import RestaurantCategories from './RestaurantCategories'
import RestaurantPages from './RestaurantPages'
import ProductOptions from '../components/product_options'
import { device, pageContainer } from '../utils/common-style'
import { getUrlWithDeliveryPointParam } from '../utils/urlParams'

class Restaurant extends Component {
    state = {
        closed: false,
        deliveryPoint: null,
    }

    async componentDidMount() {
        const res = await this.props.getStore()
        let weekday = [
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
        ][new Date().getDay()]

        const urlSearchParams = new URLSearchParams(window.location.search)
        let category = urlSearchParams.get('category')
        let deliveryPointParam = urlSearchParams.get('deliveryPoint')
        let actionParam = urlSearchParams.get('action')

        const store = res?.value?.data
        if (store?.restDay.length > 0 && store.restDay.indexOf(weekday) >= 0) {
            this.setState({
                closed: true,
                message:
                    'Spiacenti ma oggi siamo chiusi, torna a trovarci domani!',
            })
        } else if (store?.forceClose) {
            this.setState({
                closed: true,
                message:
                    'Il nostro servizio di delivery interno non é operativo, ma puoi ordinare cercando Pariá su Deliveroo e Glovo!',
            })
        } else if (store?.deliveryPointsOffline && deliveryPointParam) {
            this.setState({
                closed: true,
                message:
                    'Siamo spiacenti ma il servizio non é disponibile in questo momento.',
            })
        }
        if (this.props.cart.items === 0) {
            await this.props.getMenu()
            this.props.fetchBaseOptions()
        }
        if (category) {
            const selected = this.props.menu.categories.findIndex(
                (x) => x.item === category,
            )
            setTimeout(() => {
                this.props.selectCategory(selected >= 0 ? selected : 0)
            }, 400)
        }
        const deliveryPoint =
            this.props.appStatePersistent?.deliveryPoints?.find(
                (x) => x.slug === deliveryPointParam,
            )
        if (deliveryPoint) {
            this.setState({
                deliveryPoint,
            })
            if (actionParam === 'clear') {
                this.props.resetAfterPayment()
                this.props.getMenu()
                this.props.fetchBaseOptions()
            }
        } else if (!deliveryPoint && deliveryPointParam) {
            this.props.history.push('/')
        }
    }

    componentDidUpdate(prevProps) {
        const prevCategory = prevProps?.menu?.selectedCategory
        const newCategory = this.props?.menu?.selectedCategory
        if (prevCategory?.index !== newCategory?.index) {
            const newLocationSearch = new URLSearchParams(
                window.location.search
            )
            newLocationSearch.set('category', newCategory.item)
            this.props.history.replace({ search: newLocationSearch.toString() })
        } else if (
            !newCategory &&
            this.props.selectedRestaurant.categories.length
        ) {
            this.props.selectCategory(0)
        }
    }

    render() {
        if (this.state.closed) {
            return (
                <div className={this.props.className}>
                    <BackNavbar />
                    <div className={'closed-message'}>{this.state.message}</div>
                </div>
            )
        }
        return (
            <div className={this.props.className}>
                <BackNavbar />
                {!!this.state.deliveryPoint && (
                    <p className={'deliverypoint'}>
                        {this.state.deliveryPoint.name}
                    </p>
                )}
                {!!this.state.deliveryPoint && (
                    <i className="deliverypoint material-icons">location_on</i>
                )}
                <RestaurantCategories
                    deliveryPoint={this.state.deliveryPoint}
                />
                <div className={'content'}>
                    <div>
                        <RestaurantPages
                            deliveryPoint={this.state.deliveryPoint}
                        />
                    </div>
                </div>
                <CartPreview
                    total={this.props.cart.total}
                    items={this.props.cart.items}
                    onClick={() => {
                        this.props.history.push(
                            getUrlWithDeliveryPointParam(urls.CART),
                        )
                        this.props.clearItemForOptionMode()
                    }}
                />
                <ProductOptions
                    item={this.props.cart.optionItem}
                    onConfirmButtonClicked={this.props.addItemToCart}
                    onCloseModal={this.props.setStateItemForOptionMode}
                    baseOptions={this.props.menu.baseOptions}
                />
            </div>
        )
    }
}

const StyledRestaurant = styled(Restaurant)`
    ${pageContainer};
    .content {
        margin: 0px 20vw 0px 20vw;
        & > div {
            height: calc(100vh - 148px);
        }
        @media ${device.laptopL} {
        }
    }
    .deliverypoint {
        position: absolute;
        left: 10px;
        width: 70px;
        top: 25px;
        text-align: center;
        font-size: 11px;
        &.material-icons {
            top: 10px;
            font-size: 16px;
        }
    }
    .closed-message {
        margin: 10vh 20vw;
        font-size: 30px;
    }
`

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setStateItemForOptionMode,
            clearItemForOptionMode,
            addItemToCart,
            getMenu,
            fetchBaseOptions,
            getStore,
            resetAfterPayment,
            selectCategory,
        },
        dispatch,
    )
const mapStateToProps = ({
    selectedRestaurant,
    cart,
    appStatePersistent,
    menu,
}) => ({
    selectedRestaurant,
    cart,
    appStatePersistent,
    menu,
})
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withContainer(StyledRestaurant))
