import React from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { withContainer, connect } from '../services/container'
class ProtectedRoute extends React.Component {
    render() {
        const Component = this.props.component
        const isAuthenticated = this.props.user.isLogged
        return isAuthenticated ? (
            <Component {...this.props} />
        ) : (
            <Redirect to={{ pathname: '/store' }} />
        );
    }
}


const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {},
        dispatch,
    )
const mapStateToProps = ({ user }) => ({
    user,
})
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withContainer(withRouter(ProtectedRoute)))
