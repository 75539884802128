import React from 'react'
import styled from 'styled-components'

const NavbarPanels = (props) => (
    <div className={`${props.className}`}>
        <div
            className="panel"
            style={{ left: `${props.tabIndex === 0 ? '0' : '-100%'}`,
            opacity: `${props.tabIndex === 0 ? '1' : '0'}` }}>
            {props.children[0]}
        </div>
        <div
            className="panel"
            style={{ left: `${props.tabIndex === 1 ? '0' : '100%'}`,
            opacity: `${props.tabIndex === 1 ? '1' : '0'}` }}>
            {props.children[1]}
        </div>
    </div>
)

const StyledNavbarPanels = styled(NavbarPanels)`
    .panel {
        position: absolute;
        width: 100%;
        transition: 0.3s ease;
        background-color: white;
    }
`

export default StyledNavbarPanels
