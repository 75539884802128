export const urls = {
    PROFILE: '/profile',
    CART: '/cart',
    STORE: '/store',
    DELIVERYMODE: '/delivery-takeaway',
    CHECKOUT: '/checkout',
    THANKYOUPAGE: '/thankyou-page',
    SINGLE_RESTAURANT: '/restaurant',
    REGISTRATION: '/registration',
    ADDRESSES: '/addresses',
    ORDERS: '/orders',
    ORDERDETAILS: '/order-datails/:id',
    PAYMENTMETHODS: '/payment-methods',
    PAYMENTDETAILS: '/payment-details',
    SOMETHINGWRONG: '/something-wrong',
    USERDATA: '/userdata',
    PHONEVERIFICATION: '/phone-verification',
    RESETPASSWORD: '/reset-password',
    SETTINGS: '/settings',
    PAGE: '/page/:content',
    NEWADDRESS: '/new-address',
}

export const social = {
    FB: '167018104441457',
}

export const homeTabs = {
    RESTAURANTS: {
        url: urls.RESTAURANTS,
        order: 1,
        icon: 'restaurant',
    },
    SEARCH: {
        url: urls.SEARCH,
        order: 2,
        icon: 'search',
    },
    PROFILE: {
        url: urls.PROFILE,
        order: 3,
        icon: 'face',
    },
}

export const STAGING_ENV = process.env.NODE_ENV !== 'production'
console.log('process.env.NODE_ENV', process.env.NODE_ENV)

export const test_URL =
    'https://7y25pyd7hj.execute-api.eu-west-1.amazonaws.com/test'
export const prod_URL =
    'https://7y25pyd7hj.execute-api.eu-west-1.amazonaws.com/prod'

// export const baseURL = STAGING_ENV ? test_URL : prod_URL
export const baseURL =  prod_URL

export const updateAmplifyConfig = (config) => {
    var urlsIn = config.oauth.redirectSignIn.split(',')
    var urlsOut = config.oauth.redirectSignOut.split(',')
    const oauth = {
        domain: config.oauth.domain,
        scope: config.oauth.scope,
        redirectSignIn: config.oauth.redirectSignIn,
        redirectSignOut: config.oauth.redirectSignOut,
        responseType: config.oauth.responseType,
    }
    var hasLocalhost = (hostname) =>
        Boolean(
            hostname.match(/localhost/) ||
                hostname.match(
                    /127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}/,
                ),
        )
    var hasHostname = (hostname) =>
        Boolean(hostname.includes(window.location.hostname))
    var isLocalhost = hasLocalhost(window.location.hostname)
    if (isLocalhost) {
        urlsIn.forEach((e) => {
            if (hasLocalhost(e)) {
                oauth.redirectSignIn = e
            }
        })
        urlsOut.forEach((e) => {
            if (hasLocalhost(e)) {
                oauth.redirectSignOut = e
            }
        })
    } else {
        urlsIn.forEach((e) => {
            if (hasHostname(e)) {
                oauth.redirectSignIn = e
            }
        })
        urlsOut.forEach((e) => {
            if (hasHostname(e)) {
                oauth.redirectSignOut = e
            }
        })
    }
    var configUpdate = config
    configUpdate.oauth = oauth
    return configUpdate
}
