import { ACTION_GET_DELIVERY_SLOTS } from './types'

const getDeliverySlots = ({ rest }) => (dispatch, getState) => {
    const request = rest.get(`delivery-slot`)

    dispatch({
        payload: request,
        type: ACTION_GET_DELIVERY_SLOTS,
    })
}

export default getDeliverySlots
