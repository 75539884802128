// https://developers.facebook.com/docs/facebook-pixel/reference#standard-events

const currency = 'EUR'

export const fb_AddToCart = ({ content_name, value, contents }) => {
    if (window.fbq) {
        window.fbq('track', 'AddToCart', {
            content_name,
            value,
            contents,
            currency,
        })
    }
}

export const fb_Purchase = ({ value }) => {
    if (window.fbq) {
        window.fbq('track', 'Purchase', {
            value,
            currency,
        })
    }
}


export const fb_InitiateCheckout = ({value}) => { 
    if (window.fbq) {
        window.fbq('track', 'InitiateCheckout', {
            value,
            currency,
        })
    }
}

