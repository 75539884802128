import { ACTION_ADD_ITEM_TO_CART } from './types'
import { setItemForOptionMode, setStateItemForOptionMode } from '.'
import { fb_AddToCart } from '../../utils/analytics'
const addItemToCart = (
    { Auth },
    category,
    item,
    options = { used: false, list: '', price: 0, quantity: 1 },
) => (dispatch, getState) => {
    if (item?.rules && item?.rules?.length === 0 || options.used) {
        dispatch({
            payload: {
                category,
                item,
                options: options.list,
                optionsPrice: options.price,
                quantity: options.quantity,
            },
            type: ACTION_ADD_ITEM_TO_CART,
        })
        fb_AddToCart({
            content_name: item.name, 
            value: item.price + options.price,
            contents: { 
                id: item.SK, 
                quantity: options.quantity,
                options: options.list,
            }
        })
        if (options.used) {
            dispatch(setStateItemForOptionMode())
        }
    } else {
        dispatch(setItemForOptionMode(null, item))
    }
}

export default addItemToCart
