import React from 'react'
import styled from 'styled-components'
import { pxToRem } from '../utils/common-style'

const ProductDetails = ({product, className}) => {
    if(!product){
        return null
    }
    const imageSrc = product.images?.length > 0 ? product.images[0] : product?.thumb
    return ( <div className={className}>
        <div className="name">
            {product.name}
        </div>
        <img src={imageSrc} alt={product.name} />
        <div className="description">
            {product.description}
        </div>
    </div>
)}

const StyledProductDetails = styled(ProductDetails)`
    min-width: 300px;
    min-height: 50vh;
    img {
        width: min(80vw, 100%)
    }
    .name {
        margin-top: 50px;
        margin-bottom: 15px;
        font-size: ${pxToRem(20)};
        color: ${(props) => props.theme.textDark};
    }
    .description {
        margin-top: 15px;
        font-size: ${pxToRem(14)};
        padding-right: 75px;
        margin-bottom: 50px;
    }
`

export default StyledProductDetails
