import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faYoutube,
    faFacebook,
    faTwitter,
    faInstagram,
} from '@fortawesome/free-brands-svg-icons'
import styled from 'styled-components'

function SocialFollow(props) {
    return (
        <div
            className={`social-container ${props.className}`}>
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/pariapizzeriapartenopea/"
                className="facebook social">
                <FontAwesomeIcon icon={faFacebook} size="2x" />
            </a>
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/pariapizzeriapartenopea/"
                className="instagram social">
                <FontAwesomeIcon icon={faInstagram} size="2x" />
            </a>
        </div>
    )
}

const StyledSocialFollow = styled(SocialFollow)`
    position: absolute;
    bottom: 0;
    padding: 25px 50px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    box-sizing: border-box;
    a.social {
        margin: 0 1rem;
        transition: transform 250ms;
        display: inline-block;
    }
    a.social:hover {
        transform: translateY(-2px);
    }

    a.facebook {
        color: #282727;
    }

    a.instagram {
        color: #282727;
    }
`

export default StyledSocialFollow
