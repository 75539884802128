import React, { Component } from 'react'
import styled from 'styled-components'
import Alert from './alert'
import { pxToRem } from '../utils/common-style'

class AlertDialog extends Component {
    

    renderDialog() {
        const { title, firstSub, secondSub, positive, negative } = this.props.messages
        return (
            <div className={this.props.className}>
                <Alert className={`alert-minimum`}>
                    <div className={`panel`}>
                        <div>
                            <div className="title">{title}</div>
                            <div className="sub-content">
                                <span>
                                    {firstSub}
                                </span>
                            </div>
                            <div className="sub-content last">
                                {secondSub}
                            </div>
                            <div className="actions">
                                {this.props.negative && <div
                                    className="negative"
                                    onClick={() => this.props.negative()}>
                                    {negative}
                                </div>}
                                {this.props.positive && <div
                                    className="positive"
                                    onClick={() => this.props.positive()}>
                                    {positive}
                                </div>}
                            </div>
                        </div>
                    </div>
                </Alert>
            </div>
        )
    }
    render() {
        return <div>{this.props.visible ? this.renderDialog() : ''}</div>
    }
}

const StyledAlertDialog = styled(AlertDialog)`
    position: fixed;
    height: calc(100vh);
    width: 100vw;
    top: 0;
    left: 0;
    background-color: rgba(22, 22, 22, 0.3);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    .alert-minimum {
        background-color: ${(props) => props.theme.white};
        top: 20%;
        .panel {
            padding: 30px 10px;
            .title {
                color: ${(props) => props.theme.textDark};
                font-size: ${pxToRem(29)};
                text-align: center;
                font-family: RecoletaAlt;
                margin-bottom: 20px;
            }
            .sub-content {
                color: ${(props) => props.theme.textDark};
                font-size: ${pxToRem(17)};
                text-align: center;
                font-family: RecoletaAlt;
                &.last {
                    margin-top: 8px;
                    margin-bottom: 16px;
                }
                span {
                    font-weight: bold;
                }
            }
        }
        .actions {
            display: flex;
            flex-direction: row;
            justify-content: center;
            div {
                font-family: Roboto;
                font-size: 20px;
                font-weight: 900;
                text-align: center;
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                &.positive {
                    color: ${(props) => props.theme.green};
                }
                &.negative {
                    color: ${(props) => props.theme.moovendapurple};
                }
            }
        }
    }
`

export default StyledAlertDialog
