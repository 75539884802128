export const ACTION_TAB_SELECTED = 'TAB_SELECTED'
export const ACTION_EDIT_MODE = 'ACTION_EDIT_MODE'
export const ACTION_FETCH_RESTAURANT_EVALUATION =
    'ACTION_FETCH_RESTAURANT_EVALUATION'
export const ACTION_CATEGORY_SELECTED = 'ACTION_CATEGORY_SELECTED'
export const ACTION_FETCH_RESTAURANT_CATEGORIES =
    'ACTION_FETCH_RESTAURANT_CATEGORIES'
export const ACTION_ADD_ITEM_TO_CART = 'ACTION_ADD_ITEM_TO_CART'
export const ACTION_GET_MENU = 'ACTION_GET_MENU'
export const ACTION_GET_STORE = 'ACTION_GET_STORE'
export const ACTION_FETCH_RESTAURANT_STATE = 'ACTION_FETCH_RESTAURANT_STATE'
export const ACTION_DELIVERY_TIME_SELECTED = 'ACTION_DELIVERY_TIME_SELECTED'
export const ACTION_TAB_DAY_SELECTED = 'ACTION_TAB_DAY_SELECTED'
export const ACTION_DELIVERY_ADDRESS_SELECTED =
    'ACTION_DELIVERY_ADDRESS_SELECTED'
export const ACTION_BILLING_ADDRESS_SELECTED = 'ACTION_BILLING_ADDRESS_SELECTED'
export const ACTION_DELIVERY_TIME_IN_RESTAURANT =
    'ACTION_DELIVERY_TIME_IN_RESTAURANT'
export const ACTION_USER_LOGIN = 'ACTION_USER_LOGIN'
export const ACTION_USER_SIGNUP = 'ACTION_USER_SIGNUP'
export const ACTION_USER_LOGOUT = 'ACTION_USER_LOGOUT'
export const ACTION_FILTER_BY_CATEGORY = 'ACTION_FILTER_BY_CATEGORY'
export const ACTION_FETCH_BASE_OPTIONS = 'ACTION_FETCH_BASE_OPTIONS'
export const ACTION_CLEAR_QUERY_RESTAURANT = 'ACTION_CLEAR_QUERY_RESTAURANT'
export const ACTION_LOCATION_DETECTED = 'ACTION_LOCATION_DETECTED'
export const ACTION_SET_SPLASH_DONE = 'ACTION_SET_SPLASH_DONE'
export const ACTION_FETCH_ADVICES = 'ACTION_FETCH_ADVICES'
export const ACTION_SET_ITEM_FOR_OPTION_MODE = 'ACTION_SET_ITEM_FOR_OPTION_MODE'
export const ACTION_SET_STATE_ITEM_FOR_OPTION_MODE =
    'ACTION_SET_STATE_ITEM_FOR_OPTION_MODE'
export const ACTION_CLEAR_ITEM_FOR_OPTION_MODE =
    'ACTION_CLEAR_ITEM_FOR_OPTION_MODE'
export const ACTION_CHANGE_QUANTITY_ITEM_CART =
    'ACTION_CHANGE_QUANTITY_ITEM_CART'
export const ACTION_REMOVE_ITEM_FROM_CART = 'ACTION_REMOVE_ITEM_FROM_CART'
export const ACTION_SET_CHECKOUT_STEP = 'ACTION_SET_CHECKOUT_STEP'
export const ACTION_GET_CHECKOUT_INFO = 'ACTION_GET_CHECKOUT_INFO'
export const ACTION_FETCH_USER_PAYMENTS = 'ACTION_FETCH_USER_PAYMENTS'
export const ACTION_PAYMENT_METHOD_SELECTED = 'ACTION_PAYMENT_METHOD_SELECTED'
export const ACTION_EVALUATE_COUPON = 'ACTION_EVALUATE_COUPON'
export const ACTION_REMOVE_COUPON = 'ACTION_REMOVE_COUPON'
export const ACTION_CREATE_ORDER = 'ACTION_CREATE_ORDER'
export const ACTION_FINALISE_PAYMENT = 'ACTION_FINALISE_PAYMENT'
export const ACTION_INITIAL_ADDRESS_SELECTED = 'ACTION_INITIAL_ADDRESS_SELECTED'
export const ACTION_TAB_ADDRESS_SELECTED = 'ACTION_TAB_ADDRESS_SELECTED'
export const ACTION_DELIVERY_ADDRESS_USED_FOR_ORDER =
    'ACTION_DELIVERY_ADDRESS_USED_FOR_ORDER'
export const ACTION_RESET_AFTER_PAYMENT = 'ACTION_RESET_AFTER_PAYMENT'
export const ACTION_FETCH_USER_ORDERS = 'ACTION_FETCH_USER_ORDERS'
export const ACTION_ORDER_SELECTED = 'ACTION_ORDER_SELECTED'
export const ACTION_SOMETHING_WENT_WRONG = 'ACTION_SOMETHING_WENT_WRONG'
export const ACTION_USER_LOCATIONS_UPDATE = 'ACTION_USER_LOCATIONS_UPDATE'
export const ACTION_USER_LOCATIONS_CREATE = 'ACTION_USER_LOCATIONS_CREATE'
export const ACTION_USER_UPDATE = 'ACTION_USER_UPDATE'
export const ACTION_USER_UPDATE_PRIVACY = 'ACTION_USER_UPDATE_PRIVACY'
export const ACTION_USER_PAYMENT_METHOD_ATTACH =
    'ACTION_USER_PAYMENT_METHOD_ATTACH'
export const ACTION_AUTHORIZE_BRAINTREE = 'ACTION_AUTHORIZE_BRAINTREE'
export const ACTION_USER_LOCATIONS_DELETE = 'ACTION_USER_LOCATIONS_DELETE'
export const ACTION_PAYMENT_SELECTED_FOR_DETAILS =
    'ACTION_PAYMENT_SELECTED_FOR_DETAILS'
export const ACTION_USER_PAYMENT_METHOD_DELETE =
    'ACTION_USER_PAYMENT_METHOD_DELETE'
export const ACTION_RESET_PWD = 'ACTION_RESET_PWD'
export const ACTION_SET_HOME_SCROLL = 'ACTION_SET_HOME_SCROLL'
export const ACTION_FETCH_USER_ORDER_BY_ID = 'ACTION_FETCH_USER_ORDER_BY_ID'
export const ACTION_FETCH_APP_VERSION = 'ACTION_FETCH_APP_VERSION'
export const ACTION_FETCH_DISCLAIMER = 'ACTION_FETCH_DISCLAIMER'
export const ACTION_APP_VERSION_UNSUPPORTED = 'ACTION_APP_VERSION_UNSUPPORTED'
export const ACTION_PASSWORD_TEST = 'ACTION_PASSWORD_TEST'
export const ACTION_CLEAR_USER_ERRORS = 'ACTION_CLEAR_USER_ERRORS'
export const ACTION_SHOW_ALERT_CART_FLUSH = 'ACTION_SHOW_ALERT_CART_FLUSH'
export const ACTION_PROCEED_WITH_FLUSH_CART = 'ACTION_PROCEED_WITH_FLUSH_CART'
export const ACTION_REGISTER_NOTIFICATIONS = 'ACTION_REGISTER_NOTIFICATIONS'
export const ACTION_ACCEPT_NOTIFICATIONS = 'ACTION_ACCEPT_NOTIFICATIONS'
export const ACTION_SUBSCRIBE_NOTIFICATIONS = 'ACTION_SUBSCRIBE_NOTIFICATIONS'
export const ACTION_UNSUBSCRIBE_NOTIFICATIONS =
    'ACTION_UNSUBSCRIBE_NOTIFICATIONS'
export const ACTION_LOCATION_DECLINED = 'ACTION_LOCATION_DECLINED'
export const ACTION_LOCATION_DECLINED_RESET = 'ACTION_LOCATION_DECLINED_RESET'
export const ACTION_SEND_SMS = 'ACTION_SEND_SMS'
export const ACTION_CHECK_CODE = 'ACTION_CHECK_CODE'
export const ACTION_CHECK_TOKEN = 'ACTION_CHECK_TOKEN'
export const ACTION_CREATE_CHARGE = 'ACTION_CREATE_CHARGE'

export const ACTION_GET_DELIVERY_SLOTS = 'ACTION_GET_DELIVERY_SLOTS'
export const ACTION_SELECT_DELIVERY = 'ACTION_SELECT_DELIVERY'
export const ACTION_CREATE_PAYMENT_INTENT = 'ACTION_CREATE_PAYMENT_INTENT'
export const ACTION_SET_PHONE_NUMBER = 'ACTION_SET_PHONE_NUMBER'
export const ACTION_MAILCHIMP_EVENT = 'ACTION_MAILCHIMP_EVENT'
export const ACTION_SET_DELIVERYPOINTS_MODE = 'ACTION_SET_DELIVERYPOINTS_MODE'