import { ACTION_DELIVERY_ADDRESS_SELECTED } from './types'

const selectDeliveryAddress = (
    container,
    { street, zipcode, city, number, lat, lng, intercome, note },
) => (dispatch) => {
    dispatch({
        payload: {
            address: {
                street,
                zipcode,
                city,
                number,
            },
            coordinates: {
                lat,
                lng,
            },
            intercome,
            note,
        },
        type: ACTION_DELIVERY_ADDRESS_SELECTED,
    })
}

export default selectDeliveryAddress
