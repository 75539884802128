// @flow
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { withContainer, connect } from '../services/container'
import Slider from 'react-slick'
import styled from 'styled-components'
import {
    setStateItemForOptionMode,
    clearItemForOptionMode,
    addItemToCart,
    selectDeliveryAddress,
    setCheckoutStep,
    getCheckoutInfo,
    selectDeliveryTime,
    selectTabDay,
    getDeliverySlots,
    selectPaymentMethod,
    createOrder,
    attachPaymentMethod,
    createPaymentIntent,
} from '../stores/actions'
import StepsHighlight from '../components/steps_highlight'
import BackNavbar from './BackNavbar'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { urls } from '../config'
import ConfirmButton from '../components/confirm_button'
import AlertDialog from '../components/alert_dialog'
import InfoCart from '../components/info_cart'
import {
    pageContainer,
    listCard,
    device,
    pxToRem,
    cardAction,
    withModal,
    contextButton,
} from '../utils/common-style'
import CheckoutFirstStep from '../components/checkoutFirstStep'
import CheckoutSecondStep from '../components/checkoutSecondStep'
import CheckoutThirdStep from '../components/checkoutThirdStep'
import FullPageSpinner from '../components/fullPageSpinner'
import { fb_Purchase } from '../utils/analytics'
import {appStore} from '../App'
import {getUrlWithDeliveryPointParam} from '../utils/urlParams'

class Checkout extends Component {
    currentAuthenticatedUser = null
    state = {
        modalOpen: false,
        loading: false,
        alertVisible: false,
        messages: {
            title: '',
            firstSub: '',
            secondSub: '',
            negative: '',
            positive: ''
        }
    }


    constructor(props) {
        super(props)
        this.stateList = ['Indirizzo', 'Orario', 'Pagamento']
    }

    componentDidMount() {
        this.goToStep(0)
        this.props.getDeliverySlots()
        this.props.container.Auth.currentAuthenticatedUser()
        .then((user) => {
            appStore.dispatch({
                payload: user.attributes,
                type: 'ACTION_USER_DATA_FETCHED',
            })
            this.currentAuthenticatedUser = user
            this.props.getCheckoutInfo()
        }).catch((e) => {
            appStore.dispatch({ type: 'ACTION_USER_LOGOUT' })
        })

        let deliveryPointParam = (new URLSearchParams(window.location.search)).get("deliveryPoint")
        const deliveryPoint = this.props.appStatePersistent.deliveryPoints.find(x => x.slug === deliveryPointParam)
        if(deliveryPoint){
            this.setState({
                deliveryPoint
            })
        }
    }

    navigateToThankYouPage() {
        this.props.history.push(urls.THANKYOUPAGE)
    }

    setLoadingState(newState) {
        this.setState({ loading: newState })
    }

    getAlertMessage(code) {
        switch (code) {
            // Invalid vehicles
            // non hai messo rider in turno??
            case 2:
                return 'Purtroppo in questo momento non siamo in grado di prendere in carico il tuo ordine.'
            case 0:// ottimizzazione fallita, suggerisci altro orario
                return 'Purtroppo tutti i nostri fattorini sono occupati nella fascia oraria desiderata, ti suggeriamo di riprovare con un altro orario.'
            default:
                return 'Ti suggeriamo di contattarci telefonicamente.'
        }
    }

    async nextStep() {
        const { step } = this.props.checkout

        if (step === 1 && !this.props.checkout.paymentsFetched) {
            // this.props.fetchUserPayments()
        }
        if (step === 2 && !this.props.timeAddress.time) {
            // Not allowed to go step 3 without delivery time selected
            this.setState({ modalOpen: true })
        } else if (step < 3) {
            this.props.setCheckoutStep(step + 1)
            this.slider.slickGoTo(step)
        }
        if (step === 3 && this.currentAuthenticatedUser) {
            try {
                this.setLoadingState(true)
                const res = await this.props.createOrder()
                if (res?.value?.data?.statusCode === 400 || res?.errorMessage) {
                    this.setLoadingState(false)
                    this.setState({
                        alertVisible: true,
                        messages: {
                            title: `Spiacenti, l'odine non é andato a buon fine`,
                            firstSub: this.getAlertMessage(res.value.data.code),
                            secondSub: ``,
                            negative: '',
                            positive: "OK"
                        }
                    })
                    //modal spiegazione ogni errore TODO
                } else {
                    // order created
                    const { PK, SK, totalPrice } = res.value.data.Attributes
                    fb_Purchase({value: totalPrice})
                    const { selectedPayment } = this.props.checkout
                    if (selectedPayment === 'card') {
                        this.payWithCard({ PK, SK })
                        // on server, if payment ok change paymentStatus in order
                    } else {
                        this.setLoadingState(false)
                        this.navigateToThankYouPage()
                    }
                }
            } catch (err) {
                console.log(err)
                this.setLoadingState(false)
            }
        }
    }

    payWithCard = async (orderKey) => {
        // call createPaymentIntent if card
        const customerId = this.currentAuthenticatedUser.attributes[
            'custom:stripeId'
        ]
        const paymentMethodId = this.props.checkout.selectedCard.id
        try {
            const res = await this.props.createPaymentIntent(
                customerId,
                paymentMethodId,
                orderKey,
            )
            if (res?.value?.data?.paymentStatus === 'failed' ) {
                // Auth card required
                // Insufficient founds
                // card expired
                // TODO tell the reason
                this.setState({
                    alertVisible: true,
                    messages: {
                        title: `Spiacenti, il pagamento non é andato a buon fine.`,
                        firstSub: `Puoi inserire una carta diversa oppure scegliere di pagare in contanti.`,
                        secondSub: ``,
                        negative: '',
                        positive: "OK"
                    }
                })
            } else {
                // payment ok
                this.navigateToThankYouPage()
            }
        } catch (error) {
            console.log(error)
        } finally {
            this.setLoadingState(false)
        }
    }

    onAttachPaymentMethod = (id) => {
        try {
            this.setLoadingState(true)
            return this.props.attachPaymentMethod(id)
        } catch (error) {
            console.log(error)
        } finally {
            this.setLoadingState(false)
        }
    }

    goToStep = (step) => {
        this.props.setCheckoutStep(step + 1)
        this.slider.slickGoTo(step)
    }

    onStepClick = (nextStep) => {
        const { step } = this.props.checkout
        if (nextStep < step) {
            this.goToStep(nextStep)
        }
    }

    invalidSelectedAddress() {
        return (
            this.props.cart.isDelivery &&
            (!this.props.checkout.delivery.address ||
                this.props.checkout.delivery.intercome === 'Citofono mancante')
        )
    }

    cantCheckout() {
        const { selectedCard, selectedPayment } = this.props.checkout
        const invalidPaymentMethod = selectedPayment === 'card' && !selectedCard
        return this.invalidSelectedAddress() || invalidPaymentMethod
    }

    onAddNewAddress = () => {
        this.props.history.push(urls.NEWADDRESS, { detail: 'something' })
    }

    render() {
        const deliveryList = this.props.user.locations.filter(
            (x) => x.locationType === 'delivery',
        )
        const settings = {
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
            swipe: false,
            arrows: false,
            infinite: false,
        }
        return (
            <div className={this.props.className}>
                <BackNavbar
                    title="Checkout"
                    showBack
                    onBackClicked={() => {
                        this.props.history.push(getUrlWithDeliveryPointParam(urls.DELIVERYMODE))
                    }}
                />
                <div className="content">
                    <div>
                        <FullPageSpinner
                            active={this.state.loading}></FullPageSpinner>
                        <StepsHighlight
                            step={this.props.checkout.step}
                            stateList={this.stateList}
                            onStepClick={this.onStepClick}
                        />
                        <Slider
                            ref={(slider) => (this.slider = slider)}
                            {...settings}>
                            <CheckoutFirstStep
                                appStatePersistent={
                                    this.props.appStatePersistent
                                }
                                selectDeliveryAddress={
                                    this.props.selectDeliveryAddress
                                }
                                onAddNewAddress={this.onAddNewAddress}
                                deliveryList={deliveryList}
                                deliveryPoint={this.state.deliveryPoint}
                                isDelivery={
                                    this.props.cart.isDelivery
                                }></CheckoutFirstStep>
                            <CheckoutSecondStep
                                timeAddress={this.props.timeAddress}
                                selectedRestaurant={
                                    this.props.selectedRestaurant
                                }
                                selectDeliveryTime={
                                    this.props.selectDeliveryTime
                                }
                                modalOpen={this.state.modalOpen}
                                selectTabDay={this.props.selectTabDay}
                                isDelivery={
                                    this.props.cart.isDelivery
                                }></CheckoutSecondStep>
                            <CheckoutThirdStep
                                selectPaymentMethod={
                                    this.props.selectPaymentMethod
                                }
                                deliveryPoint={this.state.deliveryPoint}
                                onAddError={
                                    (message) => {this.setState({
                                        alertVisible: true,
                                        messages: {
                                            title: `Spiacenti, l'operazione non é andata a buon fine.`,
                                            firstSub: message || `Puoi inserire una carta diversa oppure scegliere di pagare in contanti.`,
                                            secondSub: '',
                                            negative: '',
                                            positive: "OK"
                                        }
                                    })}
                                }
                                attachPaymentMethod={this.onAttachPaymentMethod}
                                checkout={this.props.checkout}
                                isDelivery={
                                    this.props.cart.isDelivery
                                }></CheckoutThirdStep>
                        </Slider>
                    </div>
                </div>
                <AlertDialog
                    visible={this.state.alertVisible}
                    positive={() => this.setState({alertVisible: false})}
                    messages={this.state.messages}
                />
                <InfoCart
                    className="info-cart"
                    total={this.props.cart.total}
                    hasDiscount={!!this.props.cart?.evaluatedCoupon?.discount}
                    shipmentCost={this.props.selectedRestaurant.evaluation.cost}
                    discount={this.props.cart.evaluatedCoupon?.discount}
                    extra={this.props.cart.extra}
                    minimum={this.props.cart.minimum}
                    showDeltaRow={true}
                />
                <ConfirmButton
                    className={`confirm ${
                        this.cantCheckout() ? 'disabled' : ''
                    } hidden-on-keyboard`}
                    message={'Continua'}
                    loading={this.props.checkout.paymentInAction}
                    onClick={() => {
                        this.nextStep()
                    }}
                />
            </div>
        )
    }
}

const StyledCheckout = styled(Checkout)`
    ${pageContainer};
    .step {
        min-height: 100px;
        text-align: center;
        &.step-1 {
            height: calc(100vh - 304px);
            overflow-y: scroll;
            @media ${device.laptopL} {
                height: calc(100vh - 345px);
            }
            .takeaway-text {
                font-size: 12px;
                color: ${(props) => props.theme.textDark};
                max-width: 280px;
                margin: 0 auto;
            }
            .title-action {
                ${contextButton};
            }
        }
        &.step-2 {
            .time-card {
                ${listCard};
                height: 84px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                .selected-time {
                    font-size: ${pxToRem(20)};
                }
                .action {
                    ${cardAction};
                }
            }
        }
        &.step-3 {
            height: calc(100vh - 304px);
            overflow-y: scroll;
            @media ${device.laptopL} {
                height: calc(100vh - 345px);
            }
            .card-wrapper {
                max-width: 300px;
                max-height: 105px;
                padding: 0;
                margin: 0 auto;
                .rccs {
                    opacity: 0.4;
                    transform: scale(0.4);
                }
                &.selected {
                    .rccs {
                        opacity: 1;
                        transform: scale(0.5);
                    }
                }
            }
            .pay-method {
                max-width: 300px;
                margin: 10px auto;
                .not-available {
                    font-size: 10px;
                    color: #bfbfbf;
                    text-align: right;
                    margin-top: -12px;
                }
            }
            .add-payment-btn {
                ${contextButton};
                margin-top: 30px;
            }
            .info-message {
                padding: 20px 0px 0px 0px;
                text-align: center;
                margin-bottom: -12px;
            }
        }
    }
    .info-cart {
        border-top: 1px solid #deddde;
        position: fixed;
        padding: 10px 40px;
    }
    .confirm {
        position: fixed;
        bottom: 0px;
        height: 54px;
        width: calc(100vw - 34px);
        padding: 11px 0px;
        background-color: white;
    }
    @media ${device.laptopL} {
        .confirm {
            bottom: 0px;
            width: calc(60vw - 34px);
        }
    }
    ${withModal}
`

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setStateItemForOptionMode,
            clearItemForOptionMode,
            addItemToCart,
            selectDeliveryAddress,
            setCheckoutStep,
            getCheckoutInfo,
            selectDeliveryTime,
            selectTabDay,
            getDeliverySlots,
            selectPaymentMethod,
            createOrder,
            attachPaymentMethod,
            createPaymentIntent,
        },
        dispatch,
    )
const mapStateToProps = ({
    checkout,
    cart,
    selectedRestaurant,
    appStatePersistent,
    user,
    timeAddress,
}) => ({
    checkout,
    cart,
    selectedRestaurant,
    appStatePersistent,
    user,
    timeAddress,
})
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withContainer(StyledCheckout))
