import React from 'react'
import styled from 'styled-components'
import { pxToRem } from '../utils/common-style'

const StepsHighlight = (props) => {
    const getBulletClass = (step) =>
        props.step === step ? 'active' : props.step > step ? 'past' : ''

    return (
        <div className={props.className}>
            <div className="line">
                <div
                    className="fill"
                    style={{
                        width: `${((props.step - 1) * 100) / 2}%`,
                        maxWidth: '100%',
                    }}
                />
                <div className="bullets">
                    <div className={`step-bullet ${getBulletClass(1)}`}>
                        <i className="material-icons">check</i>
                        <span onClick={() => props.onStepClick(0)}>
                            {props.stateList[0]}
                        </span>
                    </div>
                    <div className={`step-bullet ${getBulletClass(2)}`}>
                        <i className="material-icons">check</i>
                        <span onClick={() => props.onStepClick(1)}>
                            {props.stateList[1]}
                        </span>
                    </div>
                    <div className={`step-bullet ${getBulletClass(3)}`}>
                        <i className="material-icons">check</i>
                        <span onClick={() => props.onStepClick(2)}>
                            {props.stateList[2]}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

const StyledStepsHighlight = styled(StepsHighlight)`
    height: 80px;
    background-color: ${(props) => props.theme.white};
    display: flex;
    justify-content: center;
    align-items: center;
    .line {
        width: 65%;
        height: 3px;
        background-color: ${(props) => props.theme.separatorColor};
        .fill {
            height: 100%;
            background-color: ${(props) => props.theme.green};
            transition: width 0.5s ease;
        }
        .bullets {
            display: flex;
            justify-content: space-between;
            transform: translate(0px, -11px);
            .step-bullet {
                height: 17px;
                width: 17px;
                background-color: ${(props) => props.theme.separatorColor};
                border-radius: 50%;
                i {
                    opacity: 0;
                    position: absolute;
                    font-size: ${pxToRem(14)};
                    transition: opacity 0.2s;
                    transition-delay: 0.6s;
                    color: white;
                    font-size: ${pxToRem(13)};
                    padding: 2px;
                }
                span {
                    position: absolute;
                    translate: 50%, 0;
                    font-size: ${pxToRem(12)};
                    transform: translate(-36px, -20px);
                    text-align: center;
                    width: 90px;
                    height: 52px;
                }
                &::after {
                    content: '';
                    border: 0px solid ${(props) => props.theme.green};
                    box-sizing: border-box;
                    height: 17px;
                    display: block;
                    width: 17px;
                    border-radius: 50%;
                    transition: all 0.2s;
                    transition-delay: 0.4s;
                }
                &.active {
                    &::after {
                        border: 3px solid ${(props) => props.theme.green};
                        background-color: ${(props) => props.theme.white};
                    }
                }
                &.past {
                    i {
                        opacity: 1;
                    }
                    &::after {
                        background-color: ${(props) => props.theme.green};
                    }
                }
            }
        }
    }
`

export default StyledStepsHighlight
