import React from 'react'
import {
    withGoogleMap,
    GoogleMap,
    Marker,
    withScriptjs,
} from 'react-google-maps'

const MapWithMarker = withScriptjs(
    withGoogleMap((props) => (
        <GoogleMap
            defaultZoom={16}
            center={{ lat: props.lat || 42.3498, lng: props.lng || 13.3995 }}
            options={{
                fullscreenControl: false,
                mapTypeId: 'roadmap',
                mapTypeControl: false,
                rotateControl: false,
                streetViewControl: false,
                zoomControl: false,
                scrollwheel: false,
                navigationControl: false,
                scaleControl: true,
                draggable: true,
                gestureHandling: 'greedy',
            }}>
            {props.renderMarker ? (
                <Marker position={{ lat: props.lat, lng: props.lng }} />
            ) : (
                ''
            )}
        </GoogleMap>
    )),
)

export default MapWithMarker
