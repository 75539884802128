import {
    ACTION_DELIVERY_ADDRESS_USED_FOR_ORDER,
    ACTION_INITIAL_ADDRESS_SELECTED,
    ACTION_ACCEPT_NOTIFICATIONS,
    ACTION_REGISTER_NOTIFICATIONS,
    ACTION_SUBSCRIBE_NOTIFICATIONS,
    ACTION_UNSUBSCRIBE_NOTIFICATIONS,
} from '../actions/types'

const INITIAL_STATE = {
    initialAddress: {
        street: 'Via Celestino V',
        number: '11',
        CAP: '67100',
        city: "L'Aquila",
        lat: 42.3465298,
        lng: 13.3993838,
        formatted_address: `Via Celestino V, 11, 67100 L'Aquila`,
    },
    addressDetails: {
        note: '',
        intercome: '',
    },
    notificationToken: null,
    notificationsAccepted: false,
    notificationSubscribed: false,
    deliveryPoints: [],
    deliveryPointsOffline: false
}
/**
 * @param {Object} state - Default aplication state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action) => {
    const { type, payload } = action
    switch (type) {
        case ACTION_INITIAL_ADDRESS_SELECTED: {
            const {
                street,
                number,
                CAP,
                city,
                lat,
                lng,
                formatted_address,
            } = payload.address
            return {
                ...state,
                initialAddress: {
                    street,
                    number,
                    CAP,
                    city,
                    lat,
                    lng,
                    formatted_address,
                },
                addressDetails: {
                    note: payload.note,
                    intercome: payload.intercome,
                },
            }
        }
        case ACTION_ACCEPT_NOTIFICATIONS: {
            return {
                ...state,
                notificationsAccepted: true,
            }
        }
        case `${ACTION_REGISTER_NOTIFICATIONS}_FULFILLED`: {
            return {
                ...state,
                notificationToken: payload,
            }
        }
        case `${ACTION_SUBSCRIBE_NOTIFICATIONS}_FULFILLED`: {
            return {
                ...state,
                notificationSubscribed: true,
            }
        }
        case `${ACTION_UNSUBSCRIBE_NOTIFICATIONS}_FULFILLED`: {
            return {
                ...state,
                notificationSubscribed: false,
            }
        }
        case ACTION_DELIVERY_ADDRESS_USED_FOR_ORDER: {
            const { address, coordinates, intercome, note } = payload
            const prettyAddress = ({ number, street, zipcode, city }) =>
                `${street}, ${number}, ${zipcode} ${city}`
            return {
                ...state,
                initialAddress: {
                    street: address.street,
                    number: address.number,
                    CAP: address.zipcode,
                    city: address.city,
                    lat: coordinates.lat,
                    lng: coordinates.lng,
                    formatted_address: prettyAddress(address),
                },
                addressDetails: {
                    note,
                    intercome,
                },
            }
        }
        case 'ACTION_GET_STORE_FULFILLED': {
            return {
                ...state,
                deliveryPoints: payload?.data?.deliveryPoints,
                deliveryPointsOffline: payload?.data?.deliveryPointsOffline
            }
        }
        default:
            return state
    }
}
