export const maybePluralize = (count, singular, plural) =>
  `${count} ${count === 1 ? singular : plural}`;

export const compare = ( a, b, prop ) => {
    if(!a[prop] || !b[prop] ){
        return 0;
    }
    if ( a[prop] < b[prop] ){
      return -1;
    }
    if ( a[prop] > b[prop] ){
      return 1;
    }
    return 0;
  }

  export const sortObjectsByOrder = (a, b) => {
    // If either a or b does not have an 'order' property defined, move that object
    // to the end of the array.
    if (a.order === undefined) {
      return 1;
    } else if (b.order === undefined) {
      return -1;
    }

    // Compare the order property of both objects and sort accordingly.
    return a.order - b.order;
  }