import React, { Component } from 'react'
import { confetti } from 'dom-confetti'

const style = {
    position: 'absolute',
    top: '50vh',
    left: '50vw',
}

export default class Confetti extends Component {
    constructor(props) {
        super(props)
        this.setRef = this.setRef.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.active && !this.props.active) {
            setTimeout(() => {
                confetti(this.container, nextProps.config)
            }, nextProps.config.dalay || 1000)
        }
    }

    setRef(ref) {
        this.container = ref
    }

    render() {
        return (
            <div
                className={this.props.className}
                style={style}
                ref={this.setRef}
            />
        )
    }
}
