export default function Address (
  address_components = [],
  geometry = null,
  formatted_address = ''
) {
  this.street = this.findInComponents(address_components, 'route')
  this.number = this.findInComponents(address_components, 'street_number')
  this.CAP = this.findInComponents(address_components, 'postal_code')
  this.city = this.findInComponents(
    address_components,
    'administrative_area_level_3'
  )
  if (geometry && typeof geometry.location.lat === 'function') {
    this.lat = geometry ? geometry.location.lat() : null
    this.lng = geometry ? geometry.location.lng() : null
  } else {
    this.lat = geometry ? geometry.location.lat : null
    this.lng = geometry ? geometry.location.lng : null
  }
  this.formatted_address = formatted_address
}

Address.prototype.findInComponents = function (address_components, type) {
  const component = address_components.find(x => x.types[0] === type)
  return component ? component.long_name : ''
}

Address.prototype.isComplete = function () {
  return !!(
    this.lat && this.lng && this.street && this.number && this.CAP && this.city
  )
}

function nthIndex (str, pat, n) {
  let L = str.length, i = -1
  while (n-- && i++ < L) {
    i = str.indexOf(pat, i)
    if (i < 0) break
  }
  return i
}

export const shortAddress = formattedAddress =>
  formattedAddress.substring(0, nthIndex(formattedAddress, ',', 2))
export const prettyAddress = ({ number, street, zipcode, city }) =>
  `${street}, ${number}, ${zipcode} ${city}`

Address.shortAddress = shortAddress
Address.prettyAddress = prettyAddress
