import { ACTION_USER_LOCATIONS_CREATE } from './types'
import { getCheckoutInfo, selectDeliveryAddress } from '.'

const createLocation = (
    { rest, Auth },
    payload,
    createLocationSettings,
) => async (dispatch, getState) => {
    const config = {}
    const currentUser = await Auth.currentAuthenticatedUser()
    const delivery = {
        userId: currentUser.attributes.sub,
        ...payload.address,
        ...payload.coordinates,
        note: payload.note,
        intercome: payload.intercome,
        phone: '-',
    }
    const request = rest.post(`user/locations`, delivery, config)

    await dispatch({
        payload: request,
        type: ACTION_USER_LOCATIONS_CREATE,
    })
    await dispatch(getCheckoutInfo({ rest, Auth }))
    if (
        createLocationSettings &&
        createLocationSettings.dispatchSelectDeliveryAddress
    ) {
        await dispatch(selectDeliveryAddress({ rest, Auth }, delivery))
    }
}

export default createLocation
