import { theme } from '../theme'

const baseVal = 12

export const size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1079px',
    laptopL: '1440px',
    desktop: '2560px',
}

export const device = {
    mobileS: `(min-width: ${size.mobileS})`,
    mobileM: `(min-width: ${size.mobileM})`,
    mobileL: `(min-width: ${size.mobileL})`,
    tablet: `(min-width: ${size.tablet})`,
    laptop: `(min-width: ${size.laptop})`,
    laptopL: `(min-width: ${size.laptopL})`,
    desktop: `(min-width: ${size.desktop})`,
    desktopL: `(min-width: ${size.desktop})`,
}

export const pxToRem = (px) => {
    const rem = parseFloat((px / parseInt(baseVal, 10)).toPrecision(4))
    return `${rem}rem`
}

export const truncate =
    'white-space: nowrap; overflow: hidden; text-overflow: ellipsis;'

export const listCard = `margin: 7px 12px;
box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.13);
border-radius: 2px;
background-color: ${theme.white};`

export const pageContainer = `
background-image: url("https://paria-web-store.s3-eu-west-1.amazonaws.com/site-assets/paria_pattern.jpg");
background-repeat: repeat;  
height: 100vh;
display: flex;
flex-direction: column;
align-items: center;
.content {
  position: relative;
  width: 100vw;
  & > div {
    height: 100%;
    background-color: #fff;
  }
}
@media ${device.laptopL} {
  background-image: url("https://paria-web-store.s3-eu-west-1.amazonaws.com/site-assets/paria_pattern.jpg");
  background-repeat: repeat;  
  .content{
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.13);
    margin: 0px 20vw;
    border-radius: 3px;
    width: 60vw;
    & > div {
      background-color: #fff;
      height: calc(100vh - 100px);
      overflow-y: scroll;
      padding-bottom: 157px;
      box-sizing: border-box;
      background-color: #fff;
    }
  }
}
`

export const cardAction = `
font-size: ${pxToRem(14)};
font-weight: 900;
text-align: right;
color: ${theme.pariabrown};
position: absolute;
bottom: 14px;
right: 19px;
`

export const cartTotalRow = `
font-size: ${pxToRem(17)};
span {
  float: right;
  &.discount-applied {
    text-decoration: line-through;
    font-weight: normal;
    color: ${theme.textLight};
    padding: 0 7px;
    font-size: ${pxToRem(14)};
    color: #5a0f0f;
  }
}
`

export const pageSection = `
  font-size: ${pxToRem(12)};
  font-weight: 900;
  letter-spacing: 2.1px;
  text-transform: uppercase;
  padding: 0 17px;
  color: ${theme.textLight};
`

export const user = `
  margin-top: 30px;
  margin-bottom: 20px;
  display: inline-block;
  box-sizing: border-box;
  width: 60px;
  height: 60px;
  border: 3px solid ${theme.white};
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-image: url('http://placehold.it/200x200');
`

export const moovendaForm = `
  padding: 70px 40px 100px 40px;
  height: calc(100vh - 273px);
  overflow: scroll;
  .react-floating-label-input {
    width: 100%;
    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      position: relative;
      height: 2em;
      border-bottom: 1px solid #ddd;
    }
    input {
      border: none;
      outline: none;
      font-size: ${pxToRem(13)};
      margin-bottom: 3px;
      &::placeholder {
        color: #9b9b9b;
        opacity: 0;
        transition: opacity 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
        font-size: ${pxToRem(13)};
      }
    }
  }
  .react-floating-label-input * {
    font-family: inherit;
  }
  .react-floating-label-input .react-floating-label-input input,
  .react-floating-label-input label {
    padding: 0;
    margin: 0;
    border: 0;
    text-align: left;
  }
  .react-floating-label-input label {
    position: absolute;
    color: #9b9b9b;
    bottom: 4px;
    transition: all linear 0.2s;
    transform-origin: left top;
    font-size: 1em;
    cursor: text;
    pointer-events: none;
    width: 66.6%;
  }
  .react-floating-label-input.active {
    input::placeholder {
      opacity: 1;
    }
    label {
      color: ${(props) => props.theme.pariabrown};
    }
  }
`

export const withModal = `
.modal-content {
  height: calc(100vh - 215px);
  overflow-y: scroll;
  padding-bottom: 63px;
  box-sizing: border-box;
  .confirm {
      left: 0;
      position: absolute;
      bottom: 0;
      margin: 0 17px;
      width: calc(100% - 34px);
  }
}
&.invalid {
  .modal-content {
      .confirm {
          > div {
              pointer-events: none;
              opacity: 0.5;
          }
      }
  }
}
`

export const contextButton = `background-color: #f9e3d8;
width: 200px;
margin: 10px auto;
padding: 5px;
box-shadow: 0 3px 3px 0 rgba(0,0,0,0.14);
cursor: pointer;`