import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { pxToRem } from '../utils/common-style';

const mapStatusToLine = (status) => {
  const statusMap = {
    taken: '0%',
    STARTED: '33%',
    assigned: '66%',
    COMPLETED: '100%',
  };
  return statusMap[status];
};

const isStepPast = (status, step) => {
  if (status === 'taken') {
    return step <= 1;
  }
  if (status === 'STARTED') {
    return step <= 2;
  }
  if (status === 'assigned') {
    return step <= 3;
  }
  return true;
};

const isCurrent = (status, step) => {
  if (status === 'taken') {
    return step === 1;
  }
  if (status === 'STARTED') {
    return step === 2;
  }
  if (status === 'assigned') {
    return step === 3;
  }
  return step === 4;
};

const getClasses = (status, step) =>
  classNames({
    'step-bullet': true,
    past: isStepPast(status, step),
    current: isCurrent(status, step),
  });

const StepsHighlightOrder = props => (
  <div className={props.className}>
    <div className="line">
      <div className="fill" style={{ width: mapStatusToLine(props.status) }} />
      <div className="bullets">
        <div className={getClasses(props.status, 1)}>
          <i className="material-icons">check</i>
          <span>Preso in carico</span>
        </div>
        <div className={getClasses(props.status, 2)}>
          <i className="material-icons">check</i>
          <span>In preparazione</span>
        </div>
        <div className={getClasses(props.status, 3)}>
          <i className="material-icons">check</i>
          <span>In consegna</span>
        </div>
        <div className={getClasses(props.status, 4)}>
          <i className="material-icons">check</i>
          <span>Consegnato</span>
        </div>
      </div>
    </div>
  </div>
);

const StyledStepsHighlightOrder = styled(StepsHighlightOrder)`
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
  .line {
    width: 100%;
    height: 3px;
    background-color: ${props => props.theme.separatorColor};
    .fill {
      height: 100%;
      background-color: ${props => props.theme.green};
      transition: width 0.5s ease;
    }
    .bullets {
      display: flex;
      justify-content: space-between;
      transform: translate(0px, -11px);
      .step-bullet {
        height: 17px;
        width: 17px;
        background-color: ${props => props.theme.separatorColor};
        border-radius: 50%;
        i {
          opacity: 0;
          position: absolute;
          font-size: ${pxToRem(14)};
          transition: opacity 0.2s;
          transition-delay: 0.6s;
          color: white;
          font-size: ${pxToRem(13)};
          padding: 2px;
        }
        span {
          position: absolute;
          translate: 50%, 0;
          transform: translate(-36px, 22px);
          font-size: ${pxToRem(12)};
          text-align: center;
          width: 90px;
          font-size: ${pxToRem(12)};
          font-weight: 900;
          text-align: center;
          opacity: 0;
          color: ${props => props.theme.green};
        }
        &::after {
          content: '';
          border: 0px solid ${props => props.theme.green};
          box-sizing: border-box;
          height: 17px;
          display: block;
          width: 17px;
          border-radius: 50%;
          transition: all 0.2s;
          transition-delay: 0.4s;
        }
        &.active {
          &::after {
            border: 3px solid ${(props) => props.theme.green};
                        background-color: ${(props) => props.theme.white};
          }
        }
        &.past {
          i {
            opacity: 1;
          }
          &::after {
            background-color: ${(props) => props.theme.green};
          }
          &.current {
            span {
              opacity: 1;
            }
          }
        }
      }
    }
  }
`;

export default StyledStepsHighlightOrder;
