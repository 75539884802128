import React from 'react'
import styled, { keyframes } from 'styled-components'
import Ripples from 'react-ripples'
import { pxToRem } from '../utils/common-style'

const getIcon = ({ loading }) => {
    return loading && <i className="material-icons rotate">local_pizza</i>
}

const ConfirmButton = (props) => (
    <div className={`${props.className}`}>
        <div className="btn-conf">
            <Ripples
                onClick={() =>
                    !props.loading && setTimeout(props.onClick, 700)
                }>
                {!props.loading && (
                    <div className="message">{props.message}</div>
                )}
                {getIcon(props)}
            </Ripples>
        </div>
    </div>
)

const rotate360 = keyframes`
from {
  transform: rotate(0deg);
  transform-origin: 50% 100%;
}

to {
  transform: rotate(360deg);
  transform-origin: 50% 100%;

}
`

const StyledConfirmButton = styled(ConfirmButton)`
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    &.disabled {
        > div {
            pointer-events: none;
            opacity: 0.5;
            cursor: default;
        }
    }
    .btn-conf {
        width: 100%;
        height: 54px;
        padding: 11px 17px;
        color: ${(props) => props.theme.white};
        background-color: #fff;
        border-radius: 2.5px;
        &.invisible {
            display: none;
        }
        > div {
            box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14);
            background-color: ${(props) => props.theme.green};
            display: flex !important;
            justify-content: space-between;
            align-items: center;
            text-align: center;
            padding: 0 16px;
            height: 100%;
            cursor: pointer;
            s {
                top: 50% !important;
                left: 50% !important;
            }
            > div {
                font-size: ${pxToRem(16)};
                font-weight: 900;
                &.total {
                }
                &.message {
                    position: absolute;
                    font-size: ${pxToRem(16)};
                    width: 100%;
                    width: calc(100% - 34px);
                }
            }
        }
        i {
            font-size: ${pxToRem(20)};
            &.rotate {
                animation: ${rotate360} 2s linear infinite;
                font-size: ${pxToRem(18)};
                position: absolute;
                font-size: 1.333rem;
                width: 100%;
                top: 11px;
                left: 3px;
            }
        }
    }
`

export default StyledConfirmButton
