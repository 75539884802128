import { ACTION_DELIVERY_TIME_SELECTED } from './types'

const selectDeliveryTime = (container, x) => (dispatch, getState) => {
    dispatch({
        payload: { ...x },
        type: ACTION_DELIVERY_TIME_SELECTED,
    })
}

export default selectDeliveryTime
