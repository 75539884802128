// @flow
import React, { Component } from 'react'

import { motion, useAnimation } from 'framer-motion'
import styled from 'styled-components'
import { ReactComponent as PariaLogoTop } from '../../assets/paria_logo_02.svg'
import { ReactComponent as PariaLogoBottom } from '../../assets/paria_logo_03.svg'
const _LogoTop = ({ className, big }) => {
    const controls = useAnimation()
    controls.start({
        scale: big ? 1.5 : 0.7,
        top: big ? '30vh' : '0px',
        transition: { duration: 0.7 },
    })
    return (
        <motion.div animate={controls} className={`${className}`}>
            <div className="logo-container">
                <PariaLogoTop></PariaLogoTop>
            </div>
        </motion.div>
    )
}

const _LogoBottom = ({ className, big }) => {
    const controls = useAnimation()
    controls.start({
        scale: big ? 1.5 : 0.7,
        top: big ? '30vh' : '0px',
        opacity: big ? 1 : 0,
        transition: { duration: 0.7 },
    })
    return (
        <motion.div animate={controls} className={`${className}`}>
            <div className="logo-container">
                <PariaLogoBottom></PariaLogoBottom>
            </div>
        </motion.div>
    )
}

const exporter = (cont) => styled(cont)`
    position: fixed;
    z-index: 1;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    .logo-container {
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
            width: 100%;
        }
        @media (max-width: 768px) {
            display: none;
        }
    }
`

// const exporter = (cont) => styled(cont)`
//     position: fixed;
//     z-index: 1;
//     width: 100vw;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     .logo-container {
//         flex-direction: column;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         svg {
//             width: 100%;
//         }
//         @media (max-width: 768px) {
//             svg {
//                 width: 60%;
//             }
//         }
//     }
// `

export const LogoBottom = exporter(_LogoBottom)
export const LogoTop = exporter(_LogoTop)
