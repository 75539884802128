import React, {useRef} from 'react'
import AddPayment from './add_payment'
import Cards from 'react-credit-cards'
import 'react-credit-cards/es/styles-compiled.css'
import { RadioGroup, RadioButton } from 'react-radio-buttons'
import CouponCode from './coupon_code'

const CheckoutThirdStep = ({ checkout, selectPaymentMethod, isDelivery, attachPaymentMethod, onAddError, deliveryPoint }) => {

    const scrollEl = useRef(null)

    const scrollToCoupon = () => {
        if (scrollEl) {
          scrollEl.current.focus();
        }
      }
    
    return (
        <div className="step step-3">
            <div className="pay-method">
            {deliveryPoint?.cardOnly && <p>Pagamento in contanti non disponibile per questo delivery point</p>}
                <RadioGroup onChange={(x) => {
                                selectPaymentMethod({type: x, card: checkout.payments[0]})
                        }
                    } value={checkout.selectedPayment}>
                    <RadioButton value="cash" pointColor={'#90bf84'} disabled={!!deliveryPoint?.cardOnly}>
                        Paga in contanti alla{' '}
                        {`${isDelivery ? 'consegna' : 'cassa'}`}
                    </RadioButton>
                    <RadioButton value="card" pointColor={'#90bf84'} >
                        Paga con carta
                    </RadioButton>
                </RadioGroup>
            </div>
            {checkout.selectedPayment === 'card' &&
                checkout.payments.map((x) => (
                    <div
                        className={`card-wrapper ${
                            x.id === checkout.selectedCard?.id ? 'selected' : ''
                        }`}
                        key={x.id}
                        onClick={() => selectPaymentMethod({type: 'card', card: x})}>
                        <Cards
                            key={x.id}
                            cvc={'000'}
                            expiry={`${x.card.exp_month < 10 ? '0' : ''}${
                                x.card.exp_month
                            }${x.card.exp_year}`}
                            name={``}
                            number={'************' + x.card.last4}
                            issuer={x.card.brand}
                            preview={true}></Cards>
                    </div>
                ))}
            {checkout.selectedPayment === 'card' && (
                <AddPayment  
                    onAddError={onAddError}
                    attachPaymentMethod={attachPaymentMethod}></AddPayment>
            )}
            <div className="info-message">Hai un codice sconto?</div>
            <div
                ref={scrollEl} 
                style={{ float: 'left', clear: 'both' }}
            />
            <CouponCode
              onFocus={() => setTimeout(() => {
                    scrollToCoupon();
                }, 200)
              }
            />
        </div>
    )
}
export default CheckoutThirdStep
