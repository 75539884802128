import { ACTION_EVALUATE_COUPON } from './types'

const evaluateCoupon = ({ Auth, rest }) => async (dispatch, getState) => {
    const coupon = getState().form.Coupon?.values?.term
    if(!coupon){
        return
    }
    const total = getState().cart.total
    const currentUser = await Auth.currentAuthenticatedUser()
    let config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: currentUser.signInUserSession.idToken.jwtToken,
        },
    }

    const request = rest.post(`coupon`, {
        coupon: coupon,
        total: total
    }, config)

    dispatch({
        payload: request,
        type: ACTION_EVALUATE_COUPON,
    })
}

export default evaluateCoupon
