import React from 'react'
import styled from 'styled-components'
import { parseToUnit } from '../utils/numbers'
import { cartTotalRow, pxToRem, device } from '../utils/common-style'

const InfoCart = (props) => {
    const delta =( props.showDeltaRow && props.extra > 0) ? (props.extra) : 0
    const tip = props.tip || 0
    return (
        <div className={`${props.className}`}>
            {props.deltaMinimumBanner && props.extra > 0 ? (
                <div className="info-minimum-order">
                    Il carrello non raggiunge l’ordine minimo di{' '}
                    {parseToUnit(props.minimum, 2, '€')}
                </div>
            ) : null}
            <div className="bill-row">
                Carrello <span>{parseToUnit(props.total, 2, '€')}</span>
            </div>
            {Boolean(delta) ? (
                <div className="bill-row small">
                    Sovrapprezzo
                    <span>{parseToUnit(delta, 2, '€')}</span>
                </div>
            ) : null}
            <div className="bill-row">
                Consegna
                <span>{parseToUnit(props.shipmentCost, 2, '€')}</span>
            </div>

            {/* <div className="bill-row">
                Mancia
                <span>{parseToUnit(tip, 2, '€')}</span>
            </div> */}

            <div className="bill-row">
                Totale
                {props.hasDiscount ? (
                    <span>
                        {parseToUnit(
                            props.total +
                                props.shipmentCost +
                                delta -
                                props.discount +
                                tip,
                            2,
                            '€',
                        )}
                    </span>
                ) : null}
                <span className={props.hasDiscount ? 'discount-applied' : ''}>
                    {parseToUnit(
                        props.total + delta + props.shipmentCost + tip,
                        2,
                        '€',
                    )}
                </span>
            </div>
        </div>
    )
}

const StyledInfoCart = styled(InfoCart)`
    background-color: ${(props) => props.theme.white};
    bottom: 75px;
    height: 84px;
    width: 100%;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: space-between;
    width: 100vw;
    @media ${device.laptopL} {
        width: 60vw;
        bottom: 70px;
    }
    div.info-minimum-order {
        height: 33px;
        background-color: #f3f3f3;
        border-top: solid 1px #e5e5e5;
        font-family: RecoletaAlt;
        font-size: 13px;
        color: ${(props) => props.theme.textDark};
        position: absolute;
        top: -35px;
        left: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    > div {
        font-size: ${pxToRem(14)};
        color: ${(props) => props.theme.textDark};
        span {
            float: right;
        }
        &:last-child {
            ${cartTotalRow};
        }
    }
    .bill-row.small {
        font-size: ${pxToRem(11)};
    }
`

export default StyledInfoCart
