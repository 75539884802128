import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import TimeAddressReducer, { modes } from './reducers_timeAddress'
import SelectedRestaurantReducer from './reducers_selectedRestaurant'
import AppStateReducer from './reducers_appState'
import AppStatePersistentReducer from './reducers_appStatePersistent'
import cartReducer from './reducers_cart'
import userReducer from './reducers_user'
import checkoutReducer from './reducers_checkout'
import menuReducer from './reducers_menu'

export default combineReducers({
    timeAddress: TimeAddressReducer,
    selectedRestaurant: SelectedRestaurantReducer,
    appState: AppStateReducer,
    appStatePersistent: AppStatePersistentReducer,
    cart: cartReducer,
    form: formReducer,
    user: userReducer,
    checkout: checkoutReducer,
    menu: menuReducer,
})

export const timeAddressModes = modes
