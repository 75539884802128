import React, { useState } from 'react'
import { prettyDayNameAndTime, prettyTime } from '../utils/date'
import Modal from 'react-responsive-modal'
import Navbar from '../components/navbar'
import ConfirmButton from '../components/confirm_button'
import styled from 'styled-components'
import { withModal, pxToRem } from '../utils/common-style'

const CheckoutSecondStep = ({
    timeAddress,
    className,
    selectDeliveryTime,
    selectTabDay,
    selectedRestaurant,
    modalOpen,
    isDelivery,
}) => {
    const confirmedDay = timeAddress.days.find((x) => x.confirmed)
    const selectedDay = timeAddress.days.find((x) => x.selected)
    const day = selectedDay || confirmedDay
    const filterTimeslots = (t) => isDelivery ? t.availableDelivery : t.availableTakeaway
    const [editTime, setEditTime] = useState(false)
    const [selectedTime, selectTime] = useState(timeAddress.time)

    React.useEffect(() => {
        if (!editTime && modalOpen) {
            setEditTime(modalOpen)
        }
    }, [modalOpen])

    return (
        <div className="step step-2">
            <div className="time-card">
                <div className="selected-time">
                    {prettyDayNameAndTime(timeAddress.time, ', ') ||
                        'Non hai selezionato un orario'}
                </div>
                <div className="action" onClick={() => setEditTime(true)}>
                    Modifica
                </div>
            </div>

            <Modal
                open={editTime}
                center
                focusTrapped={false}
                animationDuration={150}
                onClose={() => setEditTime(false)}>
                <div className={`${className}`}>
                    <Navbar
                        items={timeAddress.days.reduce(
                            (current, x) => current.concat(x.friendlyName),
                            [],
                        )}
                        activeIndex={day.index}
                        simple
                        onClick={(index) => {
                            selectTabDay(index)
                        }}
                    />
                    <div className="modal-content">
                        {day.index === 0 && (
                            <div className="select-time">
                                {selectedRestaurant?.deliverySlots?.[day.name] &&
                                    selectedRestaurant?.deliverySlots?.[day.name]
                                        .filter(filterTimeslots)
                                        .map((x) => (
                                            <div
                                                key={x.value}
                                                onClick={() =>
                                                    selectTime(x.value)
                                                }
                                                className={`${
                                                    x.value === selectedTime
                                                        ? 'selected'
                                                        : ''
                                                }`}>
                                                {prettyTime(x.value, ', ')}
                                            </div>
                                        ))}
                            </div>
                        )}
                        {day.index === 1 && (
                            <div className="select-time">
                                {selectedRestaurant.deliverySlots[day.name] &&
                                    selectedRestaurant.deliverySlots[day.name]
                                        .filter(filterTimeslots)
                                        .map((x) => (
                                            <div
                                                key={x.value}
                                                onClick={() =>
                                                    selectTime(x.value)
                                                }
                                                className={`${
                                                    x.value === selectedTime
                                                        ? 'selected'
                                                        : ''
                                                }`}>
                                                {prettyTime(x.value, ', ')}
                                            </div>
                                        ))}
                            </div>
                        )}
                        {selectedTime && <ConfirmButton
                            className="confirm"
                            message={'Conferma Orario'}
                            onClick={() => {
                                selectDeliveryTime({
                                    time: selectedTime,
                                })
                                setEditTime(false)
                            }}
                        />}
                    </div>
                </div>
            </Modal>
        </div>
    )
}

const StyledCheckoutSecondStep = styled(CheckoutSecondStep)`
    ${withModal}
    .select-time {
        min-width: 272px;
        font-size: ${pxToRem(18)};
        & > div {
            height: 40px;
            width: 100%;
            border-bottom: 1px solid gray;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            &:hover {
                background-color: #efefef;
            }
            &.selected {
                background-color: #cacaca;
            }
        }
    }
`

export default StyledCheckoutSecondStep
