import { ACTION_GET_MENU } from './types'

const getMenu = ({ rest }) => (dispatch, getState) => {
    const menu = 'menu_1'

    const request = rest.get(`menu?pk=${menu}`)

    dispatch({
        payload: request,
        type: ACTION_GET_MENU,
    })
}

export default getMenu
