import { Auth } from 'aws-amplify'
import React, { Component } from 'react'
import { ReactReduxContext, connect as reduxConnect } from 'react-redux'
import { baseURL } from '../config'
import restService from './rest'

const buildContainer = (services) => {
    return {
        ...services,
    }
}

export const Container = () => {
    if (!this instanceof Container) {
        return new Container()
    }

    const rest = restService({ baseURL })

    return buildContainer({ rest, Auth })
}

const ContainerContext = React.createContext()

export class ContainerProvider extends Component {
    render() {
        return (
            <ContainerContext.Provider
                value={{ container: this.props.container }}>
                {this.props.children}
            </ContainerContext.Provider>
        )
    }
}

export const connect = (stateToProps, dispatchToProps) => {
    // Binds the container on every action
    // and return the new set of actions
    const mapContainerToActions = (actions, container) => {
        const newActionsDecorator = {}
        for (const [key, action] of Object.entries(actions)) {
            const f = action.bind(null, container)
            newActionsDecorator[key] = f
        }

        return newActionsDecorator
    }

    return (BaseComponent) => {
        const NewComponent = reduxConnect(stateToProps)(BaseComponent)

        return class extends Component {
            render() {
                return (
                    // Takes the store from the Redux Context
                    <ReactReduxContext.Consumer>
                        {({ store }) => {
                            const { dispatch } = store
                            return (
                                <ContainerContext.Consumer>
                                    {({ container }) => {
                                        // Maps the container in the actions.
                                        const actions = mapContainerToActions(
                                            // Takes the action wrapped with the dispatch.
                                            // Passes the container in mapDispatchToProps
                                            // inside the ownProps argument in case of need.
                                            dispatchToProps(dispatch, {
                                                ...this.props,
                                                container,
                                            }),
                                            container,
                                        )
                                        // Returns the hoc with actions,
                                        // container and current props
                                        return (
                                            <NewComponent
                                                {...actions}
                                                container={container}
                                                {...this.props}
                                            />
                                        )
                                    }}
                                </ContainerContext.Consumer>
                            )
                        }}
                    </ReactReduxContext.Consumer>
                )
            }
        }
    }
}

export const withContainer = (BaseComponent) => {
    return class extends Component {
        render() {
            return (
                <ContainerContext.Consumer>
                    {({ container }) => (
                        <BaseComponent container={container} {...this.props} />
                    )}
                </ContainerContext.Consumer>
            )
        }
    }
}
