import React, { Component } from 'react'
import styled, { keyframes } from 'styled-components'
import { Field, reduxForm } from 'redux-form'
import { bindActionCreators } from 'redux'
import { withContainer, connect } from '../services/container'
import { listCard, cardAction, pxToRem } from '../utils/common-style'
import { evaluateCoupon, removeCoupon  } from '../stores/actions'

class CouponCode extends Component {
  constructor (props) {
    super(props)
    this.state = { errorAnimation: false, successAnimation: false }
    this.renderTitleField = this.renderTitleField.bind(this)
  }

  componentWillReceiveProps (props) {
    if (props.cart.evaluatedCoupon?.coupon) {
      this.setState({ successAnimation: true })
    } else if (props.cart.evaluatedCoupon?.error) {
      this.setState({ errorAnimation: true })
    }
  }

  renderTitleField (field) {
    return (
      <div>
        <input
          type='text'
          autoCorrect='off'
          placeholder='Aggiungi codice sconto'
          {...field.input}
          onFocus={event => {
            this.props.onFocus()
          }}
        />
      </div>
    )
  }

  renderAction () {
    if (this.props.cart.evaluatingCoupon) {
      return (
        <div className='action'>
          <i className='material-icons rotate'>donut_large</i>
        </div>
      )
    }
    if (this.props.cart.evaluatedCoupon?.coupon) {
      return (
        <div
          className='action'
          onClick={() => {
            this.props.removeCoupon()
            this.setState({ errorAnimation: false, successAnimation: false })
          }}
        >
          Rimuovi
        </div>
      )
    }

    return (
      <div className='action' onClick={() => this.props.evaluateCoupon()}>
        Applica
      </div>
    )
  }

  printError () {
    if (!this.props.cart.evaluatedCoupon) {
        return ''
    }
    const { error } = this.props.cart.evaluatedCoupon
    if (!error) {
      return ''
    }
    const errors = {
      default: 'Coupon non utilizzabile. Contattaci per maggiori informazioni',
      9404: 'Coupon non esistente',
    //   9001: 'Coupon utilizzabile solo al primo acquisto',
      9002: `Spesa minima di € ${error.minimumCart}`,
      9003: 'Coupon scaduto',
      9004: 'Coupon giá utilizzato',  
    }
    return errors[error.code] || errors.default
  }

  onKeyPress (event) {
    if (event.which === 13 /* Enter */) {
      event.preventDefault()
    }
  }

  render () {
    return (
      <div
        className={`${this.props.className} ${this.state.errorAnimation ? 'error' : ''} ${this.state.successAnimation ? 'success' : ''}`}
      >
        {this.props.cart.evaluatedCoupon?.coupon
          ? this.props.cart.couponCode
          : <form onKeyPress={this.onKeyPress}>
            <Field name='term' component={this.renderTitleField} />
          </form>}

        {this.renderAction()}
        <div className='coupon-message error-coupon'>{this.printError()}</div>
        <div className='coupon-message success-coupon'>Coupon applicato</div>
      </div>
    )
  }
}

const rotate360 = keyframes`
from {
  transform: rotate(0deg);
}

to {
  transform: rotate(360deg);
}
`

const StyledCouponCode = styled(CouponCode)`
  ${listCard};
  height: 59px;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 27px;
  box-sizing: border-box;
  margin: 25px auto;
    border-bottom: 3px solid white;
    max-width: 300px;
  input {
    color: ${props => props.theme.textDark};
    font-size: ${pxToRem(14)};
    &::placeholder {
      font-size: ${pxToRem(13)};
      color: ${props => props.theme.textLight};
    }
  }
  .action {
    ${cardAction};
    .rotate {
      animation: ${rotate360} 1s linear infinite;
      font-size: ${pxToRem(18)};
      padding: 1px;
    }
  }
  .coupon-message {
    position: absolute;
    bottom: -20px;
    left: 27px;
    font-size: ${pxToRem(11)};
    font-weight: bold;
    visibility: hidden;
    &.error-coupon {
      color: ${props => props.theme.red};
    }
    &.success-coupon {
      color: ${props => props.theme.green};
    }
  }
  &.error {
    box-shadow: 0 2px 0 0 ${props => props.theme.red};
    .coupon-message.error-coupon {
      visibility: visible;
    }
  }
  &.success {
    box-shadow: 0 2px 0 0 ${props => props.theme.green};
    color: ${props => props.theme.green};
    font-size: ${pxToRem(14)};
    .coupon-message.success-coupon {
      visibility: visible;
    }
  }
`

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      evaluateCoupon,
      removeCoupon
    },
    dispatch
  )
const mapStateToProps = ({ cart }) => ({
  cart
})
export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'Coupon'
  })(StyledCouponCode)
)
