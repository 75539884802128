export const theme = {
    pariabrown: '#252823',
    moovendaorange2: '#eb5516',
    moovendapurple: '#cf6c55',
    moovendapop: '#fa43c3',
    darkorange: '#363934',
    blue: '#518AFF',
    darkyellow: '#FFCD00',
    lime: '#EEFF41',
    green: '#90bf84',
    red: '#cf6c55',
    white: '#FFFFFF',
    loadgrey: '#FEFEFE',
    lightgray: '#fbf8e7',
    darkgray: '#bcbcbc',
    textLight: '#9e9e9e',
    textLight2: '#727272',
    textLight3: '#8a8a8a',
    jagray: '#c6c6c6',
    jagray2: '#d6d6d6',
    jagray3: '#adadad',
    textDark: '#353535',
    textDark2: '#2f2f2f',
    separatorColor: '#dadada',
    consts: {
        margin: '25',
        doubleMargin: '50',
    },
}
