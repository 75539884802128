import { sortObjectsByOrder } from '../../utils/text'
import {
    ACTION_FETCH_RESTAURANT_EVALUATION,
    ACTION_CATEGORY_SELECTED,
    ACTION_GET_DELIVERY_SLOTS,
    ACTION_DELIVERY_TIME_IN_RESTAURANT,
    ACTION_DELIVERY_TIME_SELECTED,
    ACTION_GET_MENU,
    ACTION_SELECT_DELIVERY,
    ACTION_RESET_AFTER_PAYMENT,
} from '../actions/types'

// costo di consegna
const DELIVERY_COST = 1

const INITIAL_STATE = {
    categories: [],
    selectedCategory: null,
    items: {},
    editDeliveryTimeMode: false,
    deliverySlots: [],
    evaluation: {
        cost: DELIVERY_COST,
    },
}

export default (state = INITIAL_STATE, action) => {
    const { type, payload } = action
    switch (type) {
        case `${ACTION_FETCH_RESTAURANT_EVALUATION}_FULFILLED`:
            return {
                ...state,
                evaluationError: payload.data.error || null,
                evaluation: payload.data.evaluation,
            }
        case `${ACTION_GET_MENU}_FULFILLED`: {
            return {
                ...state,
                categories: payload.data.categories
                    .sort(sortObjectsByOrder)
                    .map((i) => ({
                        item: i.SK,
                        displayName: i.name,
                        loaded: true,
                    })),
                items: payload.data.categories.reduce((acc, curr) => {
                    acc[curr.SK] = curr.list
                    return acc
                }, {}),
            }
        }
        case ACTION_CATEGORY_SELECTED:
            if(state.categories.length === 0){
                return {
                    ...state,
                }
            }
            return {
                ...state,
                selectedCategory: {
                    index: payload.index,
                    item: state.categories[payload.index].item,
                },
            }
        case `${ACTION_GET_DELIVERY_SLOTS}_FULFILLED`:
            return {
                ...state,
                deliverySlots: payload.data,
            }
        case ACTION_DELIVERY_TIME_IN_RESTAURANT: {
            return {
                ...state,
                editDeliveryTimeMode: payload,
            }
        }
        case ACTION_DELIVERY_TIME_SELECTED: {
            return {
                ...state,
                editDeliveryTimeMode: false,
            }
        }
        case ACTION_SELECT_DELIVERY: {
            return {
                ...state,
                evaluation: {
                    cost: payload.isDelivery ? DELIVERY_COST : 0,
                },
            }
        }
        case ACTION_RESET_AFTER_PAYMENT: {
            return {
                ...INITIAL_STATE,
                selectedCategory: state.selectedCategory,
                categories: state.categories
            }
        }
        default:
            return state
    }
}
