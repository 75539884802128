import {
  ACTION_EDIT_MODE,
  ACTION_DELIVERY_TIME_SELECTED,
  ACTION_TAB_DAY_SELECTED,
  ACTION_DELIVERY_ADDRESS_SELECTED,
  ACTION_INITIAL_ADDRESS_SELECTED,
} from '../actions/types';
import { todayName, dayName } from '../utils/date';

export const modes = {
  CLOSED: 'closed',
  ADDRESS: 'address',
  TIME: 'time',
};

const INITIAL_STATE = {
  mode: modes.CLOSED,
  days: [
    {
      friendlyName: 'Oggi alle',
      name: todayName,
      selected: true,
      confirmed: false,
      index: 0,
    },
    // {
    //   friendlyName: 'Domani',
    //   name: tomorrowName,
    //   selected: false,
    //   confirmed: false,
    //   index: 1,
    // },
  ],
  time: null,
};

/**
 * @param {Object} state - Default aplication state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_EDIT_MODE:
      return {
        ...state,
        mode: payload.mode,
      };
    case ACTION_DELIVERY_TIME_SELECTED: {
      const days = state.days.map((x) => {
        x.confirmed = false;
        x.selected = false;
        if (x.name === dayName(payload.time)) {
          x.confirmed = true;
          x.selected = true;
        }
        return x;
      });
      return {
        ...state,
        time: payload.time,
        mode: modes.CLOSED,
        days,
      };
    }
    case ACTION_TAB_DAY_SELECTED: {
      const indexToSelect = payload.index;
      const days = state.days.map((x, i) => {
        x.selected = parseInt(indexToSelect, 10) === x.index;
        return x;
      });
      return {
        ...state,
        days,
      };
    }
    case ACTION_INITIAL_ADDRESS_SELECTED: {
      return {
        ...state,
        mode: modes.CLOSED,
      };
    }
    case ACTION_DELIVERY_ADDRESS_SELECTED: {
      return {
        ...state,
        mode: modes.CLOSED,
      };
    }
    default:
      return state;
  }
};
