import React from 'react';
import styled from 'styled-components';
import { pxToRem } from '../utils/common-style';


const ItemQuantity = props => (
  <div className={`${props.className}`}>
    <div className="number-circle" onClick={props.less}>
      <div>-</div>
    </div>
    <div className="number-circle count">
      <div>{props.count}</div>
    </div>
    <div className="number-circle" onClick={props.more}>
      <div>+</div>
    </div>
  </div>
);

const StyledItemQuantity = styled(ItemQuantity)`
  display: flex;
  .number-circle {
    border-radius: 50%;
    font-size: ${pxToRem(18)};
    width: 38px;
    height: 28px;
    color: ${props => props.theme.textDark};
    margin: 0 auto;
    font-weight: bold;
    div {
      text-align: center;
      line-height: 27px;
      display: block;
    }
    &.count {
      font-size: ${pxToRem(16)};
      background-color: ${props => props.theme.lightgray};
    }
  }
`;

export default StyledItemQuantity;
