import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import styled, { keyframes } from 'styled-components'
import { Link } from 'react-router-dom'
import { urls } from '../config'
import AccountTopInfo from '../components/account_top_info'
import { withContainer, connect } from '../services/container'
import BackNavbar from './BackNavbar'
import { pageSection, pxToRem, pageContainer } from '../utils/common-style'
import {appStore} from '../App'

const Account = (props) => {
    const [user, setUser] = useState(null)

    
    useEffect(() => {
        const checkUser = async () => {   
            try {
                const user = await props.container.Auth.currentAuthenticatedUser()
                appStore.dispatch({
                    payload: user.attributes,
                    type: 'ACTION_USER_DATA_FETCHED',
                })
                setUser(user)
            } catch (error) {
                appStore.dispatch({ type: 'ACTION_USER_LOGOUT' })
                setUser()
            }
        }
        checkUser()
    },[])


    const signOut = () => {
        props.container.Auth.signOut()
    }

    return (
      <div className={`${props.className}`} >
        <BackNavbar
            title={'Profilo utente'}
            showBack
        />
        
        <div className="content">
            <div className='grid'>
            {user && <AccountTopInfo user={user} />}
            <Link to={urls.ORDERS} key={'orders'}>
                <div className='ordini item'>
                <i className='material-icons purple-icon'>receipt</i>
                <div>I tuoi ordini</div>
                </div>
            </Link>
            <div className='account'>
                <h2 className='page-title'>account</h2>
                {/* <Link  key={'addresses'}> */}
                    <div className='item'>
                        <i className='material-icons'>location_on</i>Indirizzi salvati
                    </div>
                {/* </Link> */}
                {/* <Link  key={'orders'}> */}
                    <div className='item'>
                        <i className='material-icons'>payment</i>Metodi di pagamento
                    </div>
                {/* </Link> */}
            </div>
            <div className='app'>
                <h2 className='page-title'>applicazione</h2>
                <div className='item' onClick={signOut}>
                <i className='material-icons'>arrow_forward</i>
                Esci
                </div>
            </div>
            </div>
        </div>
      </div>
    )
}

const rotate360 = keyframes`
from {
  transform: rotate(0deg);
}

to {
  transform: rotate(360deg);
}
`

const StyledAccount = styled(Account)`
    ${pageContainer};
    .content {
        margin: 0px 20vw 0px 20vw;
        & > div {
            height: calc(100vh - 148px);
        }
    }
  .grid {
    background-color: white;
    padding-bottom: 30px;
    min-height: calc(100vh - 199px);
    h2.page-title {
      ${pageSection};
      padding: 0;
      margin-bottom: 21px;
    }
    .ordini {
      border-bottom: 1px solid ${props => props.theme.separatorColor};
      padding-left: 41px;
      height: 64px !important;
      &.item {
        color: ${props => props.theme.moovendaorange};
        font-weight: bold;
        margin-bottom: 0;
        i {
          color: ${props => props.theme.moovendaorange};
        }
      }
    }
    .account {
      padding-top: 16px;
      border-bottom: 1px solid ${props => props.theme.separatorColor};
      padding-left: 41px;
    }
    .item {
      height: 24px;
      margin-bottom: 30px;
      font-size: ${pxToRem(16)};
      color: #353535;
      display: flex;
      align-items: center;
      i {
        margin-right: 24px;
        color: #bdbdbd;
        &.rotate {
          animation: ${rotate360} 1s linear infinite;
          font-size: ${pxToRem(18)};
          padding: 1px;
        }
        &.purple-icon {
          color: ${props => props.theme.moovendapurple};
        }
      }
    }
    .app {
      padding-top: 16px;
      padding-left: 41px;
    }
  }
`

const mapDispatchToProps = dispatch => bindActionCreators({ }, dispatch)
const mapStateToProps = ({ user }) => ({ user })
export default connect(mapStateToProps, mapDispatchToProps)(withContainer(StyledAccount))
