import { ACTION_FETCH_BASE_OPTIONS } from './types'

const fetchBaseOptions =
    ({ rest }) =>
    (dispatch, getState) => {
        const request = rest.get(`base-options`)

        return dispatch({
            payload: request,
            type: ACTION_FETCH_BASE_OPTIONS,
        })
    }

export default fetchBaseOptions
