// @flow
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { withContainer, connect } from '../../services/container'
import styled from 'styled-components'
import { parseToUnit } from '../../utils/numbers'
import { pxToRem } from '../../utils/common-style'
import { sortObjectsByOrder } from '../../utils/text'

class MenuCategoryPage extends Component {
    render() {
        return (
            <div>
                {this.props.menu.items[this.props.category.item] ? (
                    <div className={`cat-page ${this.props.className}`}>
                        <ul>
                            {this.props.menu.items[this.props.category.item]
                                .filter(
                                    (x) =>
                                        !this.props.isDelivery ||
                                        !x.deliveryLocked,
                                )
                                .sort(sortObjectsByOrder)
                                .map((i) => (
                                    <li key={i.SK}>
                                        <div>
                                            <div
                                                className={`item ${
                                                    i.locked
                                                        ? 'unavailable'
                                                        : ''
                                                }`}>
                                                {i.locked && (
                                                    <span className="unavailable-text">
                                                        Momentaneamente non
                                                        disponibile
                                                    </span>
                                                )}
                                                <div className="name-price">
                                                    <div className="name">
                                                        {i.name}
                                                    </div>
                                                    <div className="price">
                                                        {parseToUnit(
                                                            i.price,
                                                            2,
                                                            '€',
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="description">
                                                    {i.description}
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                        </ul>
                    </div>
                ) : (
                    ''
                )}
            </div>
        )
    }
}

const StyledCategoryPage = styled(MenuCategoryPage)`
    ul {
        padding-top: 26px;
        margin-bottom: 60px;
        li {
            display: flex;
            > div {
                position: relative;
                display: flex !important;
                width: 100%;
                padding: 5px 0px;
                s {
                    top: 50% !important;
                    left: 50% !important;
                }
                .item {
                    margin: 13px 10px;
                    flex-grow: 1;
                    .unavailable-text {
                        position: absolute;
                        color: #b91111;
                        top: 30px;
                        transform: rotate(-6deg);
                        background-color: white;
                        padding: 2px;
                        border: 1px solid #b91111;
                        border-radius: 6px;
                        left: 14px;
                    }
                    .name-price {
                        display: flex;
                        margin-bottom: 5px;
                        justify-content: space-between;
                        .name {
                            font-size: ${pxToRem(16)};
                            color: ${(props) => props.theme.textDark};
                        }
                        .price {
                            margin-left: 25px;
                            font-size: ${pxToRem(14)};
                            font-family: RecoletaAlt, sans-serif;
                            font-weight: 500;
                            color: ${(props) => props.theme.textDark};
                            white-space: nowrap;
                        }
                    }
                    .description {
                        font-size: ${pxToRem(12)};
                        padding-right: 75px;
                        font-family: WorkSans;
                    }
                    &.unavailable {
                        .name-price {
                            .name {
                                color: #bdbdbd;
                            }
                            .price {
                                color: #bdbdbd;
                            }
                        }
                        .description {
                            color: #bdbdbd;
                        }
                    }
                }
            }
        }
    }
`

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)
const mapStateToProps = ({ menu }) => ({
    menu,
})
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withContainer(StyledCategoryPage))
