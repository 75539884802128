import moment from 'moment'

const dayFromNow = (index) => moment().add(index, 'days')

const dayNameFromNow = (index) => dayFromNow(index).format('dddd').toLowerCase()

const prettyDayName = (dayName) => {
    if (dayName.toLowerCase() === todayName) return 'Oggi'
    if (dayName.toLowerCase() === tomorrowName) return 'Domani'
    return dayName
}

export const todayName = dayNameFromNow(0)

export const tomorrowName = dayNameFromNow(1)

export const prettyDateAndTime = (time, separator = ' ') => {
    if (!time) return
    const day = moment(time).format('DD/MM/YYYY')
    const hour = moment(time).format('HH:mm')
    return `${day}${separator}${hour}`
}

export const prettyTime = (time, separator = ' ') => {
    if (!time) return
    const hour = moment(time).format('HH:mm')
    return `${hour}`
}

export const prettyDayNameAndTime = (time, separator = ' ') => {
    if (!time) return
    const day = moment(time).format('dddd')
    const hour = moment(time).format('HH:mm')
    return `${prettyDayName(day)}${separator}${hour}`
}

export const dayName = (time) => moment(time).format('dddd').toLowerCase()

export const prettyOpenings = (openings) => {
    const myday = openings[todayName] || openings.wildcard
    return myday.reduce(
        (acc, x, i) =>
            `${acc + x.start}-${x.end} ${i + 1 !== myday.length ? ' / ' : ''}`,
        '',
    )
}
