import React, { useState } from 'react'
import Modal from 'react-responsive-modal'
import SplitForm from '../components/SplitForm'

const AddPayment = ({ attachPaymentMethod, onAddError }) => {
    const [isOpen, setModalState] = useState(false)
    return (
        <div>
            <button
                className="add-payment-btn"
                onClick={() => {
                    setModalState(true)
                }}>
                Aggiungi metodo di pagamento
            </button>
            <Modal
                open={isOpen}
                onClose={() => {
                    setModalState(false)
                }}
                center>
                <SplitForm
                    attachPaymentMethod={attachPaymentMethod}
                    onError={(errorMessage) => {
                        setModalState(false)
                        onAddError(errorMessage)
                    }}
                    closeModal={() => {
                        setModalState(false)
                    }}></SplitForm>
            </Modal>
        </div>
    )
}

export default AddPayment
