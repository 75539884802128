// @flow
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { withContainer, connect } from '../services/container'
import styled from 'styled-components'
import { selectDeliveryMode, login, setPhoneNumber, updateUser } from '../stores/actions'
import { urls } from '../config'
import { Link } from 'react-router-dom'
import ConfirmButton from '../components/confirm_button'
import { pageContainer, pxToRem } from '../utils/common-style'
import AlertDialog from '../components/alert_dialog'
import { parseToUnit } from '../utils/numbers'
import InfoCart from '../components/info_cart'
import { RadioGroup, RadioButton } from 'react-radio-buttons'
import BackNavbar from './BackNavbar'
import { device } from '../utils/common-style'
import { fb_InitiateCheckout } from '../utils/analytics'
import 'react-phone-input-2/lib/material.css'
import PhoneInput from 'react-phone-input-2'
import Checkbox from 'react-mdc-web/lib/Checkbox/Checkbox'
import {getUrlWithDeliveryPointParam} from '../utils/urlParams'

class Cart extends Component {
    constructor(props) {
        super(props)
        this.state = {
            minimumOrder: false,
            checked: true,
            deliveryPoint: null
        }
    }

    componentDidMount() {
        if(this.props.user?.cognitoData?.['custom:phone']){
            this.props.setPhoneNumber(this.props.user?.cognitoData?.['custom:phone'])
        }

        let deliveryPointParam = (new URLSearchParams(window.location.search)).get("deliveryPoint")
        const deliveryPoint = this.props.appStatePersistent.deliveryPoints.find(x => x.slug === deliveryPointParam)
        if(deliveryPoint){
            this.setState({
                deliveryPoint
            })
            this.props.selectDeliveryMode( true )
        }
    }

    goToCheckoutIfLogged = async () => {
        if(this.props.user.isLogged) {
            this.checkMinimumOrderOrProceed()
        }else {
            let deliveryPointParam = (new URLSearchParams(window.location.search)).get("deliveryPoint")
            if(deliveryPointParam){
                localStorage.setItem('deliveryPointParam', deliveryPointParam);
            }
            this.props.login()
        }
    }

    checkMinimumOrderOrProceed = () => {
        if (this.props.cart.total < this.props.cart.minimum) {
            const nextState = {
                minimumOrder: true,
            }
            if (this.state.loginModal === false) {
                nextState.loginModal = false
            }
            this.setState(nextState)
        } else {
            fb_InitiateCheckout({value: this.props.cart.total})
            this.props.updateUser()
            this.props.history.push(getUrlWithDeliveryPointParam(urls.CHECKOUT))
        }
    }


    render() {
        const {isLogged, cognitoData} = this.props.user
        const isValidPhone = this.props.checkout?.phone?.length >= 10
        const canProceed = !isLogged || isValidPhone && this.state.checked
        const marketingAcceptedBefore = (cognitoData['custom:marketing'] === 'true') || (cognitoData['custom:marketing'] === true)
        const showMarketing = isLogged && cognitoData && !marketingAcceptedBefore
        const messages = {
            title: 'Ordine minimo',
            firstSub: `Il carrello non raggiunge l'ordine minimo di ${parseToUnit(this.props.cart.minimum, 2, '€')}.`,
            secondSub: `Aggiungi nuovi prodotti o procedi aggiungendo ${parseToUnit(this.props.cart.extra, 2,'€')} extra`,
            negative: 'Aggiungi prodotti',
            positive: "Procedi all'acquisto"
        }

        return (
            <div className={`${this.props.className}`}>
                <BackNavbar
                    title={this.props.selectedRestaurant.partnerName}
                    showBack
                    onBackClicked={() => {
                        this.props.history.push(getUrlWithDeliveryPointParam(urls.CART))
                    }}
                />
                <div className="content">
                    <div>
                        <div className="delivery-mode">

                        {this.props.user.isLogged && <div>
                                <div className="info-phone">Inserisci un numero di telefono, lo useremo per contattarti in caso di necessitá</div>
                                <PhoneInput
                                    country='it'
                                    regions={'europe'}
                                    label="test"
                                    inputProps={{
                                        name: 'phone',
                                        required: true,
                                    }}
                                    specialLabel="Telefono"
                                    countryCodeEditable={false}
                                    value={this.props.checkout.phone}
                                    onChange={phone => this.props.setPhoneNumber(phone)}
                                />
                            </div>}

                        {this.state.deliveryPoint && <div className="info-message">{`Il tuo ordine verra consegnato presso il delivery point: ${this.state.deliveryPoint.name}`}</div>}
                        {this.state.deliveryPoint && <div className="info-message">{`${this.state.deliveryPoint.instructions}`}</div>}
                        {!this.state.deliveryPoint && <div className="info-message">Scegli tra il ritiro in sede e la consegna a casa</div>}
                            {!this.state.deliveryPoint && <RadioGroup
                                onChange={(x) =>
                                    this.props.selectDeliveryMode(
                                        x === 'delivery',
                                    )
                                }
                                value={
                                    this.props.cart.isDelivery
                                        ? 'delivery'
                                        : 'takeaway'
                                }>
                                <RadioButton
                                    value="delivery"
                                    pointColor={'#90bf84'}>
                                    Consegna a casa
                                </RadioButton>
                                <RadioButton
                                    value="takeaway"
                                    pointColor={'#90bf84'}>
                                    Ritiro presso il locale
                                </RadioButton>
                            </RadioGroup>}
                            {showMarketing && <div>
                                <div className="privacy-check"
                                    onClick={() => {
                                        this.setState({
                                            checked: !this.state.checked
                                        })
                                    }}>
                                    <Checkbox
                                        readOnly
                                        checked={this.state.checked}
                                    />
                                    <label>Acconsento al Trattamento Dati per la ricezione, di comunicazioni a carattere informativo e/o commerciale come esplicitamente descritto nella <Link to={'privacy-policy'}> privacy policy</Link></label>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
                <InfoCart
                    className="info-cart"
                    total={this.props.cart.total}
                    hasDiscount={!!this.props.cart.evaluatedCoupon?.discount}
                    shipmentCost={this.props.selectedRestaurant.evaluation.cost}
                    discount={this.props.cart.evaluatedCoupon?.discount}
                    extra={this.props.cart.extra}
                    minimum={this.props.cart.minimum}
                    deltaMinimumBanner
                />
                <ConfirmButton
                    className={`confirm ${canProceed ? '' : 'disabled'}`}
                    message={'Conferma i prodotti scelti'}
                    onClick={this.goToCheckoutIfLogged}
                />
                <AlertDialog
                    visible={this.state.minimumOrder}
                    positive={() => this.props.history.push(getUrlWithDeliveryPointParam(urls.CHECKOUT))}
                    negative={() => {
                        this.setState({ minimumOrder: false })
                        this.props.history.push(getUrlWithDeliveryPointParam(urls.CART))
                    }}
                    messages={messages}
                />
            </div>
        )
    }
}

const StyledCart = styled(Cart)`
    ${pageContainer};
    .content {
        padding-bottom: 200px;
    }
    .info-cart {
        border-top: 1px solid #deddde;
        position: fixed;
        padding: 10px 40px;
    }
    .confirm {
        position: fixed;
        bottom: 0px;
        height: 54px;
        width: calc(100vw - 34px);
        padding: 11px 0px;
        background-color: white;
    }

    .delivery-mode {
        max-width: 300px;
        margin: 10px auto;
        .info-message {
            padding: 40px 0px 8px 0px;
            text-align: center;
        }
        .info-phone {
            padding: 11px 0px 11px 0px;
            text-align: center;
        }
        .privacy-check {
            display: flex;
            margin-top: 12px;
            cursor: pointer;
            font-size: 9px;
        }
    }
    @media ${device.laptopL} {
        .confirm {
            bottom: 0px;
            width: calc(60vw - 34px);
        }
    }


    .mdc-checkbox__native-control:checked ~ .mdc-checkbox__background {
        background-color: ${(props) => props.theme.green};
        border-color: ${(props) => props.theme.darkorange};
    }
    .mdc-checkbox__background {
        width: 35%;
        height: 35%;
    }
    .mdc-checkbox__background:before {
        background: ${(props) => props.theme.darkorange};
        width: 35%;
        height: 35%;
        margin: 15%;
    }

`

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            selectDeliveryMode,
            setPhoneNumber,
            login,
            updateUser
        },
        dispatch,
    )
const mapStateToProps = ({ selectedRestaurant, cart, user, checkout, appStatePersistent }) => ({
    selectedRestaurant,
    cart,
    user,
    checkout,
    appStatePersistent
})
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withContainer(StyledCart))
