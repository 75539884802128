import React, { Component } from 'react'
import styled from 'styled-components'
import PlacesAutocomplete, { geocodeByPlaceId } from 'react-places-autocomplete'
import { truncate, pxToRem } from '../utils/common-style'

class SearchAddress extends Component {
    constructor(props) {
        super(props)
        this.state = { term: '', noCivic: false, edit: !props.address }
    }

    onChange = (term) => this.setState({ term })

    onSelect = (inputAddress, placeId, suggestion) => {
        this.placesAutocompleteRef.current.blur()
        geocodeByPlaceId(placeId)
            .then((res) => this.onGeocodedPlaceById(res, suggestion))
            .catch((error) => console.error(error))
        this.setState({ term: inputAddress })
    }

    onGeocodedPlaceById = (res, suggestion) => {
        const proceed = (_res) => {
            this.setState({ noCivic: false })
            this.props.onAddressComplete(_res)
        }

        const arrayWithStreetNumber = res.find((x) =>
            x.address_components.find((t) => t.types[0] === 'street_number'),
        )
        // if the selected address is complet in the first attempt
        if (arrayWithStreetNumber) {
            proceed(arrayWithStreetNumber)
        } else {
            // check if we had street_number in suggestions
            if (suggestion.terms.length === 5) {
                res[0].address_components.push({
                    long_name: suggestion.terms[1].value,
                    short_name: suggestion.terms[1].value,
                    types: ['street_number'],
                })
                proceed(res[0])
            } else {
                // substr to first comma
                const newTerm = `${this.state.term.substr(
                    0,
                    this.state.term.indexOf(',') + 1,
                )} `
                this.setState({ term: newTerm, noCivic: true })
                this.placesAutocompleteRef.current.focus()
            }
        }
    }

    render() {
        const options = {
            componentRestrictions: { country: 'it' },
            types: ['address'],
            language: 'it',
            location: new window.google.maps.LatLng(42.3498, 13.3995),
            radius: 25000, //25km
        }
        this.placesAutocompleteRef = React.createRef()
        return (
            <div className={this.props.className}>
                {this.state.edit ? (
                    <PlacesAutocomplete
                        searchOptions={options}
                        onSelect={this.onSelect}
                        onChange={this.onChange}
                        value={this.state.term}
                        debounce={1000}>
                        {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                        }) => (
                            <div className="content-search">
                                <i className="material-icons search">search</i>
                                <input
                                    ref={this.placesAutocompleteRef}
                                    {...getInputProps({
                                        placeholder: 'Cerca indirizzo..',
                                        className: 'location-search-input',
                                    })}
                                />
                                <div className="autocomplete-dropdown-container">
                                    {suggestions.map((suggestion) => {
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                            ? {
                                                  backgroundColor: '#fafafa',
                                              }
                                            : {}
                                        return (
                                            <div
                                                key={suggestion.place_id}
                                                {...getSuggestionItemProps(
                                                    suggestion,
                                                    {
                                                        className:
                                                            'autocomplete-item',
                                                        style,
                                                    },
                                                )}>
                                                <span>
                                                    {suggestion.description}
                                                </span>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )}
                    </PlacesAutocomplete>
                ) : (
                    <div className="static-address">
                        <span>{this.props.address} </span>
                        <i
                            className="material-icons"
                            onClick={() => {
                                this.setState({ edit: true })
                                this.props.onSwitchToEditMode()
                            }}>
                            add_circle
                        </i>
                    </div>
                )}

                {this.state.noCivic ? (
                    <div className="no-civic">Inserisci il numero civico</div>
                ) : (
                    ''
                )}
            </div>
        )
    }
}

const StyledSearchAddress = styled(SearchAddress)`
    height: 35px;
    position: absolute;
    z-index: 1;
    width: 100%;
    top: 10px;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.textLight};
    justify-content: center;
    .content-search {
        width: 100%;
    }
    input {
        height: 35px;
        font-size: ${pxToRem(13)};
        width: calc(100% - 100px) !important;
        margin: 0 20px;
        padding: 0 10px 0 50px;
        background-color: ${(props) => props.theme.white};
        color: ${(props) => props.theme.textDark};
        ${truncate};
        &::placeholder {
            color: #cacaca;
            font-size: ${pxToRem(13)};
        }
    }
    .autocomplete-dropdown-container {
        border: none;
        position: absolute;
        top: 35px;
        width: calc(100% - 20px);
        left: 0;
        height: calc(100vh - 153px);
        z-index: 2;
        padding: 0 10px;
    }
    .autocomplete-item {
        border-bottom: 1px solid #efefef;
        height: 58px;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        padding: 0px 37px;
        cursor: pointer;
    }
    i.material-icons.search {
        position: absolute;
        left: 25px;
        z-index: 3;
        color: #c6c6c6;
        top: 6px;
    }
    #PlacesAutocomplete__root {
        height: 35px;
        border-radius: 4px;
        background-color: #aa3281;
        width: 100%;
        margin: 0 25px;
    }
    .static-address {
        width: calc(100% - 50px);
        height: 35px;
        border-radius: 4px;
        background-color: #aa3281;
        display: flex;
        align-items: center;
        font-size: ${pxToRem(13)};
        margin: 0 25px;
        color: ${(props) => props.theme.white};
        span {
            white-space: nowrap;
            max-width: calc(100% - 90px);
            overflow: hidden;
            text-overflow: ellipsis;
            margin-left: 39px;
        }
        i {
            transform: rotate(45deg);
            font-size: ${pxToRem(21)};
            position: absolute;
            right: 43px;
            opacity: 0.7;
            color: white;
        }
    }
    div.no-civic {
        position: absolute;
        top: 37px;
        background-color: white;
        z-index: 1;
        height: 23px;
        width: calc(100% - 20px);
        color: #e20d0d;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        font-size: ${pxToRem(12)};
    }
`

// &:before {
//   content: '';
//   display: block;
//   width: 0;
//   height: 0;
//   position: absolute;
//   border-top: 8px solid transparent;
//   border-bottom: 8px solid transparent;
//   border-right: 8px solid ${props => props.theme.red};
//   left: -8px;
//   top: 7px;
//   transform: rotate(90deg);
//   top: -11px;
//   left: 50%;
// }
export default StyledSearchAddress
