// @flow
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { withContainer, connect } from '../../services/container'
import styled from 'styled-components'
import SwipeableViews from 'react-swipeable-views'
import { selectCategory } from '../../stores/actions'
import MenuCategoryPage from '../../components/home/MenuCategoryPage'
import { device } from '../../utils/common-style'
export const scrollOnCategorySelected = () => {
    setTimeout(() => {
        const ref = 220 + 0
        if (window.scrollY > ref) {
            window.scrollTo(0, ref)
        }
    }, 100)
}
class MenuPages extends Component {
    render() {
        return (
            <div className={this.props.className}>
                {this.props.menu.categories.length > 0 ? (
                    <SwipeableViews
                        onChangeIndex={(index) => {
                            scrollOnCategorySelected()
                            this.props.selectCategory(index)
                        }}
                        index={this.props.menu.selectedCategory.index}>
                        {this.props.menu.categories.map((i) => (
                            <MenuCategoryPage
                                key={i.item}
                                category={i}
                                isDelivery={this.props.isDelivery}
                            />
                        ))}
                    </SwipeableViews>
                ) : (
                    ''
                )}
            </div>
        )
    }
}

const StyledRestaurantPages = styled(MenuPages)`
    min-height: calc(100vh - 56px - 48px);
    background-image: url("https://paria-web-store.s3-eu-west-1.amazonaws.com/site-assets/paria_pattern.jpg");
    @media ${device.laptopL} {
        min-height: calc(100vh - 100px - 48px - 112px);
    }
`

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ selectCategory }, dispatch)
const mapStateToProps = ({ menu }) => ({
    menu,
})
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withContainer(StyledRestaurantPages))
