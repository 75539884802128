import { ACTION_GET_CHECKOUT_INFO } from './types'
import { selectPaymentMethod } from '../actions'

const getCheckoutInfo = ({ rest, Auth }) => async (dispatch, getState) => {
    const currentUser = await Auth.currentAuthenticatedUser()
    const config = {}
    const request = rest.get(
        `user/checkout-info?userId=${currentUser.attributes.sub}&sid=${currentUser.attributes['custom:stripeId']}`,
        config,
    )

    return dispatch({
        payload: request,
        type: ACTION_GET_CHECKOUT_INFO,
    }).then((res) => {
        const cards = res?.value?.data?.cards.sort(
            (a, b) => a.created - b.created,
        )
        if (cards?.length) {
            dispatch(
                selectPaymentMethod(
                    { rest, Auth },
                    { type: 'card', card: cards[0] },
                ),
            )
        }
    })
}

export default getCheckoutInfo
