import { ACTION_ADD_ITEM_TO_CART, ACTION_REMOVE_ITEM_FROM_CART } from './types'
import { setStateItemForOptionMode } from '.'

const replaceItemToCart = (
    { rest },
    category,
    item,
    options = { used: false, list: '', price: 0 },
) => (dispatch, getState) => {
    dispatch({
        payload: { catName: category, idCart: item.cartId },
        type: ACTION_REMOVE_ITEM_FROM_CART,
    })
    setTimeout(
        () =>
            dispatch({
                payload: {
                    category,
                    item,
                    options: options.list,
                    optionsPrice: options.price,
                    quantity: options.quantity,
                },
                type: ACTION_ADD_ITEM_TO_CART,
            }),
        400,
    )
    dispatch(setStateItemForOptionMode())
}

export default replaceItemToCart
