import { ACTION_USER_UPDATE } from './types'

const updateUser = ({ Auth, rest }) => async (dispatch, getState) => {

    const currentUser = await Auth.currentAuthenticatedUser()
    const phone = getState().checkout?.phone
    let config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: currentUser.signInUserSession.idToken.jwtToken,
        },
    }
    const request = rest.post(`user/cognito`, {phone}, config)

    return dispatch({
        payload: request,
        type: ACTION_USER_UPDATE,
    }).then(async () => {
        const userInfo = await Auth.currentUserInfo()
        dispatch({
            payload: userInfo.attributes,
            type: 'ACTION_USER_DATA_FETCHED',
        })
    })
}

export default updateUser
