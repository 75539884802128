import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import BackNavbar from '../containers/BackNavbar'
import { pageSection, listCard, pxToRem, pageContainer } from '../utils/common-style'
import { urls } from '../config'
import { withContainer, connect } from '../services/container'
import { parseToUnit } from '../utils/numbers'
import { prettyDateAndTime } from '../utils/date'
import {
    selectOrderForDetails, fetchUserOrders
} from '../stores/actions'


class Orders extends Component {

  componentDidMount() {
    this.props.fetchUserOrders();
  }


  ongoingOrdersSection() {
    const ongoingOrders = this.props.user.orders.filter(x => {return x.status !== 'completed' && x.type === 'delivery'})
    const ongoingCount = ongoingOrders.length;
    return (
      <div>
        {ongoingCount > 0 ? (
          this.ongoingOrders(ongoingOrders)
        ) : (
          <div className="empty">Non ci sono ordini in corso</div>
        )}
      </div>
    )
  }

  ongoingOrders(ongoingOrders) {
    return ongoingOrders.map((item, index) => (
      <Link
        to={`/order-datails/${item.SK}`}
        key={`link-${item.SK}`}
      >
        <div className="order-item" >
          <div className="item-row">
          <div className="name">{prettyDateAndTime(new Date(Number(item.createdAt)), ' ore: ')}</div>
            <div className="time">{`${item.type}`}</div>
          </div>
          <div className="item-row">
            <div className="products">{JSON.parse(item.data)?.products.length} prodotti</div>
            <div className="price">{parseToUnit(item.totalPrice, 2, '€')}</div>
          </div>
        </div>
      </Link>
    ));
  }

  completedOrders(completedOrders) {
    return completedOrders.map((item, index) => (
      <Link
        to={`/order-datails/${item.SK}`}
        key={`link-${item.SK}`}
      >
        <div className="order-item" >
          <div className="item-row">
            <div className="name">{prettyDateAndTime(new Date(Number(item.createdAt)), ' ore: ')}</div>
            <div className="time">{`${item.type}`}</div>
          </div>
          <div className="item-row">
            <div className="products">{JSON.parse(item.data)?.products.length} prodotti</div>
            <div className="price">{parseToUnit(item.totalPrice, 2, '€')}</div>
          </div>
        </div>
      </Link>
    ));
  }

  noCompletedOrders() {
    return (
      <div>
          <div className="empty">
            Non hai ancora effettuato un ordine.
          </div>
      </div>
    );
  }

  completedOrdersSection() {
    const completedOrders = this.props.user.orders.filter(x => {return x.status === 'completed' || x.type === 'takeaway'})
    const completedCount = completedOrders.length;
    return completedCount > 0 ? this.completedOrders(completedOrders) : this.noCompletedOrders();
  }

  render() {
    return (
      <div className={`${this.props.className}`}>
        <BackNavbar
          title="Ordini"
          showBack
        />
        <div className="content">
            <div className="orders-list">
                <div>
                <div className="title">Ordini in corso</div>
                {this.props.user.orders && this.ongoingOrdersSection()}
                <div className="title">Ordini effettuati</div>
                {this.props.user.orders && this.completedOrdersSection()}
                </div>
            </div>
        </div>
      </div>
    );
  }
}

const StyledOrders = styled(Orders)`
    ${pageContainer};
    .content {
        margin: 0px 20vw 0px 20vw;
        & > div {
            height: calc(100vh - 148px);
        }
    }
  .orders-list {
    background-color: ${props => props.theme.lightgray};
    padding-top: 65px;
    height: calc(100vh - 65px);
    overflow: scroll;
    .empty {
      padding-left: 17px;
      padding-bottom: 40px;
      font-size: ${pxToRem(12)};
      .action {
        margin-top: 30px;
        font-size: ${pxToRem(14)};
        a {
          margin-left: 10px;
          color: ${props => props.theme.moovendaorange};
          font-weight: bold;
        }
      }
    }
    .title {
      ${pageSection};
      margin-top: 20px;
      margin-bottom: 15px;
    }
    .order-item {
      ${listCard};
      height: 90px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      margin-bottom: 15px;
    }
    .item-row {
      display: flex;
      justify-content: space-between;
      padding: 0 29px;
      > div {
        font-size: ${pxToRem(14)};
      }
      .name {
        color: ${props => props.theme.textDark};
        font-weight: bold;
        font-size: ${pxToRem(16)};
      }
      .price {
        color: ${props => props.theme.green};
        white-space: nowrap;
      }
      .products {
        font-size: ${pxToRem(13)};
        color: ${props => props.theme.moovendaorange};
      }
      .time {
        font-size: ${pxToRem(13)};
        font-weight: 300;
        color: ${props => props.theme.textLight};
      }
    }
  }
`;

const mapDispatchToProps = dispatch =>
  bindActionCreators({ selectOrderForDetails, fetchUserOrders }, dispatch);
const mapStateToProps = ({ activeTab, user }) => ({ activeTab, user });
export default connect(mapStateToProps, mapDispatchToProps)(withContainer(StyledOrders))
