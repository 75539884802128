// @flow
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import ReactFullpage from '@fullpage/react-fullpage'
import { withContainer, connect } from '../services/container'
import Contacts from '../components/home/Contacts'
import Services from '../components/home/Services'
import HomePic from '../components/home/HomePic'
import HomeCarousel from '../components/home/HomeCarousel'
import ServiceCarousel from '../components/home/ServiceCarousel'
import Menu from '../components/home/Menu'
import { ReactComponent as PariaLogo } from '../assets/logo.svg'
import { LogoTop, LogoBottom } from '../components/home/LogoAnimation'


class Home extends Component {
    state = { big: null}

    componentDidMount() {       
        const { location, history } = this.props;   
      }
    
    render() {
        return (
            <div className={`${this.props.className}`}>
                <Menu big={this.state.big} fullpageApi={this.state._fullpageApi}></Menu>
                <LogoTop big={this.state.big}></LogoTop>
                <div className="mobile-logo">
                    <PariaLogo></PariaLogo>
                </div>
                <LogoTop big={this.state.big}></LogoTop>
                <LogoBottom big={this.state.big}></LogoBottom>
                <ReactFullpage
                    licenseKey=""
                    menu="#myMenu"
                    anchors={[
                        'home',
                        'filosofia',
                        'pariá',
                        'servizi',
                        'contatti',
                    ]}
                    loopHorizontal={false}
                    animateAnchor={false}
                	recordHistory={true}
                    scrollingSpeed={1000} /* Options here */
                    animateAnchor={false}
                    render={({ state, fullpageApi }) => {
                        return (
                            <ReactFullpage.Wrapper>
                                <HomePic
                                    goTo={(path) =>
                                        this.props.history.push(path)
                                    }
                                ></HomePic>
                                <HomeCarousel></HomeCarousel>
                                <ServiceCarousel></ServiceCarousel>
                                <Services
                                    goTo={(path) =>
                                        this.props.history.push(path)
                                    }
                                    ></Services>
                                <Contacts ></Contacts>
                            </ReactFullpage.Wrapper>
                        )
                    }}
                    onLeave={(origin, destination, direction) => {
                        this.setState({
                            big: destination.index === 0,
                        })
                    }}
                    afterLoad={(origin, destination, direction, ...rest) => {
                        this.setState({
                            big: destination.index === 0,
                        })
                    }}
                />
            </div>
        )
    }
}

const StyledHome = styled(Home)`
    .mobile-logo {
        display: none;
        position: fixed;
        top: 7px;
        width: 104px;
        z-index: 3;
        left: 10px;
        @media (max-width: 768px) {
            display: block;
        }
    }
`

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)
const mapStateToProps = ({ user }) => ({
    user,
})
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withContainer(StyledHome))
