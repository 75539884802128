import React, { useMemo, useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import styled from 'styled-components'
import {
    useStripe,
    useElements,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
} from '@stripe/react-stripe-js'
import { ReactComponent as StripeLogo } from '../assets/stripe.svg'

function useResponsiveFontSize() {
    const getFontSize = () => (window.innerWidth < 450 ? '16px' : '18px')
    const [fontSize, setFontSize] = useState(getFontSize)

    useEffect(() => {
        const onResize = () => {
            setFontSize(getFontSize())
        }

        window.addEventListener('resize', onResize)

        return () => {
            window.removeEventListener('resize', onResize)
        }
    })

    return fontSize
}

const useOptions = () => {
    const fontSize = useResponsiveFontSize()
    const options = useMemo(
        () => ({
            style: {
                base: {
                    fontSize,
                    color: '#424770',
                    letterSpacing: '0.025em',
                    fontFamily: 'Source Code Pro, monospace',
                    '::placeholder': {
                        color: '#aab7c4',
                    },
                },
                invalid: {
                    color: '#9e2146',
                },
            },
        }),
        [fontSize],
    )

    return options
}

// questo é usato per salvare carta mentre si paga
// const result = await stripe.confirmCardPayment(clientSecret, {
//     payment_method: {
//         card: elements.getElement(CardNumberElement),
//     },
//     setup_future_usage: 'off_session'
// })

const SplitForm = ({ className, attachPaymentMethod, closeModal, onError }) => {
    const stripe = useStripe()
    const elements = useElements()
    const options = useOptions()

    async function retry(fn, n) {
        for (let i = 0; i < n; i++) {
          try {
            return await fn();
          } catch {}
        }
      
        throw new Error(`Failed retrying ${n} times`);
      }

    const handleSubmit = async (event) => {
        event.preventDefault()

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return
        }

        for (let i = 0; i < 3; i++) {
            try {
                const {paymentMethod, error} = await stripe.createPaymentMethod({
                    type: 'card',
                    card: elements.getElement(CardNumberElement),
                })
                if (error) {
                    // Display error.message in your UI.
                    onError()
                    return true
                } else {
                    // You have successfully created a new PaymentMethod
                    // crea un metodo di pagamento senza usarlo
                    const res = await attachPaymentMethod({paymentMethodId: paymentMethod.id})
                    if(res?.errorMessage && i >= 2){
                        const msg = res.errorMessage === "An error occurred with our connection to Stripe." ? 'Abbiamo riscontrato un problema nella comunicazione verso i server Stripe. Prova di nuovo.' : res?.errorMessage
                        onError(msg)
                    }else {
                        closeModal()
                        return true
                    }
                }
                
            } catch {}
          }
    }

    return (
        <form onSubmit={handleSubmit} className={`${className}`}>
            <label>
                Numero della carta
                <CardNumberElement
                    options={options}
                    onReady={() => {
                    }}
                    onChange={(event) => {
                    }}
                    onBlur={() => {
                    }}
                    onFocus={() => {
                    }}
                />
            </label>
            <label>
                Data di scadenza
                <CardExpiryElement
                    options={options}
                    onReady={() => {
                    }}
                    onChange={(event) => {
                    }}
                    onBlur={() => {
                    }}
                    onFocus={() => {
                    }}
                />
            </label>
            <label>
                CVC
                <CardCvcElement
                    options={options}
                    onReady={() => {
                    }}
                    onChange={(event) => {
                    }}
                    onBlur={() => {
                    }}
                    onFocus={() => {
                    }}
                />
            </label>
            <button type="submit" disabled={!stripe}>
                Salva carta
            </button>
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://stripe.com">
                <StripeLogo className={`stripe`}></StripeLogo>
            </a>
        </form>
    )
}

const StyledSplitForm = styled(SplitForm)`
    width: 300px;
    margin: 0 auto;
    label {
        color: #6b7c93;
        font-weight: 300;
        letter-spacing: 0.025em;
    }

    button {
        white-space: nowrap;
        border: 0;
        outline: 0;
        display: inline-block;
        height: 40px;
        line-height: 40px;
        padding: 0 14px;
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11),
            0 1px 3px rgba(0, 0, 0, 0.08);
        color: #fff;
        border-radius: 4px;
        font-size: 15px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.025em;
        background-color: #6772e5;
        text-decoration: none;
        -webkit-transition: all 150ms ease;
        transition: all 150ms ease;
        margin-top: 10px;
    }

    button:hover {
        color: #fff;
        cursor: pointer;
        background-color: #7795f8;
        transform: translateY(-1px);
        box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1),
            0 3px 6px rgba(0, 0, 0, 0.08);
    }

    input,
    .StripeElement {
        display: block;
        margin: 10px 0 20px 0;
        max-width: 500px;
        padding: 10px 14px;
        font-size: 1em;
        box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
            rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
        border: 0;
        outline: 0;
        border-radius: 4px;
        background: white;
    }

    input::placeholder {
        color: #aab7c4;
    }

    input:focus,
    .StripeElement--focus {
        box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
            rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
        -webkit-transition: all 150ms ease;
        transition: all 150ms ease;
    }

    .StripeElement.IdealBankElement,
    .StripeElement.FpxBankElement,
    .StripeElement.PaymentRequestButton {
        padding: 0;
    }

    .StripeElement.PaymentRequestButton {
        height: 40px;
    }
    svg.stripe {
        width: 200px;
        float: right;
        margin-top: 22px;
        transform: scale(0.6);
        margin: 0 auto;
        transform-origin: right;
    }
`
export default StyledSplitForm
