// @flow
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import SwipeableTabs from 'react-swipeable-tabs'
import { selectCategory } from '../stores/actions'
import { withContainer, connect } from '../services/container'
import { theme } from '../theme'
import { pxToRem } from '../utils/common-style'
import { scrollOnCategorySelected } from './RestaurantPages'
import { device } from '../utils/common-style'
class RestaurantCategories extends Component {
    render() {
        let categories = this.props.selectedRestaurant.categories
        const hiddenCategories = this.props?.deliveryPoint?.hiddenCategories
        if(hiddenCategories && hiddenCategories.length > 0){
            categories = this.props.selectedRestaurant.categories.filter(x => !hiddenCategories.includes(x.item))
        }
        return (
            <div className={`restaurant-categories ${this.props.className}`}>
                {this.props.selectedRestaurant.selectedCategory ? (
                    <SwipeableTabs
                        noFirstLeftPadding={false}
                        noLastRightPadding={false}
                        fitItems={false}
                        alignCenter={false}
                        borderWidthRatio={1}
                        activeItemIndex={
                            this.props.selectedRestaurant.selectedCategory?.index
                        }
                        onItemClick={(item, index) => {
                            scrollOnCategorySelected()
                            this.props.selectCategory(index)
                        }}
                        items={categories.map(
                            (i) => (
                                <div>{i.displayName}</div>
                            ),
                        )}
                        borderPosition="bottom"
                        borderThickness={2}
                        borderColor={theme.pariabrown}
                        resistanceCoeffiecent={0.01}
                        stiffness={240}
                        safeMargin={16}
                        activeStyle={{
                            color: theme.pariabrown,
                            fontWeight: 900,
                        }}
                    />
                ) : (
                    ''
                )}
            </div>
        )
    }
}

const StyledRestaurantCategories = styled(RestaurantCategories)`
    height: 48px;
    width: 100vw;
    border-bottom: 1px solid #f0f0f0;
    background-color: white;
    @media ${device.laptopL} {
        z-index: 1;
        width: 60vw;
    }
    ul {
        height: 48px;
        li {
            height: 48px;
            padding: 0 20px !important;
            text-transform: uppercase;
            div {
                font-size: ${pxToRem(12)};
                line-height: 1.67;
                letter-spacing: 0.5px;
            }
        }
        & + div {
            margin-top: -1px;
        }
    }
`

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ selectCategory }, dispatch)
const mapStateToProps = ({ selectedRestaurant }) => ({
    selectedRestaurant,
})
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withContainer(StyledRestaurantCategories))
