// @flow
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { withContainer, connect } from '../services/container'
import styled from 'styled-components'
import Modal from 'react-responsive-modal'
import { addItemToCart } from '../stores/actions'
import { parseToUnit } from '../utils/numbers'
import { pxToRem } from '../utils/common-style'
import ProductDetails from '../components/product_details'
import { withRouter } from 'react-router-dom'
import { sortObjectsByOrder } from '../utils/text'

const emptyThumb = 'https://paria-web-store.s3-eu-west-1.amazonaws.com/gallery/menu/empty.png'
class CategoryPage extends Component {
    state = {
        productDetailsModal: false,
    }

    componentDidUpdate() {
        if(!this.state.productDetailsModal){
            const urlSearchParams = (new URLSearchParams(window.location.search))
            let productParam = urlSearchParams.get("product")
            let productParamArray = productParam?.split(':')
            const category = this.props.category.item
            if (productParam && category === productParamArray?.[0]) {
                this.openDetails(this.props.selectedRestaurant?.items?.[productParamArray?.[0]].find(x => x.SK === productParam))
            }
        }
    }

    openDetails(i) {
        this.setState({productDetailsModal: i})
        const queryParams = new URLSearchParams(window.location.search)
        queryParams.set('product', i.SK)
        this.props.history.replace({
          search: queryParams.toString(),
        })
    }

    onCloseModal() {
        const queryParams = new URLSearchParams(window.location.search)
        queryParams.delete('product')
        this.props.history.replace({
          search: queryParams.toString(),
        })
        this.setState({ productDetailsModal: false })
    }
    
    renderList() {
        const itemsOfCategory = this.props.cart.categories[
            this.props.category.item
        ]
        const itemsOfCategoryStringifyed = Object.keys(
            itemsOfCategory,
        ).toString()
        const isPresentInCart = (SK) => itemsOfCategoryStringifyed.includes(SK)
        const howManyTimes = (SK) => {
            const filtered = Object.keys(itemsOfCategory).filter((x) =>
                x.match(new RegExp(SK, 'g')),
            )
            const total = filtered.reduce(
                (acc, current) => acc + itemsOfCategory[current].count,
                0,
            )
            return total
        }

        return (
            <ul>
                {this.props.selectedRestaurant.items[this.props.category.item]
                    .filter((x) => !x.deliveryLocked)
                    .sort(sortObjectsByOrder)
                    .map((i) => (
                        <li key={i.SK} className={`${i.locked ? 'unavailable' : ''}`}>
                            <div>
                                <div className="cart" 
                                    onClick={() => this.openDetails(i)}>
                                    <img src={i.thumb || emptyThumb} alt={i.name}/>
                                    {isPresentInCart(i.SK) ? (
                                        <div className="numberCircle">
                                            <span>{howManyTimes(i.SK)}</span>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                <div
                                    onClick={() =>{
                                        if(!i.locked){
                                            this.props.addItemToCart(
                                                this.props.category.item,
                                                i,
                                            )}
                                        }
                                    }
                                    className={`item ${
                                        i.locked ? 'unavailable' : ''
                                    }`}>
                                    {i.locked && (
                                        <span className="unavailable-text">
                                            Momentaneamente non disponibile
                                        </span>
                                    )}
                                    <div className="name-price">
                                        <div className="name">
                                            {i.name}
                                        </div>
                                        <div className="price">
                                            {parseToUnit(i.price, 2, '€')}
                                        </div>
                                    </div>
                                    <div className="description">
                                        {i.description}
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}
            </ul>
        )
    }

    render() {
        if (Object.keys(this.props.cart.categories).length === 0) {
            return null
        }
        if (!this.props.selectedRestaurant.items[this.props.category.item]) {
            return null
        }
        if(!this.props.isSelected){
            return null
        }
        return (
            <div className={`${this.props.className}`}>
                <div className={`cat-page`}>
                    {this.renderList()}
                </div>
                <Modal
                    open={!!this.state.productDetailsModal}
                    onClose={() => this.onCloseModal()}
                    animationDuration={0}
                    >
                    <ProductDetails product={this.state.productDetailsModal}></ProductDetails>
                </Modal>
            </div>
        )
    }
}



const StyledCategoryPage = styled(CategoryPage)`
    ul {
        padding-top: 26px;
        margin-bottom: 60px;
        li {
            display: flex;
            &:not(.unavailable){
                cursor: pointer;
                &:hover {
                    background-color: #FAFAFA;
                }
            }
            > div {
                position: relative;
                display: flex !important;
                width: 100%;
                padding: 5px 0px;
                s {
                    top: 50% !important;
                    left: 50% !important;
                }
                .cart {
                    min-width: 80px;
                    min-height: 80px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    img {
                        width: 60px;
                        height: 60px;
                    }
                    .numberCircle {
                        border-radius: 50%;
                        font-size: ${pxToRem(12)};
                        width: 22px;
                        height: 22px;
                        background-color: ${(props) => props.theme.pariabrown};
                        color: ${(props) => props.theme.white};
                        margin: 0 auto;
                        border: 1px solid white;
                        position: absolute;
                        bottom: 5px;
                        left: 5px;
                    }
                    .numberCircle span {
                        text-align: center;
                        line-height: 22px;
                        display: block;
                    }
                }
                .item {
                    margin: 13px 0;
                    flex-grow: 1;
                    .unavailable-text {
                        position: absolute;
                        color: #b91111;
                        top: 30px;
                        transform: rotate(-6deg);
                        background-color: white;
                        padding: 2px;
                        border: 1px solid #b91111;
                        border-radius: 6px;
                        left: 14px;
                    }
                    .name-price {
                        display: flex;
                        margin-bottom: 5px;
                        justify-content: space-between;
                        .name {
                            font-size: ${pxToRem(16)};
                            color: ${(props) => props.theme.textDark};
                        }
                        .price {
                            margin-right: 10px;
                            margin-left: 25px;
                            font-size: ${pxToRem(14)};
                            font-family: RecoletaAlt, sans-serif;
                            font-weight: 500;
                            color: ${(props) => props.theme.textDark};
                            white-space: nowrap;
                        }
                    }
                    .description {
                        font-size: ${pxToRem(12)};
                        padding-right: 75px;
                    }
                    &.unavailable {
                        .name-price {
                            .name {
                                color: #bdbdbd;
                            }
                            .price {
                                color: #bdbdbd;
                            }
                        }
                        .description {
                            color: #bdbdbd;
                        }
                    }
                }
            }
        }
    }
    .product-details-modal {
        min-width: 300px;
    }
`

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ addItemToCart }, dispatch)
const mapStateToProps = ({ selectedRestaurant, cart }) => ({
    selectedRestaurant,
    cart,
})
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withContainer( withRouter(StyledCategoryPage) ))
