import { ACTION_CREATE_PAYMENT_INTENT } from './types'

const createPaymentIntent = ({ rest }, customerId, paymentMethodId, orderKey) => (dispatch, getState) => {
    const { total, extra, evaluatedCoupon } = getState().cart
    const { evaluation } = getState().selectedRestaurant

    const eventualExtra = (extra > 0 ? extra : 0)
    let discount = 0
    if(evaluatedCoupon?.discount){
        discount = evaluatedCoupon?.discount
    }
    const amount = Math.round((total + eventualExtra - discount + Number(evaluation?.cost)) * 100)
    const request = rest.post(`payment-intent`, {
        amount,
        customerId,
        paymentMethodId,
        orderKey
    })

    return dispatch({
        payload: request,
        type: ACTION_CREATE_PAYMENT_INTENT,
    })
}

export default createPaymentIntent