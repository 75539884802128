import React from 'react'
import styled from 'styled-components'
import SocialFollow from './SociaFollow'
import { pxToRem } from '../../utils/common-style'

class Contacts extends React.Component {
    render() {
        return (
            <div className={`section ${this.props.className}`}>
                <div className="wrapper-u8i">
                    <div className="address">
                        Piazza della Repubblica 16, L'Aquila (AQ) Italy
                    </div>
                    <a className="map-link" target="_blank" rel="noopener noreferrer"
                        href="https://www.google.com/maps/place/Pari%C3%A0+Pizzeria+Partenopea/@42.3476333,13.3948796,17z/data=!3m1!4b1!4m5!3m4!1s0x132fd3a7d64c9539:0xd997dc9baa78178c!8m2!3d42.3476333!4d13.3970683">
                        <img src="https://paria-web-store.s3-eu-west-1.amazonaws.com/site-assets/map-paria.jpg" alt="map" />
                    </a>
                    <div className="email">info@pariapizza.com</div>
                    <div className="phone"> <a href="tel:3347619116">+39 334 7619116</a> </div>
                    <div className="ragione">CIQUADRO - SOCIETA' A RESPONSABILITA' LIMITATA SEMPLIFICATA</div>
                    <SocialFollow />
                </div>
            </div>
        )
    }
}

const StyledContacts = styled(Contacts)`
    background-image: url("https://paria-web-store.s3-eu-west-1.amazonaws.com/site-assets/paria_pattern2.jpg");
    .wrapper-u8i {
        display: flex;
        flex-direction: column;
        a.map-link {
            text-align: center;
            img {
                height: 40vh;
                margin: 0 auto;
            }
        }
        &.section.fp-section.fp-table {
            background-color: #fae3d8;
        }
        .logo {
            height: 200px;
            width: 200px;
        }
        .address {
            text-align: center;
            font-family: RecoletaAlt;
            margin-top: 30px;
            margin-bottom: 10px;
            font-size: ${pxToRem(20)};
            padding: 0 30px;
        }

        .email {
            text-align: center;
            font-family: RecoletaAlt;
            margin-top: 50px;
            font-size: ${pxToRem(16)};
        }
        .phone {
            text-align: center;
            font-family: RecoletaAlt;
            margin-top: 15px;
            font-size: ${pxToRem(16)};
        }
        .ragione {
            text-align: center;
            font-family: RecoletaAlt;
            margin-top: 25px;
            font-size: ${pxToRem(8)};
            position: absolute;
            bottom: 0;
            width: 100%;
        }
    }
`

export default StyledContacts
