import { ACTION_FETCH_USER_ORDERS } from './types'
import createConfig from '../utils/headers'

const fetchUserOrders = ({ Auth, rest }) => async (dispatch, getState) => {
    const currentUser = await Auth.currentAuthenticatedUser()
    let config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: currentUser.signInUserSession.idToken.jwtToken,
        },
    }
    const request = rest.get(`user/orders`, config)
    dispatch({
        payload: request,
        type: ACTION_FETCH_USER_ORDERS,
    })
}

export default fetchUserOrders
