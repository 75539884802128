import React, { Component } from 'react'
import Checkbox from 'react-mdc-web/lib/Checkbox/Checkbox'
import Radio from 'react-mdc-web/lib/Radio/Radio'
import FormField from 'react-mdc-web/lib/FormField/FormField'
import RadioGroup from 'react-mdc-web/lib/Radio/RadioGroup'
import styled from 'styled-components'
import { parseToUnit } from '../utils/numbers'
import { maybePluralize } from '../utils/text'
import { pxToRem } from '../utils/common-style'

class ProductRule extends Component {
    constructor(props) {
        super(props)
        this.state = {}
        this.state[props.name] = null
        const selected = Object.values(props.options).find((x) => x.checked)
        if (selected) {
            this.state[props.name] = selected.name
        }
    }

    selected() {
        let count = 0
        Object.keys(this.props.options).forEach((optName) => {
            if (this.props.options[optName].checked) {
                count++
            }
        })
        return count
    }

    confirmable() {
        if (this.radioMode()) {
            return this.state[this.props.name] !== null
        }
        return !this.props.min || this.selected() >= this.props.min
    }

    printRule() {
        const { min, max } = this.props
        const hasRules = max || min
        if (!hasRules) return ''
        let rule = ''
        if (!!max && !min) {
            rule = `Al massimo ${maybePluralize(max, 'opzione', 'opzioni')}`
        }
        if (!max && !!min) {
            rule = `Almeno ${maybePluralize(max, 'opzione', 'opzioni')}`
        }
        if (!!max && !!min) {
            rule = `${maybePluralize(max, 'opzione', 'opzioni')}`
        }

        // let rule = '(';
        // rule = rule.concat(this.props.min ? `Min ${this.props.min}` : '');
        // rule = rule.concat(hasMinMax ? ' - ' : '');
        // rule = rule.concat(this.props.max ? `Max ${this.props.max}` : '');
        // rule = rule.concat(')');
        return rule
    }

    radioMode() {
        const { min, max } = this.props
        return min === max && min === 1
    }

    renderCheckbox() {
        return Object.keys(this.props.options).map((option, i) => {
            const opt = this.props.options[option]
            const isDisabled = !opt.checked && this.selected() >= this.props.max
            return (
                <FormField
                    id={`${this.props.name}-checkbox${i}`}
                    key={`key_${i}`}>
                    <Checkbox
                        onChange={({ target }) => {
                            this.props.onSelection({
                                opt,
                                propName: this.props.name,
                            })
                            setTimeout(() => {
                                target.blur()
                            }, 200)
                        }}
                        disabled={isDisabled}
                        checked={opt.checked}
                    />
                    <label className={isDisabled ? 'disabled' : ''}>
                        {opt.name} <span>{parseToUnit(opt.price, 2, '€')}</span>
                    </label>
                </FormField>
            )
        })
    }

    renderRadio() {
        const options = Object.keys(this.props.options)
        return (
            <RadioGroup
                onChange={({ target }) => {
                    this.setState({ [this.props.name]: target.value })
                    this.props.onSelection({
                        value: target.value,
                        propName: this.props.name,
                    })
                    setTimeout(() => {
                        target.blur()
                    }, 200)
                }}
                name={this.props.name}
                value={this.state[this.props.name]}>
                {options.map((option, i) => (
                    <Radio value={option} key={option}>
                        {option}{' '}
                        <span>
                            {parseToUnit(
                                this.props.options[option].price,
                                2,
                                '€',
                            )}
                        </span>
                    </Radio>
                ))}
            </RadioGroup>
        )
    }

    render() {
        return (
            <div className={`${this.props.className}`}>
                <div className="line rule">
                    <h2>
                        {this.props.name} <div>{this.printRule()}</div>
                    </h2>
                </div>
                {this.radioMode() ? this.renderRadio() : this.renderCheckbox()}
            </div>
        )
    }
}

const StyledProductRule = styled(ProductRule)`
    padding-bottom: 30px;
    border-bottom: 1px solid #dadada;
    .line.rule {
        font-size: ${pxToRem(18)};
        color: ${(props) => props.theme.textLight3};
        div {
            font-size: ${pxToRem(12)};
        }
    }
    ul.mdc-list {
        padding: 0;
    }
    .mdc-form-field {
        width: 100%;
        padding: 4px 6px;
        box-sizing: border-box;
        font-size: ${pxToRem(14)};
        label {
            width: 100%;
            span {
                float: right;
                padding-right: 16px;
            }
            &.disabled {
                opacity: 0.6;
            }
        }

        .mdc-radio__native-control:checked
            + .mdc-radio__background
            .mdc-radio__inner-circle {
            background-color: ${(props) => props.theme.pariabrown};
        }
        .mdc-radio__native-control:checked
            + .mdc-radio__background
            .mdc-radio__outer-circle {
            border-color: ${(props) => props.theme.darkorange};
        }
        .mdc-checkbox__native-control:checked ~ .mdc-checkbox__background {
            background-color: ${(props) => props.theme.pariabrown};
            border-color: ${(props) => props.theme.darkorange};
        }
        .mdc-radio__background:before,
        .mdc-checkbox__background:before {
            background: ${(props) => props.theme.darkorange};
        }
    }
`

export default StyledProductRule
