import React from 'react'
import styled from 'styled-components'
import Ripples from 'react-ripples'
import { parseToUnit } from '../utils/numbers'
import { pxToRem, device } from '../utils/common-style'

const CartPreview = (props) => (
    <div className={`${props.className}`}>
        <Ripples onClick={() => setTimeout(props.onClick, 700)}>
            <div className="total">{parseToUnit(props.total, 2, '€')}</div>
            <div className="message">Vedi il carrello</div>
            <i className="material-icons">shopping_cart</i>
        </Ripples>
    </div>
)

const StyledCartPreview = styled(CartPreview)`
    position: fixed;
    bottom: ${(props) => (props.items > 0 ? '0px' : '-100px')};
    height: 54px;
    width: calc(100vw - 34px);
    margin: 11px 0px;
    background-color: ${(props) => props.theme.green};
    color: ${(props) => props.theme.white};
    border-radius: 2.5px;
    opacity: ${(props) => (props.items > 0 ? '1' : '0')};
    transition: 0.3s ease;
    @media ${device.laptopL} {
        bottom: ${(props) => (props.items > 0 ? '0px' : '-100px')};
        width: calc(60vw - 34px);
    }
    > div {
        display: flex !important;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        padding: 0 16px;
        height: 100%;
        s {
            top: 50% !important;
            left: 50% !important;
        }
        > div {
            font-size: ${pxToRem(16)};
            font-weight: 900;
            &.total {
            }
            &.message {
                position: absolute;
                width: 100%;
            }
        }
    }
`

export default StyledCartPreview
