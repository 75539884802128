import {
    ACTION_CATEGORY_SELECTED,
    ACTION_GET_MENU,
    ACTION_FETCH_BASE_OPTIONS,
} from '../actions/types'

const INITIAL_STATE = {
    categories: [],
    selectedCategory: null,
    items: {},
    baseOptions: [],
}

export default (state = INITIAL_STATE, action) => {
    const { type, payload } = action
    switch (type) {
        case `${ACTION_GET_MENU}_FULFILLED`: {
            return {
                ...state,
                categories: payload.data.categories
                    .sort((a, b) => a.order - b.order)
                    .map((i) => ({
                        item: i.SK,
                        displayName: i.name,
                        loaded: true,
                    })),
                items: payload.data.categories.reduce((acc, curr) => {
                    acc[curr.SK] = curr.list
                    return acc
                }, {}),
            }
        }
        case ACTION_CATEGORY_SELECTED:
            if (state.categories.length === 0) {
                return {
                    ...state,
                }
            }
            return {
                ...state,
                selectedCategory: {
                    index: payload.index,
                    item: state.categories[payload.index].item,
                },
            }
        case `${ACTION_FETCH_BASE_OPTIONS}_FULFILLED`:
            return {
                ...state,
                baseOptions: payload.data
                    .filter((x) => !x.locked)
                    .map(({ name, price }) => ({
                        name,
                        price,
                    })),
            }
        default:
            return state
    }
}
