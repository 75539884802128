// @flow
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { withContainer, connect } from '../services/container'
import styled from 'styled-components'
import { getMenu, resetAfterPayment, mailchimpEvent } from '../stores/actions'
import Confetti from '../components/confetti'
import { motion, useAnimation } from 'framer-motion'
import BackNavbar from './BackNavbar'
import { pxToRem, pageContainer, contextButton } from '../utils/common-style'

const Message = (props) => {
    const controls = useAnimation()
    controls.start({
        opacity: props.confettiActive ? 1 : 0,
        transition: { duration: 1.2 },
    })
    return (
        <motion.div animate={controls} className={props.className}>
            {props.confettiActive && props.text}
        </motion.div>
    )
}

class ThankYouPage extends Component {
    state = {
        confettiActive: false,
    }

    componentDidMount() {
        this.props.resetAfterPayment()
        setTimeout(() => {
            this.setState({ confettiActive: true })
            this.props.mailchimpEvent()
        }, 700)
    }

    render() {
        const confettiConfig = {
            angle: 90,
            spread: 104,
            startVelocity: 21,
            elementCount: 251,
            decay: 0.94,
            dalay: 1500,
        }

        return (
            <div className={this.props.className}>
                <BackNavbar hideUser={true} />
                <div className="wrapper">
                    <Confetti
                        active={this.state.confettiActive}
                        config={confettiConfig}
                    />
                    <div className="img-order" />
                    <Message
                        delay={0}
                        confettiActive={this.state.confettiActive}
                        text="Ordine Completato!"
                        className={'completed-message'}></Message>
                    <Message
                        delay={0}
                        confettiActive={this.state.confettiActive}
                        text="Il tuo ordine é in arrivo! Grazie per averci scelto"
                        className={'share-message'}></Message>

                    <button
                        className="back-home-btn"
                        onClick={() => {
                            this.props.history.replace('/store')
                        }}>
                        Torna alla Home
                    </button>
                </div>
            </div>
        )
    }
}

const StyledTankYouPage = styled(ThankYouPage)`
    ${pageContainer};
    .wrapper {
        ${
            '' /* background-image: linear-gradient(to bottom, #75c856, #a8e154); */
        }
        display: flex;
        align-items: center;
        flex-direction: column;
        .img-order {
            height: 18vh;
            margin: 8vh;
            align-items: center;
            text-align: center;
            display: flex;
            width: 233px;
            ${'' /* background-image: url('./shopper.svg'); */}
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }
        .completed-message {
            height: 13vh;
            align-items: center;
            display: flex;
            font-size: 36px;
            font-weight: 900;
            line-height: 1.11;
            color: ${(props) => props.theme.textDark};
            width: 233px;
            text-align: center;
        }
        .share-message {
            height: 18vh;
            align-items: center;
            display: flex;
            width: 233px;
            font-size: ${pxToRem(20)};
            text-align: center;
            color: ${(props) => props.theme.textDark};
        }

        .back-home-btn {
            ${contextButton};
            margin-top: 30px;
        }
    }
`

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getMenu,
            resetAfterPayment,
            mailchimpEvent,
        },
        dispatch,
    )
const mapStateToProps = ({ selectedRestaurant, cart }) => ({
    selectedRestaurant,
    cart,
})
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withContainer(StyledTankYouPage))
