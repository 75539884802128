import React from 'react'
import styled from 'styled-components'
import { pxToRem } from '../../utils/common-style'

class HomePic extends React.Component {
    render() {
        return (
            <div className={`section ${this.props.className}`}>
                <div className="slide ">
                    <div className="slide-content">
                        <div className="bg third"></div>
                    </div>
                </div>
                <div className="slide ">
                    <div className="slide-content">
                        <div className="bg fourth"></div>
                    </div>
                </div>
                <div className="slide ">
                    <div className="slide-content">
                        <div className="bg fifth"></div>
                    </div>
                </div>
            </div>
        )
    }
}

const StyledHomePic = styled(HomePic)`
    .slide {
        text-align: center;
        font-family: RecoletaAlt;
        font-size: ${pxToRem(16)};
        .slide-content {
            height: 100%;
            .text-content {
                height: 40%;
                padding-top: 200px;
                box-sizing: border-box;
                color: red;
                font-size: 36px;
            }
            .bg {
                height: 100%;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;

                &.first {
                    background-image: url('https://paria-web-store.s3-eu-west-1.amazonaws.com/site-assets/gallery/impasto.jpg');
                }
                &.second {
                    background-image: url('https://paria-web-store.s3-eu-west-1.amazonaws.com/site-assets/gallery/ingredients.jpg');
                }
                &.third {
                    background-image: url('https://paria-web-store.s3-eu-west-1.amazonaws.com/site-assets/gallery/wall.jpg');
                }
                &.fourth {
                    background-image: url('https://paria-web-store.s3-eu-west-1.amazonaws.com/site-assets/gallery/beers.jpg');
                    background-position: bottom;
                }
                &.fifth {
                    background-image: url('https://paria-web-store.s3-eu-west-1.amazonaws.com/site-assets/gallery/delivery.jpg');
                }
            }
        }
    }
    .fp-controlArrow {
        border: solid #282727;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 9px;
        &.fp-next {
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
        }
        &.fp-prev {
            transform: rotate(135deg);
            -webkit-transform: rotate(135deg);
        }
    }
`

export default StyledHomePic
