import { ACTION_USER_LOGIN } from './types'

const login = ({ Auth }) => async (dispatch, getState) => {
    const request = Auth.federatedSignIn()
    return dispatch({
        payload: request,
        type: ACTION_USER_LOGIN,
    })
}

export default login
