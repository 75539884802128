import { ACTION_SOMETHING_WENT_WRONG } from './types';

const somethingWentWrong = isWrong => (dispatch) => {
  dispatch({
    payload: { isWrong },
    type: ACTION_SOMETHING_WENT_WRONG,
  });
};

export default somethingWentWrong;
