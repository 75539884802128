import React, { Component } from 'react'
import FlipMove from 'react-flip-move'
import styled from 'styled-components'
import { listCard, cardAction, pxToRem, truncate } from '../utils/common-style'

// eslint-disable-next-line no-extend-native
Array.prototype.move = function (from, to) {
    this.splice(to, 0, this.splice(from, 1)[0])
}

class AddressList extends Component {
    constructor(props) {
        super(props)
        const addressList = this.props.addressList.slice()
        this.state = {
            addressList,
        }
    }

    componentWillMount(nextProps) {
        this.prepareList(nextProps)
        const addressList = this.state.addressList.slice()
        if (addressList.length > 0) {
            this.props.onSelectAddress(addressList[0])
        }
    }

    componentWillUpdate(nextProps) {
        this.prepareList(nextProps)
    }

    componentDidUpdate(prevProps) {
        const prevFirst = prevProps?.addressList?.[0]
        if((!prevFirst || prevFirst.SK) && !this.props.addressList?.[0]?.SK){
            this.props.addressList?.[0] && this.props.onSelectAddress(this.props.addressList?.[0])
        }
    }

    prepareList(nextProps) {
        const prepProps = nextProps || this.props
        if (prepProps.type === 'delivery') {
            const addressList = prepProps.addressList.slice()
            const newAddressAdded =
                addressList.length !== this.state.addressList.length
            const itemChecksum = (item) =>
                item.lat + item.lng + item.intercome + item.note
            let editedItemIndex
            const editedItem = !prepProps.addressList.every(
                (element, index) => {
                    const foo = this.state.addressList.find(
                        (x) => itemChecksum(x) === itemChecksum(element),
                    )
                    if (!foo) {
                        editedItemIndex = index
                    }
                    return foo
                },
            )
            // if editedItem, it must be first
            // if (editedItem) {
            //     addressList.move(editedItemIndex + 1, 0)
            // }
            if (newAddressAdded || editedItem) {
                this.setState({
                    addressList,
                })
            }
        } else if (prepProps.type === 'invoice') {
            const newAddressAdded =
                prepProps.addressList.length !== this.state.addressList.length

            const itemChecksum = (item) => item.companyName + item.companyVat
            let editedItemIndex = null
            const editedItem = !prepProps.addressList.every(
                (element, index) => {
                    const foo = this.state.addressList.find(
                        (x) => itemChecksum(x) === itemChecksum(element),
                    )
                    if (!foo) {
                        editedItemIndex = index
                    }
                    return foo
                },
            )
            // if editedItem, it must be first
            if (editedItem) {
                prepProps.addressList.move(editedItemIndex, 0)
            }
            if (newAddressAdded || editedItem) {
                this.setState({
                    addressList: prepProps.addressList,
                })
            }
        }
    }

    reorder(selectedIndex) {
        const addressList = this.state.addressList.slice()
        addressList.move(selectedIndex, 0)
        this.setState({ addressList })
        this.props.onSelectAddress(addressList[0])
    }

    render() {
        const prettyAddress = ({ street, number, zipcode, city }) =>
            `${street}, ${number} - ${zipcode} ${city}`
        return (
            <div className={`${this.props.className}`}>
                <FlipMove easing="ease-in">
                    {this.state.addressList.map((location, i) => (
                        <div
                            className={`address-card ${i === 0 ? 'first' : ''}`}
                            key={`${location.SK}`}
                            onClick={() => {
                                this.reorder(i)
                            }}>
                            <div className="intercome">
                                {location.intercome || location.companyName}
                            </div>
                            <div className="address">
                                {prettyAddress(location)}
                            </div>
                        </div>
                    ))}
                </FlipMove>
                {this.state.addressList.length === 0 && (
                    <div className="empty-address-list">Non hai indirizzi</div>
                )}
            </div>
        )
    }
}

const StyledAddressList = styled(AddressList)`
    .empty-address-list {
        height: 84px;
    }
    .address-card {
        ${listCard};
        height: 57px;
        padding: 10px;
        position: relative;
        box-sizing: border-box;
        opacity: 0.4;
        background-color: #cccccc;
        width: 95%;
        max-width: 800px;
        margin: 7px auto;
        .intercome {
            color: ${(props) => props.theme.textDark};
            font-weight: bold;
            font-size: ${pxToRem(14)};
            display: none;
        }
        .address {
            font-size: ${pxToRem(13)};
            margin-top: 4px;
            ${truncate};
        }
        .action {
            ${cardAction};
            visibility: hidden;
        }
        &.first {
            opacity: 1;
            z-index: 2;
            height: 84px;
            padding: 17px 40px 32px 40px;
            background-color: #fff;
            .intercome {
                display: block;
            }
            .action {
                visibility: visible;
            }
            .check-bullet {
                visibility: visible;
            }
        }
        &.invalid {
            .check-bullet {
                &::after {
                    background-color: ${(props) => props.theme.red};
                }
            }
        }
    }
`

export default StyledAddressList
