import { ACTION_GET_STORE } from './types'

const getStore = ({ rest }) => (dispatch, getState) => {
    const request = rest.get(`store`)

    return dispatch({
        payload: request,
        type: ACTION_GET_STORE,
    })
}

export default getStore
