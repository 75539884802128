import React from 'react'
import AddressList from '../components/address_list'

const checkoutFirstStep = ({
    appStatePersistent,
    selectDeliveryAddress,
    onAddNewAddress,
    deliveryList,
    isDelivery,
    deliveryPoint
}) => {
    const renderAddressList = () => {
        const list = deliveryPoint ? [deliveryPoint.address] : deliveryList
        return <div className="step step-1">
            <AddressList
                type="delivery"
                addressList={list}
                addressDetails={appStatePersistent.addressDetails}
                onSelectAddress={(x) => selectDeliveryAddress(x)}
                onEditListItem={(location) => {
                    console.log(location)
                }}
            />

            {!deliveryPoint && <div className="title-action" onClick={onAddNewAddress}>
                Aggiungi Nuovo
            </div>}
        </div>
    }

    const renderTakeAwayInstructions = () => (
        <div className="step step-1">
            <div className="takeaway-text">
                Potrai effetuare il ritiro presso la nostra sede in Piazza della
                Repubblica 16. <br />
                Nei prossimi step avrai la possibilitá di selezionare un orario
                di ritiro ed una modalitá di pagamento.
            </div>
        </div>
    )

    return isDelivery ? renderAddressList() : renderTakeAwayInstructions()
}

export default checkoutFirstStep
