import React from 'react'
import styled from 'styled-components'

import ResponsiveMenu from 'react-responsive-navbar'
import { motion, useAnimation } from 'framer-motion'
import { size, device } from '../../utils/common-style'

const StyledButton = styled.div`
    position: fixed;
    top: 5px;
    z-index: 2;
    right: 5px;
    i.material-icons {
        font-size: 38px;
    }
`

const Menu = ({ big }) => {
    let ref 
    return (
        <ResponsiveMenu
            ref={menu => ref = menu}
            menuOpenButton={
                <StyledButton>
                    <i className="material-icons">menu</i>
                </StyledButton>
            }
            menuCloseButton={
                <StyledButton>
                    <i className="material-icons">close</i>
                </StyledButton>
            }
            changeMenuOn={size.tablet}
            menu={<StyledMenuComponent big={big} onClickItem={() => ref.handleClick()}></StyledMenuComponent>}
        />
    )
}

const MenuComponent = ({ className, big, onClickItem }) => {
    const controls = useAnimation()
    controls.start({
        paddingTop: big ? '30px' : '100px',
        transition: { duration: 0.7 },
    })
    const moveAndClose = (destination) => () => {
        window.fullpage_api.moveTo(destination);
        onClickItem()
    }
    return (
        <motion.div animate={controls} className={`${className}`}>
            <ul id="myMenu">
                <li data-menuanchor="filosofia">
                    <a onClick={moveAndClose('filosofia')}>Filosofia</a>
                </li>
                <li data-menuanchor="pariá">
                    <a onClick={moveAndClose('pariá')}>Galleria</a>
                </li>
                <li data-menuanchor="servizi">
                    <a onClick={moveAndClose('servizi')}>Servizi</a>
                </li>
                <li data-menuanchor="contatti">
                    <a onClick={moveAndClose('contatti')}>Contatti</a>
                </li>
            </ul>
        </motion.div>
    )
}

const StyledMenuComponent = styled(MenuComponent)`
    position: fixed;
    z-index: 1;
    width: 100%;
    font-weight: 700;
    background-color: #f9e3d8e0;
    padding-bottom: 10px;
    @media ${device.tablet} {
        background-color: unset;
    }
    ul {
        padding: 0;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    li {
        display: inline;
        font-size: 13px;
        list-style-type: none;
        margin-right: 10px;
        margin-left: 10px;
        &.active {
            a {
                border-bottom: 2px solid #38241b;
                padding-bottom: 8px;
                opacity: 1;
            }
        }
    }
    a {
        text-decoration: none;
        text-transform: uppercase;
        font-size: 18px;
        font-family: WorkSans;
        letter-spacing: 3px;
        margin: 0 20px;
        color: #38241b;
        cursor: pointer;
        opacity: 0.5;
        &:hover {
            border-bottom: 2px solid #38241b;
            padding-bottom: 8px;
            opacity: 1;
        }
    }
    @media (max-width: 768px) {
        ul {
            flex-direction: column;
            height: unset;
            li {
                display: block;
                height: 20px;
                margin-top: 13px;
                margin-bottom: 8px;
            }
        }
    }
`

export default Menu
