import React from 'react'
import styled from 'styled-components'
import { device } from '../utils/common-style'

const Alert = (props) => (
    <div className={props.className}>
        <div className="message">
            {props.children ? props.children : props.message}
        </div>
        {/* <button onClick={props.action}>{props.actionName}</button>
        {props.onDismiss && (
            <button className="dismiss" onClick={() => props.onDismiss()}>
                Annulla
            </button>
        )} */}
    </div>
)

const StyledAlert = styled(Alert)`
    left: 0;
    margin: 11px;
    background-color: ${(props) => props.theme.red};
    color: ${(props) => props.theme.white};
    width: calc(100vw - 54px);
    margin: 11px;
    padding: 18px 16px;
    z-index: 1;
    border-radius: 3px;
    @media ${device.laptopL} {
        width: 50vw;
    }
    button {
        font-weight: bold;
        color: white;
        background-color: #ff2949;
        float: right;
        margin-top: 10px;
        &.dismiss {
            margin-right: 40px;
        }
    }
`

export default StyledAlert
