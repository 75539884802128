// @flow
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import {
    changeQuantityItemCart,
    setStateItemForOptionMode,
    setItemForOptionMode,
    replaceItemToCart,
    setDeliveryPointsMode,
} from '../stores/actions'
import ProductOptions from '../components/product_options'
import { withContainer, connect } from '../services/container'
import { urls } from '../config'
import ConfirmButton from '../components/confirm_button'
import { parseToUnit } from '../utils/numbers'
import { compare } from '../utils/text'
import {
    listCard,
    cardAction,
    pxToRem,
    pageContainer,
} from '../utils/common-style'
import InfoCart from '../components/info_cart'
import ItemQuantity from '../components/item_quantity'
import BackNavbar from './BackNavbar'
import { device } from '../utils/common-style'
import { getUrlWithDeliveryPointParam } from '../utils/urlParams'

export const renderOptions = (item) => {
    const selectedOptions = []
    item.options
        .split(',')
        .map((optionName) =>
            selectedOptions.push(
                item.rules
                    .reduce((acc, rule) => acc.concat(rule.options), [])
                    .find((option) => option.name === optionName),
            ),
        )
    return selectedOptions.reduce(
        (acc, item) =>
            item
                ? `${acc.concat(
                      `+ ${item.name} ${parseToUnit(item.price, 2, '€')}`,
                  )} `
                : '',
        '',
    )
}

class Cart extends Component {
    constructor(props) {
        super(props)
        this.state = {
            minimumOrder: false,
        }
    }

    componentDidMount() {
        let deliveryPointParam = new URLSearchParams(
            window.location.search
        ).get('deliveryPoint')
        const deliveryPoint = this.props.appStatePersistent.deliveryPoints.find(
            (x) => x.slug === deliveryPointParam,
        )
        this.props.setDeliveryPointsMode(!!deliveryPoint)
    }

    goToDeliveryMode = () => {
        this.props.history.push(getUrlWithDeliveryPointParam(urls.DELIVERYMODE))
    }

    render() {
        const items = []
        Object.keys(this.props.cart.categories).forEach((key) => {
            const cat = this.props.cart.categories[key]
            Object.keys(cat).map((i) => items.push({ ...cat[i], cartId: i }))
        })
        items.sort((a, b) => compare(a, b, 'categoryName'))
        return (
            <div className={`${this.props.className}`}>
                <BackNavbar
                    title={this.props.selectedRestaurant.partnerName}
                    showBack
                    onBackClicked={() => {
                        this.props.history.push(
                            getUrlWithDeliveryPointParam(urls.STORE),
                        )
                    }}
                />
                <div className="content">
                    <div>
                        {items.map((item, i) => (
                            <div className="cart-item" key={i}>
                                <div className="left">
                                    <div className="title">{item.name}</div>
                                    <div className="price">
                                        <span>
                                            {parseToUnit(item.price, 2, '€')}
                                        </span>
                                    </div>
                                    <div className="options">
                                        {renderOptions(item)}
                                    </div>
                                </div>
                                <div className="right">
                                    <ItemQuantity
                                        count={item.count}
                                        more={() => {
                                            this.props.changeQuantityItemCart({
                                                add: true,
                                                catName: item.categoryName,
                                                idCart: item.cartId,
                                            })
                                        }}
                                        less={() => {
                                            this.props.changeQuantityItemCart({
                                                add: false,
                                                catName: item.categoryName,
                                                idCart: item.cartId,
                                            })
                                        }}
                                    />

                                    {item.options ? (
                                        <div
                                            className="change-options"
                                            onClick={() => {
                                                this.props.setItemForOptionMode(
                                                    item,
                                                )
                                                return null
                                            }}>
                                            Cambia
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <InfoCart
                    className="info-cart"
                    total={this.props.cart.total}
                    hasDiscount={!!this.props.cart.evaluatedCoupon?.discount}
                    shipmentCost={this.props.selectedRestaurant.evaluation.cost}
                    discount={this.props.cart.evaluatedCoupon?.discount}
                    extra={this.props.cart.extra}
                    minimum={this.props.cart.minimum}
                    deltaMinimumBanner
                />

                <ConfirmButton
                    className={`confirm ${items.length > 0 ? '' : 'disabled'}`}
                    message={'Conferma i prodotti scelti'}
                    onClick={this.goToDeliveryMode}
                />
                <ProductOptions
                    item={this.props.cart.optionItem}
                    onConfirmButtonClicked={this.props.replaceItemToCart}
                    onCloseModal={this.props.setStateItemForOptionMode}
                    baseOptions={this.props.menu.baseOptions}
                />
            </div>
        )
    }
}

const StyledCart = styled(Cart)`
    ${pageContainer};
    .content {
        padding-bottom: 200px;
    }
    .cart-item {
        ${listCard};
        display: flex;
        justify-content: space-between;
        .left {
            padding-left: 29px;
            padding-top: 14px;
            .title {
                font-size: ${pxToRem(16)};
                text-align: left;
                color: ${(props) => props.theme.textDark};
            }
            .price {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 70px;
                height: 21px;
                border-radius: 10.5px;
                background-color: #948475;
                margin: 7px 0px;
                span {
                    color: ${(props) => props.theme.white};
                    font-size: ${pxToRem(12)};
                    font-weight: 900;
                    text-align: center;
                }
            }
            .options {
                font-size: ${pxToRem(12)};
                text-align: left;
                color: ${(props) => props.theme.textLight};
                margin-bottom: 14px;
            }
        }
        .right {
            padding-right: 19px;
            padding-top: 23px;
            width: 90px;
            min-width: 90px;
            min-height: 71px;
            position: relative;
            .change-options {
                ${cardAction};
            }
        }
    }
    .info-cart {
        border-top: 1px solid #deddde;
        position: fixed;
        padding: 10px 40px;
    }
    .login-modal {
        header {
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
        }
        .purple-message {
            height: 206px;
            width: 100%;
            background-color: ${(props) => props.theme.moovendapurple};
            position: absolute;
            top: 56px;
            z-index: -1;
            color: ${(props) => props.theme.white};
            padding: 24px 38px;
            font-size: 16px;
            box-sizing: border-box;
        }
        .login-form {
            margin: 107px 12px 0 12px;
            position: relative;
        }
    }
    .phone-modal {
        header {
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
        }
        .purple-message {
            height: 206px;
            width: 100%;
            background-color: ${(props) => props.theme.moovendapurple};
            position: absolute;
            top: 56px;
            z-index: 0;
            color: ${(props) => props.theme.white};
            padding: 24px 38px;
            font-size: 16px;
            box-sizing: border-box;
        }
        .phone-verification-cart {
            color: red;
            .sms-request {
                margin-top: 80px;
            }
        }
    }
    .confirm {
        position: fixed;
        bottom: 0px;
        height: 54px;
        width: calc(100vw - 34px);
        padding: 11px 0px;
        background-color: white;
    }
    @media ${device.laptopL} {
        .confirm {
            bottom: 0px;
            width: calc(60vw - 34px);
        }
    }
`

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            changeQuantityItemCart,
            setStateItemForOptionMode,
            setItemForOptionMode,
            replaceItemToCart,
            setDeliveryPointsMode,
        },
        dispatch,
    )
const mapStateToProps = ({
    selectedRestaurant,
    cart,
    user,
    appStatePersistent,
    menu,
}) => ({
    selectedRestaurant,
    cart,
    user,
    appStatePersistent,
    menu,
})
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withContainer(StyledCart))
