import {
    ACTION_SET_SPLASH_DONE,
    ACTION_TAB_ADDRESS_SELECTED,
    ACTION_SET_HOME_SCROLL,
    ACTION_APP_VERSION_UNSUPPORTED,
    ACTION_FETCH_APP_VERSION,
    ACTION_SHOW_ALERT_CART_FLUSH,
    ACTION_PROCEED_WITH_FLUSH_CART,
} from '../actions/types'

const getRandomInt = (min, max) =>
    Math.floor(Math.random() * (max - min + 1)) + min

const INITIAL_STATE = {
    splashDone: false,
    addressTabIndex: 0,
    unsupported: false,
    partnerCartAlert: false,
    proceedWIthFlush: false,
    privacy: null,
    terms: null,
    faq: null,
    locationDeclined: false,
    sessionId: getRandomInt(1, 1000000000000),
}
/**
 * @param {Object} state - Default aplication state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action) => {
    const { type, payload } = action
    switch (type) {
        case ACTION_SET_SPLASH_DONE: {
            return {
                ...state,
                splashDone: true,
            }
        }
        case ACTION_TAB_ADDRESS_SELECTED:
            return {
                ...state,
                addressTabIndex: payload,
            }
        case ACTION_SET_HOME_SCROLL: {
            return {
                ...state,
                homeScroll: payload.scroll,
            }
        }
        case ACTION_APP_VERSION_UNSUPPORTED: {
            return {
                ...state,
                unsupported: true,
            }
        }
        case `${ACTION_FETCH_APP_VERSION}_FULFILLED`: {
            return {
                ...state,
                downloadLink: payload.data.app.android.link,
            }
        }
        case ACTION_SHOW_ALERT_CART_FLUSH: {
            return {
                ...state,
                partnerCartAlert: payload.visible,
            }
        }
        case ACTION_PROCEED_WITH_FLUSH_CART: {
            return {
                ...state,
                proceedWIthFlush: payload.proceed,
                partnerCartAlert: false,
            }
        }
        case 'ACTION_FETCH_DISCLAIMER_TERMS_FULFILLED': {
            return {
                ...state,
                terms: payload.data.disclaimer,
            }
        }
        case 'ACTION_FETCH_DISCLAIMER_PRIVACY_FULFILLED': {
            return {
                ...state,
                privacy: payload.data.disclaimer,
            }
        }
        case 'ACTION_FETCH_DISCLAIMER_FAQ_FULFILLED': {
            return {
                ...state,
                faq: payload.data.disclaimer,
            }
        }
        case 'ACTION_LOCATION_DECLINED': {
            return {
                ...state,
                locationDeclined: true,
            }
        }
        case 'ACTION_LOCATION_DECLINED_RESET': {
            return {
                ...state,
                locationDeclined: false,
            }
        }
        default:
            return state
    }
}
