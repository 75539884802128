import {
    ACTION_SET_CHECKOUT_STEP,
    ACTION_PAYMENT_METHOD_SELECTED,
    ACTION_FINALISE_PAYMENT,
    ACTION_CREATE_ORDER,
    ACTION_DELIVERY_ADDRESS_SELECTED,
    ACTION_BILLING_ADDRESS_SELECTED,
    ACTION_RESET_AFTER_PAYMENT,
    ACTION_SOMETHING_WENT_WRONG,
    ACTION_GET_CHECKOUT_INFO,
    ACTION_SET_PHONE_NUMBER
} from '../actions/types'

const INITIAL_STATE = {
    step: 1,
    payments: [],
    selectedPayment: null,
    selectedCard: null,
    paymentsFetched: false,
    paymentConfirmation: 'hidden',
    paymentInAction: false,
    tip: 0,
    delivery: {
        address: null,
    },
    billing: null,
    somethingWrong: false,
    phone: null
}
/**
 * @param {Object} state - Default aplication state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action) => {
    const { type, payload } = action
    switch (type) {
        case `${ACTION_SET_CHECKOUT_STEP}`:
            return {
                ...state,
                step: payload,
            }
        case `${ACTION_PAYMENT_METHOD_SELECTED}`:
            return {
                ...state,
                selectedPayment: payload.type,
                selectedCard: payload.card || state.selectedCard ,
            }
        case `${ACTION_GET_CHECKOUT_INFO}_FULFILLED`: {
            if (!payload.data){
                return {...state}
            }
            return {
                ...state,
                payments: payload.data.cards,
                paymentsFetched: true,
            }
        }
        case `${ACTION_FINALISE_PAYMENT}_FULFILLED`: {
            return {
                ...state,
                paymentConfirmation: payload.data.error ? 'hidden' : 'open',
                paymentInAction: false,
            }
        }
        case `${ACTION_CREATE_ORDER}_PENDING`: {
            return {
                ...state,
                paymentInAction: true,
            }
        }
        case `${ACTION_CREATE_ORDER}_FULFILLED`:
        case `${ACTION_CREATE_ORDER}_REJECTED`:
        case `${ACTION_FINALISE_PAYMENT}_REJECTED`: {
            return {
                ...state,
                paymentInAction: false,
            }
        }
        case ACTION_DELIVERY_ADDRESS_SELECTED: {
            return {
                ...state,
                delivery: { ...payload },
            }
        }
        case ACTION_BILLING_ADDRESS_SELECTED: {
            return {
                ...state,
                billing: payload ? { ...payload } : null,
            }
        }
        case ACTION_RESET_AFTER_PAYMENT: {
            return {
                ...INITIAL_STATE,
            }
        }
        case ACTION_SOMETHING_WENT_WRONG: {
            return payload.isWrong
                ? {
                      ...state,
                      paymentInAction: false,
                      somethingWrong: payload.isWrong,
                  }
                : { ...INITIAL_STATE }
        }
        case ACTION_SET_PHONE_NUMBER: {
            return {
                ...state,
                phone: payload.phone
            }
        }
        default:
            return state
    }
}
