import {
    ACTION_USER_LOGIN,
    ACTION_USER_LOGOUT,
    ACTION_USER_SIGNUP,
    ACTION_GET_CHECKOUT_INFO,
    ACTION_FETCH_USER_ORDERS,
    ACTION_ORDER_SELECTED,
    ACTION_USER_UPDATE,
    ACTION_USER_UPDATE_PRIVACY,
    ACTION_FETCH_USER_ORDER_BY_ID,
    ACTION_CREATE_ORDER,
    ACTION_SEND_SMS,
    ACTION_CHECK_CODE,
} from '../actions/types'

const INITIAL_STATE = {
    orders: null,
    orderDetails: null,
    
    // AWS amplify
    isAttemptingLogin: false,   
    isLogged: false,
    attributes: {},
    cognitoData: {},
    locations: [],
}

export default (state = INITIAL_STATE, action) => {
    const { type, payload } = action
    switch (type) {
        // User login
        case `ACTION_USER_LOGIN`:
            return {
                ...state,
                isLogged: true,
                cognitoData: payload.payload
            }
        case `ACTION_USER_UPDATE_FULFILLED`:
            return {
                ...state,
            }
        case 'ACTION_USER_DATA_FETCHED':
            return {
                ...state,
                isLogged: true,
                cognitoData: {
                    ...state.cognitoData,
                    ...payload
                }
            }
        case `ACTION_USER_LOGOUT`:
            return {
                ...INITIAL_STATE,
                isLogged: false,
            }

        case `ACTION_REFRESH`:
            return {
                ...state,
                isLogged: true,
                signInUserSession: payload,
            }
        case `${ACTION_GET_CHECKOUT_INFO}_FULFILLED`: {
            if (!payload.data){
                return {...state}
            }
            return {
                ...state,
                locations: payload.data.locations,
            }
        }
        case `${ACTION_FETCH_USER_ORDERS}_PENDING`: {
            return {
                ...state,
                ordersLoading: true,
            }
        }
        case `${ACTION_FETCH_USER_ORDERS}_FULFILLED`: {
            return {
                ...state,
                orders: payload.data.orders,
                ordersLoading: false,
            }
        }
        case `${ACTION_FETCH_USER_ORDERS}_REJECTED`: {
            return {
                ...state,
                ordersLoading: false,
            }
        }
        case ACTION_ORDER_SELECTED: {
            return {
                ...state,
                orderDetails: payload.order,
            }
        }
        case `${ACTION_FETCH_USER_ORDER_BY_ID}_FULFILLED`: {
            return {
                ...state,
                orderDetails: payload.data,
            }
        }
        case `${ACTION_CREATE_ORDER}_FULFILLED`: {
            return {
                ...state,
                lastCreatedOrderId: payload.data.orderId,
                lastCreatedOrderItems: JSON.parse(payload.config.data).products,
                lastOrderError:
                    payload.data?.statusCode === 400
                        ? payload.data?.body
                        : null,
            }
        }
        case `${ACTION_USER_UPDATE}_PENDING`: {
            return {
                ...state,
                isUpdatePending: true,
            }
        }
        case `${ACTION_USER_UPDATE}_REJECTED`: {
            return {
                ...state,
                isUpdatePending: false,
            }
        }
        case `${ACTION_USER_UPDATE}_FULFILLED`: {
            return {
                ...state,
                isUpdatePending: false,
                ...payload.data,
            }
        }
        case `${ACTION_USER_UPDATE_PRIVACY}_FULFILLED`: {
            return {
                ...state,
                isUpdatePending: false,
                ...payload.data,
            }
        }

        case `${ACTION_SEND_SMS}_PENDING`: {
            return {
                ...state,
                isSendSmsPending: true,
                sendSmsError: false,
            }
        }
        case `${ACTION_SEND_SMS}_FULFILLED`: {
            return {
                ...state,
                lastNonce: payload.data.nonceId,
                lastPhone: JSON.parse(payload.config.data).phone,
                isSendSmsPending: false,
                sendSmsError: false,
            }
        }
        case `${ACTION_SEND_SMS}_REJECTED`: {
            return {
                ...state,
                isSendSmsPending: false,
                sendSmsError: true,
            }
        }
        case `${ACTION_CHECK_CODE}_PENDING`: {
            return {
                ...state,
                isCheckCodePending: true,
                checkCodeError: false,
            }
        }
        case `${ACTION_CHECK_CODE}_FULFILLED`: {
            const { expired, valid } = payload.data
            const isVerified = !expired && valid
            return {
                ...state,
                isCheckCodePending: false,
                phoneVerified: isVerified,
                phone: isVerified ? state.lastPhone : '',
                lastPhone: '',
            }
        }
        case `${ACTION_CHECK_CODE}_REJECTED`: {
            return {
                ...state,
                checkCodeError: true,
                isCheckCodePending: false,
                phone: '',
                lastPhone: '',
            }
        }

        default:
            return state
    }
}
