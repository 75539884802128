import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import promise from 'redux-promise-middleware'
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'
import allReducers from './reducers'

//utils
import Address from './utils/address'
import * as MDateUtils from './utils/date'
import * as MTextUtils from './utils/text'
import * as MNumbersUtils from './utils/numbers'
import {STAGING_ENV} from '../config'

const logger = createLogger({
    diff: false,
})

let middleware = [promise, thunk]
if (STAGING_ENV) {
  middleware = [...middleware, logger]
}

const createStoreWithMiddleware = composeWithDevTools(
    applyMiddleware(...middleware),
)(createStore)

const persistConfig = {
    key: 'paria-web-store-01',
    storage,
    blacklist: ['timeAddress', 'checkout'],
}

const persistedReducer = persistReducer(persistConfig, allReducers)

export default () => {
    let store = createStoreWithMiddleware(persistedReducer)
    let persistor = persistStore(store)
    return { store, persistor }
}

export const moovendaCoreUtils = {
    Address,
    MDateUtils,
    MTextUtils,
    MNumbersUtils,
}
