// @flow
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { withContainer, connect } from '../services/container'
import styled from 'styled-components'
import SwipeableViews from 'react-swipeable-views'
import { selectCategory } from '../stores/actions'
import CategoryPage from './CategoryPage'
import { device } from '../utils/common-style'
export const scrollOnCategorySelected = () => {
    setTimeout(() => {
        const ref = 220 + 0
        if (window.scrollY > ref) {
            window.scrollTo(0, ref)
        }
    }, 100)
}
class RestaurantPages extends Component {
    render() {
        let categories = this.props.selectedRestaurant.categories
        const hiddenCategories = this.props?.deliveryPoint?.hiddenCategories
        if(hiddenCategories && hiddenCategories.length > 0){
            categories = this.props.selectedRestaurant.categories.filter(x => !hiddenCategories.includes(x.item))
        }
        return (
            <div className={this.props.className}>
                {categories.length > 0 ? (
                    <SwipeableViews
                        onChangeIndex={(index) => {
                            scrollOnCategorySelected()
                            this.props.selectCategory(index)
                        }}
                        index={
                            this.props.selectedRestaurant.selectedCategory?.index
                        }>
                        {categories.map((cat, i) => (
                            <CategoryPage key={cat.item} category={cat} isSelected={ i === this.props.selectedRestaurant.selectedCategory?.index}/>
                        ))}
                    </SwipeableViews>
                ) : (
                    ''
                )}
            </div>
        )
    }
}

const StyledRestaurantPages = styled(RestaurantPages)`
    background-color: white;
    min-height: calc(100vh - 56px - 48px);
    @media ${device.laptopL} {
        min-height: calc(100vh - 100px - 48px - 112px);
    }
`

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ selectCategory }, dispatch)
const mapStateToProps = ({ selectedRestaurant }) => ({
    selectedRestaurant,
})
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withContainer(StyledRestaurantPages))
