import React from 'react'
import styled from 'styled-components'
import { pxToRem, device } from '../../utils/common-style'
class HomePic extends React.Component {
    render() {
        return (
            <div className={`section ${this.props.className}`}>
                <div className="slide ">
                    <div className="slide-content ">
                        <div className="text-content"></div>
                        <div className="bg first ">
                            <div className="text-wrapper">
                                Per realizzare le vostre pizze utilizziamo
                                esclusivamente farine poco raffinate di Tipo 1 e
                                Tipo 2 prodotte con colture biologiche di grano
                                tenero 100% italiano macinato a pietra.
                                L’ingrediente fondamentale, però, è un altro: il
                                tempo.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="slide">
                    <div className="slide-content ">
                        <div className="text-content"></div>
                        <div className="bg second">
                            <div className="text-wrapper">
                                Tutto ha inizio 72 ore prima che la vostra pizza
                                venga servita, quando viene preparato un
                                prefermento composto da acqua, farina e lievito
                                (detto biga) che il giorno seguente viene
                                lavorato e completato per diventare l’impasto
                                finale. Questo impasto viene poi lasciato a
                                maturare a temperatura controllata e a lievitare
                                per le successive 48 ore al fine di garantire la
                                massima leggerezza e digeribilità.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="slide">
                    <div className="slide-content ">
                        <div className="text-content"></div>
                        <div className="bg second2">
                            <div className="text-wrapper">
                                Utilizziamo sempre e solo ingredienti freschi e
                                stagionali per realizzare tutte le nostre pizze
                                e i nostri piatti. Inoltre, concentriamo
                                costantemente le nostre energie nella selezione
                                delle eccellenze gastronomiche presenti sul
                                territorio locale e nazionale al fine di offrire
                                i migliori prodotti presidio DOP, IGP e Slow
                                Food.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const StyledHomePic = styled(HomePic)`
    .slide {
        text-align: center;
        font-family: RecoletaAlt;
        font-size: ${pxToRem(16)};
        .slide-content {
            height: 100%;
            .text-content {
                background-image: url("https://paria-web-store.s3-eu-west-1.amazonaws.com/site-assets/paria_pattern.jpg");
                background-repeat: repeat;
                height: 0%;
                box-sizing: border-box;
                @media ${device.tablet} {
                    height: 160px;
                }
            }
            .bg {
                height: 100%;
                @media ${device.tablet} {
                    height: calc(100% - 160px);
                }
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                align-items: center;
                display: flex;
                .text-wrapper {
                    font-family: WorkSans;
                    color: #282727;
                    text-align: center;
                    font-size: 20px;
                    background-color: #ffffff61;
                    position: absolute;
                    top: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    padding-right: 50px;
                    padding-left: 50px;
                    height: 100%;
                    width: 100%;
                    @media ${device.tablet} {
                        max-width: 300px;
                        text-align: left;
                    }
                }
                &.first {
                    background-image: url('https://paria-web-store.s3-eu-west-1.amazonaws.com/site-assets/gallery/paria_02.jpg');
                    background-position: bottom;
                }
                &.second {
                    background-image: url('https://paria-web-store.s3-eu-west-1.amazonaws.com/site-assets/gallery/paria_03.jpg');
                }
                &.second2 {
                    background-image: url('https://paria-web-store.s3-eu-west-1.amazonaws.com/site-assets/gallery/paria_04.jpg');
                    background-position: bottom;
                }
                &.third {
                    background-image: url('https://paria-web-store.s3-eu-west-1.amazonaws.com/site-assets/gallery/wall.jpg');
                }
                &.fourth {
                    background-image: url('https://paria-web-store.s3-eu-west-1.amazonaws.com/site-assets/gallery/delivery.jpg');
                }
                &.fifth {
                    background-image: url('https://paria-web-store.s3-eu-west-1.amazonaws.com/site-assets/gallery/beers.jpg');
                }
            }
        }
    }
    .fp-controlArrow {
        border: solid #282727;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 9px;
        &.fp-next {
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
        }
        &.fp-prev {
            transform: rotate(135deg);
            -webkit-transform: rotate(135deg);
        }
    }
`

export default StyledHomePic
