import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { pxToRem } from '../utils/common-style'

class Navbar extends Component {
    isActive(i) {
        return this.props.activeIndex === parseInt(i, 10)
    }

    renderListItemsHome() {
        return Object.keys(this.props.items).map((key) => {
            const i = this.props.items[key]
            return (
                <Link
                    className={`mdc-tab ${
                        this.isActive(i.order) ? 'mdc-tab--active' : ''
                    }`}
                    to={i.url}
                    key={`tab${i.order}`}>
                    <i className="material-icons">{i.icon}</i>
                </Link>
            )
        })
    }

    renderListItemsText() {
        return Object.keys(this.props.items).map((i) => {
            const name = this.props.items[i]
            return (
                <span
                    className={`mdc-tab ${
                        this.isActive(i) ? 'mdc-tab--active' : ''
                    }`}
                    key={`tab${i}`}
                    onClick={() => this.props.onClick(i)}>
                    {name}
                </span>
            )
        })
    }

    render() {
        return (
            <header className={`mdc-toolbar ${this.props.className}`}>
                <div className="mdc-toolbar__row">
                    <section className="mdc-toolbar__section mdc-toolbar__section--align-start">
                        <div className="tabs-container">
                            <nav className="mdc-tab-bar mdc-tab-bar--icon-tab-bar">
                                {this.props.simple
                                    ? this.renderListItemsText()
                                    : this.renderListItemsHome()}
                                <span className="mdc-tab-bar__indicator" />
                            </nav>
                        </div>
                    </section>
                </div>
            </header>
        )
    }
}

const StyledNavbar = styled(Navbar)`
    z-index: 1;
    background-color: unset;
    .mdc-toolbar__row {
        min-height: unset;
        .tabs-container {
            width: 100%;
            height: 47px;
            .mdc-tab-bar:not(.mdc-tab-bar-upgraded) .mdc-tab:after {
                background-color: ${(props) => props.theme.white};
                width: calc(100%);
                top: 45px;
                height: 2px;
            }
            .mdc-tab-bar {
                display: flex;
                height: 47px;
                margin: 0;
                color: ${(props) => props.theme.white};
                background-color: ${(props) => props.theme.white};
                > .mdc-tab {
                    flex-grow: 1;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    color: ${(props) => props.theme.jagray};
                    font-weight: bold;
                    font-size: ${pxToRem(14)};
                    &.mdc-tab--active {
                        color: ${(props) => props.theme.darkorange};
                    }
                }
            }
        }
    }
`

export default StyledNavbar
